import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import { Calendar, momentLocalizer } from 'react-big-calendar';

import urls from '../../../constants.js'
import FCApi from '../../../fc-api.js';
import CalendarToolbar from '../../CalendarToolbar.jsx';
import BookLessonsCalendarCard from './BookLessonsCalendarCard.jsx';
import BookLessonsCalendarTooltip from './BookLessonsCalendarTooltip.jsx';
import StudentCalendarTooltip from '../../StudentCalendarTooltip.jsx';
import CalendarKey from '../../CalendarKey.jsx';
import CalendarHeader from '../../CalendarHeader.jsx';
import { ToastNotificationsError, ToastNotificationsSuccess } from '../../../notifications/ToastNotifications.jsx';

import { useWindowSize, shiftTimezone } from '../../utils.jsx';

const USER_TIMEZONE = window.user_timezone ? window.user_timezone : 'America/Detroit';
const tzGroup = window.timezones.find(tz => tz.name == USER_TIMEZONE).group;

export default function BookLessonsCalendarView(props) {
  const navigate = useNavigate();
  const { teacherId } = useParams();

  // Global Data
  const [ skipDates, setSkipDates ] = useState([]);

  // User Data
  const [ teacher, setTeacher ] = useState(null);
  const [ availabilities, setAvailabilities ] = useState([]);
  const [ studentSessions, setStudentSessions ] = useState([]);

  // event selection
  const [ selectedEvent, setSelectedEvent ] = useState(null);
  const [ isCancellingPrivateSession, setIsCancellingPrivateSession ] = useState(false);
  const [ timeSlot, setTimeslot ] = useState(null);
  const [ tooltipComplete, setTooltipComplete ] = useState(false);

  // navigation trackers
  const [ canGoBack, setCanGoBack ] = useState(false);
  const [ canGoForward, setCanGoForward ] = useState(true);
  const [ viewDate, setViewDate ] = useState(null);

  // window size tracker
  const [ windowWidth ] = useWindowSize();

  // initialize API
  const apiClient = new FCApi();

  const loadSkipDate = () => {
    apiClient.listSkipDates(
      (error, response) => {
        if (!error) {
          setSkipDates(response);
        }
      },
      $.param({'preset': 'calendar'})
    );
  }

  const loadUserData = () => {
    apiClient.getTeacher(
      teacherId,
      (error, response) => {
        if (!error) {
          setTeacher(response);
        } else {
          // Teacher is inactive or missing, return to teacher selection
          navigate('../', {relative: 'path'});
        }
      }
    );
    apiClient.listTeacherAvailabilities(
      (error, response) => {
        if (!error) {
          setAvailabilities(response);
        }
      },
      $.param({'teacher_id': teacherId})
    );
    apiClient.listPrivateSessions(
      (error, response) => {
        if (!error) {
          setStudentSessions(response);
        }
      },
      $.param({
        'preset': 'calendar',
        'teacher_id': teacherId
      })
    );
  }

  useEffect(() => {
    loadSkipDate();
    loadUserData();
  }, []);

  useEffect(() => {
    $('.rbc-event').css('z-index', 1);
  }, [timeSlot, viewDate]);

  useEffect(() => {
    if(timeSlot !== null) {
      $('#temp-event').parent().parent().css('z-index', 2);
    }
  }, [timeSlot]);


  const showSuccess = (message) => {
    toast.success(
      <ToastNotificationsSuccess htmlContent={message} closeToast={() => {}} />,
      { autoClose: 3200 }
    );
  }

  const showError = (message) => {
    toast.error(
      <ToastNotificationsError htmlContent={message} closeToast={() => {}} />,
      { autoClose: 3200 }
    );
  }

  const getStart = (session) => {
    if (session.type == 'selected_timeslot') {
      // selected_timeslot events are already in local calendar time
      return moment(session.start_at).toDate();
    }

    return shiftTimezone(session.start_at, USER_TIMEZONE, moment.tz.guess());
  }

  const getEnd = (session) => {
    if (session.type == 'selected_timeslot') {
      // selected_timeslot events are already in local calendar time
      return moment(session.end_at).toDate();
    }

    let localEnd = moment(shiftTimezone(session.end_at, USER_TIMEZONE, moment.tz.guess()));
    if (localEnd.hour() == 0 && localEnd.minute() == 0) {
      localEnd.subtract(1, 'minutes');
    }
    return localEnd.toDate();
  };

  const formatTimePeriod = () => {
    let startTime = moment(timeSlot.start).format('LT');
    let endTime = moment(timeSlot.end).format('LT');
    let timeZone = moment(timeSlot.start).format('z');

    return `${startTime} - ${endTime} ${timeZone}`;
  }

  const isTimeslotValid = (slotInfo, showErrors = true) => {
    let availableTimes = new Set();
    for (let availability of availabilities) {
      availableTimes.add(moment(availability.start_at).format());
    }

    let slotStart = moment(shiftTimezone(slotInfo.start, moment.tz.guess(), USER_TIMEZONE));
    let slotEnd = moment(shiftTimezone(slotInfo.end, moment.tz.guess(), USER_TIMEZONE));

    if (slotStart < moment().add(1, 'day')) {
      if (showErrors) {
        showError(
          <p>Oops, you can&apos;t schedule a class <b className='force-uppercase'>24 hours</b> before it starts. You&apos;ll have to choose another time or day.</p>
        );
      }
      return false;
    }

    let tmpStart = slotStart.clone();
    while (tmpStart < slotEnd) {
      if (!availableTimes.has(tmpStart.format())) {
        if (showErrors) {
          showError(<p>This timeslot is not available.</p>);
        }
        return false;
      }
      tmpStart.add(30, 'minute');
    }

    // check to see if there are existing lesson overlaps
    for (let session of studentSessions) {
      if (
        (moment(session.start_at) < slotEnd) &&
        (moment(session.end_at) > slotStart)
      ) {
        if (showErrors) {
          showError(<p>You already have a lesson during this time.</p>);

        }
        return false;
      }
    }
    return true;
  }

  const selectSlot = (slot) => {
    if (!props.lesson) {
      showError('There are not enough unbooked hours on your private lesson package to create a session.');
      return;
    }

    // If the selected package has a min or max session limit, enforce it here.
    let duration = moment(slot.end).diff(moment(slot.start), 'minutes');
    const minMinutes = props.lesson.package.min_session_minutes;
    const maxMinutes = props.lesson.package.max_session_minutes;

    if (minMinutes && duration < minMinutes) {
      slot.end = moment(slot.start).add(minMinutes, 'minutes').toDate();
    }

    if (maxMinutes && duration > maxMinutes) {
      slot.end = moment(slot.start).add(maxMinutes, 'minutes').toDate();
    }

    let m = moment(slot.end);
    m.set('second', 0);
    slot.end = m.toDate();

    if (isTimeslotValid(slot)) {
      setTimeslot(slot);
    }
  }

  const eventSelected = (event, e) => {

    if (event.type == 'availability') {
      if (!props.lesson) {
        showError('There are not enough unbooked hours on your private lesson package to create a session.');
        return;
      }

      const bounds = {left: e.pageX, top: e.pageY};

      let start = moment(shiftTimezone(event.start_at, USER_TIMEZONE, moment.tz.guess())).toDate();
      let end = moment(shiftTimezone(event.end_at, USER_TIMEZONE, moment.tz.guess())).toDate();

      // Check if next slot or previous slot is valid so we can fill out the
      // entire hour. Otherwise default to 30 minutes. Do not do this for
      // packages with max of less than an hour
      const maxMinutes = props.lesson.package.max_session_minutes;

      if (!maxMinutes || maxMinutes >= 60) {
        const nextSlotEnd = moment(end).add(30, 'minutes').toDate();
        const previousSlotStart = moment(start).add(-30, 'minutes').toDate();

        if (isTimeslotValid({'start': start, 'end': nextSlotEnd}, false)) {
          end = nextSlotEnd;
        } else if (isTimeslotValid({'start': previousSlotStart, 'end': end}, false)) {
          start = previousSlotStart;
        }
      }

      if (isTimeslotValid({'start': start, 'end': end})) {
        setTimeslot({'start': start, 'end': end, 'bounds': bounds});
      }
      return;
    }
    let tempEvent = event;

    event.x = e.pageX;
    event.y = e.pageY;
    setSelectedEvent(tempEvent);
  }

  const cancelSelectSlot = () => {
    setTimeslot(null);
    setTooltipComplete(false);
  }

  const closeTooltip = () => {
    setSelectedEvent(null);
  }

  const completeTooltip = () => {
    setTooltipComplete(true);
  }

  const confirmSlot = () => {
    const startAt = moment(shiftTimezone(timeSlot.start, moment.tz.guess(), USER_TIMEZONE));

    const endAt = moment(shiftTimezone(timeSlot.end, moment.tz.guess(), USER_TIMEZONE));
    if (endAt.minute() === 59) {
      endAt.add(1, 'minutes');
    }

    let data = {
      learner: props.learner.id,
      lesson: props.lesson.id,
      language: props.language.id,
      teacher: teacher.id,
      start_at: startAt,
      end_at: endAt,
      restore_availability: true
    };

    apiClient.createPrivateSessionByLesson(props.lesson.id, data, (error, response) => {
      if (error) {
        try {
          for (let key in response) {
            showError(<p>{response[key]}</p>);
          }
        } catch (new_error) {
          showError(<p>Error booking lesson. Please try again later.</p>);
        }
        return;
      }

      const localStartAt = moment(timeSlot.start);
      const localEndAt = moment(timeSlot.end);

      showSuccess(
        <p>You&apos;re all set! Your&nbsp;
          <b className="force-uppercase">{props.language.name}</b> lesson is scheduled for &nbsp;
          <b className="force-uppercase">{localStartAt.format('MMM Do')}</b> with &nbsp;
          <b className="force-uppercase">{teacher.privacy_protected_name}</b> from &nbsp;
          <b className="force-uppercase">{localStartAt.format('LT')} - {localEndAt.format('LT')}</b>.
        </p>
      );

      setAvailabilities(
        availabilities.filter(avail => (
          !(moment(avail.start_at) < endAt && moment(avail.end_at) > startAt)
        ))
      );
      setStudentSessions([...studentSessions, response]);
    });
    setTimeslot(null);
    setTooltipComplete(false);
  }

  const renderCreateTooltip = () => {
    if (timeSlot === null || tooltipComplete)
      return '';

    let session = {
      teacher: teacher,
      language: props.language,
      timeslot: timeSlot,
    };

    return (
      <BookLessonsCalendarTooltip
        session={session}
        onCancel={cancelSelectSlot}
        onSave={completeTooltip}
      />
    );
  }

  const renderEventTooltip = () => {
    if (selectedEvent === null || selectedEvent.type == 'selected_timeslot') {
      return;
    }
    return (
      <StudentCalendarTooltip
        session={selectedEvent}
        onCloseTooltip={() => closeTooltip()}
        onCancel={() => setIsCancellingPrivateSession(true)}
      />
    );
  }

  const renderConfirmModal = () => {
    if (timeSlot === null || !tooltipComplete)
      return '';

    return (
      <Modal
        overlayClassName='profile-modal-overlay'
        className='profile-modal keep-text-centered'
        isOpen={true}
        appElement={$('.account-content-container').get(0)}
        onRequestClose={(e) => {
          e.preventDefault();
          cancelSelectSlot();
      }}
        shouldCloseOnOverlayClick={true}
      >
        <div
          className='close'
          onClick={() => {cancelSelectSlot()}}
        ></div>
        <div className='inner-content'>
          <h2>Book This Lesson</h2>
          <p>
            Ready to confirm your <b className="force-uppercase">{props.language.name}</b> lesson
            with <b className="force-uppercase">{teacher.privacy_protected_name}</b>&nbsp;
            on <b className="force-uppercase">{moment(timeSlot.start).format('MMM Do')}</b>&nbsp;
            from <b className="force-uppercase">{formatTimePeriod()}</b>?
          </p>
        </div>
        <div className='actions-wrapper'>
          <a
            href="#"
            className="btn inverted"
            onClick={() => {cancelSelectSlot()}}
          >Not Yet</a>
          <button onClick={() => confirmSlot()}>Yes</button>
        </div>
      </Modal>
    );
  }

  const renderCancelModal = () => {
    if (!isCancellingPrivateSession) {
      return '';
    }

    return (
      <Modal
        overlayClassName='profile-modal-overlay'
        className='profile-modal keep-text-centered'
        isOpen={true}
        appElement={$('.ProfileApp').get(0)}
        onRequestClose={() => {
          setSelectedEvent(null);
          setIsCancellingPrivateSession(false);
        }}
        shouldCloseOnOverlayClick={true}
      >
        <div
          className='close'
          onClick={() => {
            setSelectedEvent(null);
            setIsCancellingPrivateSession(false);
          }}
        ></div>
        <div className='inner-content'>
          <h2>Are you sure you want to cancel this lesson?</h2>
          <p>Lessons cancelled less than 24 hours in advance will still be deducted from your total hours.</p>
        </div>
        <div className='actions-wrapper'>
          <a
            href="#"
            className="btn inverted"
            onClick={(e) => {
              e.preventDefault();
              setSelectedEvent(null);
              setIsCancellingPrivateSession(false);
            }}
          >Not Yet</a>
          <button onClick={() => onPrivateLessonCancel()}>Yes</button>
        </div>
      </Modal>
    );
  }

  const onPrivateLessonCancel = () => {
    const session = selectedEvent;
    apiClient.cancelPrivateSessionByLesson(session.lesson.id, session.id, (error, response) => { // eslint-disable-line no-unused-vars
      if (error) {
        setIsCancellingPrivateSession(false);
        setSelectedEvent(null);
        showError(<p>Unable to cancel private lesson.</p>);
      } else {
        setIsCancellingPrivateSession(false);
        setSelectedEvent(null);
        setStudentSessions(studentSessions.filter(el => el.id !== session.id));
        showSuccess(<p>Lesson cancelled.</p>);
      }
    });
  }

  const onNavigate = (date, view, action) => { // eslint-disable-line no-unused-vars
    const startOfRange = moment().startOf('week');
    const endOfRange = moment().add(91, 'days').endOf('week');
    const startOfSelectedWeek = moment(date).startOf('week');
    const endOfSelectedWeek = moment(date).endOf('week');
    if (startOfSelectedWeek.isSameOrBefore(startOfRange)) {
      if (canGoBack == true) {
        setCanGoBack(false);
      }
    } else if (canGoBack == false) {
      setCanGoBack(true);
    } else if (endOfSelectedWeek.isSameOrAfter(endOfRange)) {
      if (canGoForward == true) {
        setCanGoForward(false);
      }
    } else if (canGoForward == false) {
      setCanGoForward(true);
    }
    setViewDate(date);
  }

  if (!teacher) {
    return (
      <div className="book-lessons-calendar loading">Loading...</div>
    );
  }

  CalendarToolbar.defaultProps = {
    canGoBack: canGoBack,
    canGoForward: canGoForward,
    skipDates: skipDates,
  }
  const components = {
    event: BookLessonsCalendarCard,
    toolbar: CalendarToolbar,
    header: CalendarHeader,
  }
  const formats = {
    timeGutterFormat: 'hA',
    dayFormat: (date) => {
      const backShiftDate = moment(date).subtract(1, 'days');
      const hasMatchingHoliday = skipDates.filter(sd => moment(sd.start_at).isSame(backShiftDate, 'date')).length > 0;
      if (hasMatchingHoliday) {
        return `holiday ${moment(date).format('ddd D')}`;
      }
      return moment(date).format('ddd D')
    },
    dayRangeHeaderFormat: ({ start, end }) => {
      start = moment(start);
      end = moment(end);
      return `${start.format('MMM Do')} - ${start.isSame(end, 'month') ? end.format('Do') : end.format('MMM Do')}`
    },
    dayHeaderFormat: (date) => {
      const start = moment(date).startOf('week');
      const end = moment(date).endOf('week');
      return `${start.format('MMM Do')} - ${start.isSame(end, 'month') ? end.format('Do') : end.format('MMM Do')}`
    }
  }
  const events = [
    ...availabilities.map((av) => ({
      type: 'availability',
      start_at: av.start_at,
      end_at: av.end_at,
    })),
    ...studentSessions,
  ];

  if (timeSlot !== null) {
    events.push({
      type: 'selected_timeslot',
      teacher: teacher,
      language: props.lesson.language,
      start_at: timeSlot.start,
      end_at: timeSlot.end,
    });
  }

  moment.tz.setDefault();
  const localizer = momentLocalizer(moment);
  const view = windowWidth >= 900 ? 'week' : 'day';

  return (
    <div className="book-lessons-calendar">
      <div className="title">Book Lessons</div>
      <div className="subtitle">You are viewing <span className="teacher-name">{teacher.privacy_protected_name}&apos;s</span> calendar.</div>
      <div className="subtitle">Click and drag in an available time slot to book your lesson.</div>
      <div className="subtitle">Your timezone is <a href={urls.profileUrls.accountOverview}>{tzGroup}</a></div>

      <div className="calendar-wrapper">
        <Calendar
          components={components}
          formats={formats}
          localizer={localizer}
          events={events}
          defaultView='week'
          views={['week', 'day']}
          view={view}
          onView={() => {}}
          selectable={true}
          getNow={() => shiftTimezone(new Date(), USER_TIMEZONE, moment.tz.guess())}
          startAccessor={getStart}
          endAccessor={getEnd}
          allDayAccessor={() => {return false;}}
          onSelectSlot={selectSlot}
          onSelectEvent={eventSelected}
          onNavigate={onNavigate}
        />
        <CalendarKey />
      </div>

      {renderCreateTooltip()}
      {renderEventTooltip()}
      {renderConfirmModal()}
      {renderCancelModal()}
    </div>
  );
}

BookLessonsCalendarView.propTypes = {
  learner: PropTypes.object.isRequired,
  lesson: PropTypes.object,
  language: PropTypes.object.isRequired
};
