import PropTypes from 'prop-types';
import React from 'react';


export default function FlagCard(props) {
	var containerClass = 'selected-language flag-list-item';
	return (
		<li className={containerClass + '-container'} onClick={props.onClick}>
			<div className={containerClass}>
				<img src={ props.language.flag_image }></img>
				<h4>{ props.language.name }</h4>
			</div>
		</li>
	)
}

FlagCard.propTypes = {
	language: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	selectedLanguage: PropTypes.bool,
}
