import $ from 'jquery'
import urls from './constants.js'


function addToCart(data) {
  window.dataLayer.push({'event':'addtocart'});
  $.ajax({
    method: 'POST',
    url: urls.addToCartUrl,
    data: JSON.stringify(data),
    dataType: 'json',
  }).done(() => {
    window.location.href = `${urls.cartCheckoutUrl}`
  }).fail(reason => {
    console.error(reason)
  });
}


function setDataAttr(elem, attr, value) {
  value = value === undefined ? null : value;
  elem.removeData(attr);
  elem.removeAttr(attr);
  elem.data(attr, value);
  elem.removeClass('error');
}


function getDataAttr(elem, attr) {
  return $(elem).data(attr);
}


function isRequiredField(elem) {
  return $(elem).attr('required') === 'required';
}


/**
 * Add the user selected value as a data attribute on the select
 * input when that input belongs to a subscription product.
 */
function subscriptionChangeHandler(event) {
  event.preventDefault();
  const $target = $(event.target);
  if ($target.has('.subscription-button')) {
    let $selection = $target.children('option:selected');
    setDataAttr($target, 'subscription-value', $selection.val());
  }
}


/**
 * Run Form validation for a Cart Product.
 * Note: currently only runs on subscription products.
 */
function validateAddToCartForm(button) {

  // clear out any existing errors
  $('.subscription-select').removeClass('error');

  var is_valid = true;
  const $form = $(button.closest('.fc-product'));

  $form.find('.subscription-select').each(function(index, select) {
    var $select = $(select);
    if (isRequiredField($select)) {
      let value = getDataAttr($select, 'subscription-value');
      if (!value) {
        $select.addClass('error');
        is_valid = false;
      }
    }
  });

  return is_valid;
}


function classAddToCart() {
  let $this = $(this);
  let $form = $this.closest('.fc-product');

  let subscription_data = {};
  let language = $form.find('.subscription-choose-language')
                      .data('subscription-value');
  let plan_id = $form.find('.subscription-choose-plan').val();

  (language && (subscription_data['subscription-language'] = language));
  (plan_id && (subscription_data['subscription-plan-id'] = plan_id));

  if (validateAddToCartForm($form) === false) {
    return;
  }

  let data = {
    'object_id': parseInt($this.data('id'), 10),
    'content_type': $this.data('contenttype'),
    ...subscription_data,
  };

  addToCart(data);

  // log event data for our analytics libraries
  window.dataLayer.push({
    'event': 'addedtocart',
    'prodid': data['object_id'],
    'category': $this.data('language'),
    'name': $this.data('name'),
    ...subscription_data,
  });
}


function radioAddToCart(e) {
  e.preventDefault();
  let $input = $(this).parent('form').find('input:checked');
  addToCart({
    'object_id': parseInt($input.attr('id'), 10),
    'content_type': $input.data('contenttype'),
  });
}

function toCart() {
  window.location.pathname = `${urls.cartCheckoutUrl}`
}



export default function cartListeners() {
  $('.fc-product').on('change', subscriptionChangeHandler);
  $('.js-add-to-cart').on('click', classAddToCart);
  $('.js-radio-add-to-cart').on('click', radioAddToCart);
  $('.js-to-cart').on('click', toCart);
}
