import $ from 'jquery';


export default function socialLinks() {
    $('.share-link.facebook').click(function() {
        window.FB.ui({
            method: 'share',
            display: 'popup',
            href: window.location.href,
            quote: this.dataset.message
        }, function(){});
    });

    $('.share-link.twitter').click(function() {
        var query = this.dataset.message + '&url=' + encodeURIComponent(window.location.href) + '&via=fluentcity';
        var t_url = 'https://twitter.com/intent/tweet?text=' + query;
        var strWindowFeatures = 'location=yes,height=370,width=520,scrollbars=yes,status=yes';
        window.open(t_url, '_blank', strWindowFeatures);
    });

    $('.share-link.mail').click(function() {
        var body = this.dataset.message + ' ' + window.location.href;
        var subject = 'Check this class out';
        location.href = 'mailto:?subject=' +subject+ '&body=' + body;
    });
}
