import $ from 'jquery'


export default function updateHeader() {
    var quantity = 0
    var cart_lineitems = document.hasOwnProperty('cartLineItems') ? document.cartLineItems : []
    for (var i = 0; i < cart_lineitems.length; i++) {
        quantity += cart_lineitems[i].quantity
    }
    $('.cart-quantity-count').text(quantity)
}
