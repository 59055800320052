var bannerController
var banner

export default function siteBannerController () {
    window.addEventListener('scroll', scrollEvent)

    // Fix if Django CMS toolbar present
    if ($('.cms-toolbar').length) {
      $('.site-banner').css('top', $('.cms-toolbar').height() + 'px');
    }
}

onload = () => {
    bannerController = getBannerController()
    banner = document.getElementsByClassName('sub-banner')[0]
    scrollEvent()
}

var getBannerController = () => {
    return {
        container: document.getElementsByClassName('site-banner')[0],
        expanded: false
    }
}

var scrollEvent = () => {
    if (!bannerController) { return false }


    // The list contains typically 2 or 3 elements, depending on if it's visible.
    // As such, doing a string search in the array is acceptable.
    if (!banner) { return false }

    var firstBreak = 200
    if (banner.classList.contains('private') ||
        banner.classList.contains('group')) {
        var navBarStart = document.getElementsByClassName('page-nav-container')[0].
            getBoundingClientRect().top
        var pageStart = document.body.getBoundingClientRect().top
        firstBreak = navBarStart - pageStart
    }
    var secondBreak = 1600

    var expanded = bannerController.expanded
    if (window.pageYOffset > firstBreak && !expanded) {
        bannerController.expanded = true
        bannerController.container.classList.add('expanded')
        bannerController.container.classList.remove('retracted')
        banner.classList.add('visible')
    } else if (window.pageYOffset <= firstBreak && expanded) {
        bannerController.expanded = false
        bannerController.container.classList.remove('expanded')
        bannerController.container.classList.remove('retracted')
        banner.classList.remove('visible')
    } else if (window.pageYOffset > secondBreak) {
        bannerController.container.classList.add('retracted')
    } else {
        bannerController.container.classList.remove('retracted')
    }
}
