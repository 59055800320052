import PropTypes from 'prop-types'
import React from 'react'
import {isEmpty} from '../util.js'
import CartNotification from './CartNotification.jsx'


export default function CartNotificationContainer(props) {
    if (isEmpty(props.notifications)) { return null }
    return (
        <div className={'CartNotificationContainer'}>
            {Object.keys(props.notifications).map(name => {
                return (
                    <CartNotification
                        key={name}
                        name={name}
                        message_type={props.message_type}
                        messages={props.notifications[name]}
                    />
                )
            })}
        </div>
    )
}

CartNotificationContainer.propTypes = {
    notifications: PropTypes.object.isRequired,
    message_type: PropTypes.string.isRequired,
}
