import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavItem = ({url, label, exact}) => {
  return (
    <div className="nav-item">
      <NavLink end={exact} className="option" to={url}>{label}</NavLink>
    </div>
  );
};

NavItem.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  exact: PropTypes.bool
};

export default NavItem;
