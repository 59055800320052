import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { private_lessonURL, kidsPurchaseUrl } from '../../../constants.js';

const SelectLessonView = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If only one lesson, preselect it.
    if (props.lessons.length == 1 && location.search.includes('skip')) {
      onLessonSelect(props.lessons[0].id, true);
    }
  }, []);

  const onLessonSelect = (lessonId, skip=true) => {
    let path = `${lessonId}`;
    if (skip) {
      path += '?skip';
    }
    navigate(path);
  };

  return (
    <div className="learner-selector">
      <div className="title">Select a Private Lesson Package</div>
      {props.lessons.length == 0 ?
        <div className="no-lessons">
        No Active Packages.
        <a
          className="right-btn dark-button"
          href={props.learner.learner_type == 'child' ? kidsPurchaseUrl : private_lessonURL + '#subscription-plans'}
        >Add Package</a>
        </div> :
        <div className="lessons">
          {props.lessons.map((lesson, index) => (
            <div
              className="booking-option"
              key={index}
              onClick={() => onLessonSelect(lesson.id)}
            >
              <h2 className="option-title">
                {lesson.package.title}{lesson.language ? ' - ' + lesson.language.name : ''}
              </h2>
              <div className="option-desc">
                {parseFloat(lesson.hours_unbooked)}
                {parseFloat(lesson.hours_unbooked) == 1
                  ? ' hour unbooked'
                  : ' hours unbooked'}
              </div>
            </div>
          ))}
        </div>
      }
    </div>
  );

};

SelectLessonView.propTypes = {
  learner: PropTypes.object.isRequired,
  lessons: PropTypes.array.isRequired
};

export default SelectLessonView;
