import $ from 'jquery'
import urls from './constants.js'

export function getLocation(location_id=null, callback=null) {
    // When no location_id is passed in then this function will
    // return the list of all enabled Locations. If a location_id is provided
    // we return data for a particular Location
    let url = location_id ? `${urls.locationListUrl}${location_id}` : `${urls.locationListUrl}`
    $.getJSON(url, json_data => {
        return (typeof callback === 'function') ? callback(json_data) : json_data
    }).fail((jaXHR, textStatus, error) => {
        console.error(textStatus, error)
        return {'error': error}
    })
}

export function getCourses(callback=null) {
    $.getJSON(urls.templateListUrl, json_data => {
        return (typeof callback == 'function') ? callback(json_data) : json_data
    }).fail((jaXHR, textStatus, error) => {
        console.error(textStatus, error)
        return {'error': error}
    })
}

export function getCustomerReviews(template_id=null, callback=null) {
    // Given a unique EnrollableTemplate.id make an api request to
    // the python endpoint and return the JSON data of its customer reviews.
    let url = urls.customerReviewsURL
    if (template_id != null) { url = url + template_id }
    $.getJSON(url, json_data => {
        return (typeof callback == 'function') ? callback(json_data) : json_data
    }).fail((jaXHR, textStatus, error) => {
        console.error(textStatus, error)
        return {'error': error}
    })
}

export function getSiteBanner(callback=null) {
    // Retrieve relevant site banner copy based on page and location
    $.getJSON(urls.siteBannerListUrl, json_data => {
        return (typeof callback == 'function') ? callback(json_data) : json_data
    }).fail((jaXHR, textStatus, error) => {
        console.error(textStatus, error)
        return {'error': error}
    })
}

export function getLanguageTemplates(language_slug, callback) {
    $.ajax({
        url: urls.templateByLangListUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({lang_slug: language_slug})
    }).done(response => {
        return (typeof callback === 'function') ? callback(response) : response
    }).fail(reason => {
        console.error(reason)
    })
}

export function getPackages(callback) {
    $.ajax({
        url: urls.packagesList,
        type: 'GET',
        contentType: 'application/json',
    }).done(response => {
        return callback ? callback(response) : response
    }).fail(reason => {
        console.error(reason)
    })
}

export function getPageContent(online, callback) {
    $.ajax({
        url: online? urls.onlineLessonsHubContentList : urls.privateLessonsPageContentList,
        type: 'GET',
        contentType: 'application/json',
    }).done(response => {
        return callback ? callback(response) : response
    }).fail(reason => {
        console.error(reason)
    })
}

export function redeemGiftcardForUser () {
    // this function is connected to the form on /admin/order/order/add
    let textNode = $('#giftcard-result-text')[0]
    $.ajax({
        type: 'POST',
        url: urls.redeemUserGiftcardUrl,
        data: $('#giftcard-form').serialize(),
        complete: (data) => {
            if (data.status === 200) {
                textNode.innerText = 'Successfully added $' + data.responseJSON.credit + ' to student\'s credit.'
            } else if (data.responseJSON.error) {
                textNode.innerText = data.responseJSON.error;
            } else {
                textNode.innerText = 'Status ' + data.status + ' - unknown error';
            }
        }
    });
    return false;
}
