import React, { useState, useEffect } from 'react';

import FCApi from '../../../fc-api.js';

import CourseCard from './CourseCard.jsx';

const MyCoursesListView = () => {
  const [ filterCourses, setFilterCourses ] = useState('active');
  const [ courses, setCourses ] = useState([]);

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    apiClient.v2ListCourses(
      (error, response) => {
        if (!error) {
          setCourses(response);
        }
      },
      'expand=template'
    );
  };

  const onCoursesFilterChange = (event) => {
    const value = event.target.value;
    setFilterCourses(value);
  }

  const renderCourseCards = () => {
    let filteredCourses = courses.filter(c => c.active);
    if (filterCourses == 'inactive') {
      filteredCourses = courses.filter(c => !c.active);
    }
    return (
      <div className="course-cards">
      {filteredCourses.map((course, index) => (
        <CourseCard
          key={index}
          course={course}
        />
      ))}
      </div>
    );
  }

  return (
    <div className="MyCoursesListView">
      <h2 className="inverted">My Courses</h2>
      <div className='filter-section'>
        <div className='filter-wrapper'>
          Filter courses:&nbsp;&nbsp;<select value={filterCourses} onChange={onCoursesFilterChange}>
            <option value={'active'}>Active</option>
            <option value={'inactive'}>Inactive</option>
          </select>
        </div>
        { renderCourseCards() }
      </div>
    </div>
  );
};

export default MyCoursesListView;
