import PropTypes from 'prop-types'
import React from 'react'


export default function LanguageCard(props) {
    var containerClass = `${props.selectedLanguage ? 'selected-language ' : ''}flag-list-item`
    return (
        <li className={containerClass + '-container'}>
            <div className={containerClass}>
                <img src={ props.language.flag_image }></img>
                <h4>{ props.language.name }</h4>
            </div>
        </li>
    )
}

LanguageCard.propTypes = {
    language: PropTypes.object.isRequired,
    selectedLanguage: PropTypes.bool,
}
