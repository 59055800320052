// copied and updated from the default ReactBigCalendar toolbar
import React from 'react'
import PropTypes from 'prop-types'
import { navigateActions } from '../constants.js';
import moment from 'moment-timezone';

import { shiftTimezone } from './utils.jsx';

const USER_TIMEZONE = window.user_timezone ? window.user_timezone : 'America/Detroit';
moment.tz.setDefault();

class CalendarToolbar extends React.Component {
  render() {
    let {
      date,
      view,
      label,
      canGoBack,
      canGoForward,
      skipDates,
    } = this.props

    // Shift current date back into user profile timezone for DST
    // calculation
    date = shiftTimezone(date, moment.tz.guess(), USER_TIMEZONE);
    let tzAbbr = moment.tz(date, USER_TIMEZONE).format('z');
    if (tzAbbr[0] === '-' || tzAbbr[0] === '+') {
      tzAbbr = 'GMT' + tzAbbr;
    }

    const currentDay = moment(date);
    let days = [];
    if (view === 'day') {
      let index = 0;
      [...Array(7)].map(() => {
        days.push(moment(date).startOf('week').add(index, 'days'));
        index++;
      });
    }

    return (
      <div className="rbc-toolbar">
        <div className='standard-content'>
          {canGoBack ?
            <img
              src='/static/img/left-carat.svg'
              onClick={this.navigate.bind(null, navigateActions.PREVIOUS, null)}
            />
            :
            <div className='carat-placeholder'/>
          }
          <div className='toolbar-inner-content'>
            <h2>{label}</h2>
            <p>All times displayed in {`${tzAbbr}`}</p>
          </div>
          {canGoForward ?
            <img
              src='/static/img/right-carat.svg'
              onClick={this.navigate.bind(null, navigateActions.NEXT, null)}
            />
            :
            <div className='carat-placeholder'/>
          }
        </div>
        {view === 'day' &&
          <div className='day-selector'>
            {days.map((day, index) => {
              const shiftDate = moment(day.toDate()).add(1, 'days');
              const backShiftDate = moment(day.toDate()).subtract(1, 'days');
              const hasMatchingHoliday = skipDates.filter(sd => moment(sd.start_at).isSame(backShiftDate, 'date')).length > 0;
              return (
                <div
                  key={index}
                  className={`day${day.isSame(currentDay, 'day') ? ' current-day' : ''}`}
                  onClick={this.navigate.bind(null, navigateActions.PREVIOUS, shiftDate)}
                >
                  {day.format('dd').substring(0, 1)}{day.format('D')}
                  {hasMatchingHoliday &&
                    <div className='holiday' />
                  }
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }

  navigate = (action, newDate=null, e) => {  // eslint-disable-line no-unused-vars
    if (this.props.view === 'day') {
      if (newDate === null) {
        if (action === navigateActions.PREVIOUS) {
          newDate = moment(this.props.date).add(1, 'days').subtract(1, 'weeks');
        } else if (action === navigateActions.NEXT) {
          newDate = moment(this.props.date).subtract(1, 'days').add(1, 'weeks');
        }
      }
      this.props.onNavigate(action, newDate);
    } else {
      this.props.onNavigate(action);
    }
  }
}

CalendarToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  canGoBack: PropTypes.bool.isRequired,
  canGoForward: PropTypes.bool.isRequired,
  skipDates: PropTypes.array,
}

export default CalendarToolbar;
