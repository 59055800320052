
export default function fc_dropdown() {

    $('.fc-dropdown').on( 'click', (e)=> {

        let selected_element = $(e.target)

        if(!selected_element.hasClass('fc-dropdown-option'))
            selected_element = selected_element.closest('.fc-dropdown-option')
        
        if(selected_element==null || selected_element==undefined)
            return
    
        if(!selected_element.is(':first-child'))
        {
            $('.fc-dropdown-option').first().removeClass('border-bottom')
            selected_element.remove()
            $('.fc-dropdown').prepend(selected_element)
            selected_element.addClass('border-bottom')
            let arrow = $('.fc-dropdown-arrow')
            arrow.remove()
            selected_element.append(arrow)

            $('.fc-dropdown-value-holder').val(selected_element.find('span').eq(0).html())

            if($('.placeholder-option'))
                $('.placeholder-option').remove()
        }
        
        if($('.fc-dropdown-option').last().hasClass('hide'))
        {   
            $('.fc-dropdown-arrow').addClass('flip-vertical')
            $('.fc-dropdown-option').removeClass('hide')
        }
        else
        {
            $('.fc-dropdown-arrow').removeClass('flip-vertical')
            $('.fc-dropdown-option').not(':first').addClass('hide')
        }

    })
}