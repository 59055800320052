import PropTypes from 'prop-types'
import React from 'react'


export default function ChildLineItem(props) {
    // Display component for uneditable Child LineItems
    return (
        <div className='LineItem LineItem__child'>
            <div className='LineItem__header'>
                <h2>{props.line.cart_display.title}</h2>
                <p className='LineItem__price'>${props.getLineTotal(props.line)}</p>
            </div>
            <div className='LineItem__qty'>
                <h4>{`x ${props.line.quantity}`}</h4>
            </div>
            <div className='LineItem__desc'>
                <p>{props.line.cart_display.title}</p>
            </div>
        </div>
    )
}

ChildLineItem.propTypes = {
    line: PropTypes.object.isRequired,
    getLineTotal: PropTypes.func,
}
