import $ from 'jquery'

export default function learningPlans() {
    let adults = true;
    let position = 0;
    const $adultsTable = $('.adults-table');
    const $kidsTable = $('.kids-table');
    const $toggle = $('.cricket-toggle');
    const $table =  $('.v2-plugin-table');
    const $learningTable =  $('.learning-table');
    $('.toggle-adults').on('click', () => {
        if (!adults) {
            adults = true;
            position = 0;
            $table.removeClass('toggle-kids-flip');
            $table.addClass('toggle-adults-flip');
            $toggle.removeClass('table-toggle-kids');
            $toggle.addClass('table-toggle-adults');
            $learningTable.removeClass('position-two-arrows');
            $learningTable.removeClass('position-one-arrows');
            $learningTable.addClass('position-zero-arrows');
            $adultsTable.removeClass('position-two');
            $adultsTable.removeClass('position-one');
            $adultsTable.addClass('position-zero');
        }
    })
    $('.toggle-kids').on('click', () => {
        if (adults) {
            adults = false;
            position = 0;
            $table.removeClass('toggle-adults-flip');
            $table.addClass('toggle-kids-flip');
            $toggle.removeClass('table-toggle-adults');
            $toggle.addClass('table-toggle-kids');
            $learningTable.removeClass('position-two-arrows');
            $learningTable.removeClass('position-one-arrows');
            $learningTable.addClass('position-zero-arrows');
            $kidsTable.removeClass('kids-table-one');
        }
    })
    $('.table-left-arrow').on('click', () => {
        if (adults) {
            position--;
            if (position === 1) {
                $adultsTable.removeClass('position-two');
                $adultsTable.removeClass('position-zero');
                $adultsTable.addClass('position-one');
                $learningTable.removeClass('position-zero-arrows');
                $learningTable.removeClass('position-two-arrows');
                $learningTable.addClass('position-one-arrows');
                
            }
            if (position === 0) {
                $adultsTable.removeClass('position-two');
                $adultsTable.removeClass('position-one');
                $adultsTable.addClass('position-zero');
                $learningTable.removeClass('position-two-arrows');
                $learningTable.removeClass('position-one-arrows');
                $learningTable.addClass('position-zero-arrows');
            }
        } else {
            $kidsTable.removeClass('kids-table-one');
            $learningTable.removeClass('position-one-arrows');
            $learningTable.removeClass('position-two-arrows');
            $learningTable.addClass('position-zero-arrows');
        }
    })
    $('.table-right-arrow').on('click', () => {
        position++;
        if (adults) {
            if (position === 1) {
                $adultsTable.removeClass('position-two');
                $adultsTable.removeClass('position-zero');
                $adultsTable.addClass('position-one');
                $learningTable.removeClass('position-zero-arrows');
                $learningTable.removeClass('position-two-arrows');
                $learningTable.addClass('position-one-arrows');
            }
            if (position === 2) {
                $adultsTable.removeClass('position-one');
                $adultsTable.removeClass('position-zero');
                $adultsTable.addClass('position-two');
                $learningTable.removeClass('position-zero-arrows');
                $learningTable.removeClass('position-one-arrows');
                $learningTable.addClass('position-two-arrows');
            }
        } else {
            $kidsTable.addClass('kids-table-one');
            $learningTable.removeClass('position-one-arrows');
            $learningTable.removeClass('position-zero-arrows');
            $learningTable.addClass('position-two-arrows');
        }
    })
}