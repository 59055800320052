import PropTypes from 'prop-types'
import React from 'react'


export default class FeedbackBar extends React.Component {

    static propTypes = {
        correctAnswer: PropTypes.array,
        response: PropTypes.object,
        visible: PropTypes.bool,
    }

    constructor(props) {
        super(props)
    }

    render() {
        this.firstKey = typeof this.props.response=='object' ? Object.keys(this.props.response)[0] : false
        if (this.props.visible && this.props.response) {
            if (this.props.response.correct) {
                return (<div className='feedback-bar active correct'>Correct</div>)
            } else {
                switch (this.props.response[this.firstKey].question_type) {
                    case 'Select':
                        return (
                            <div className='feedback-bar active'>
                                <span className='word'>Sorry! The correct answer is “</span>
                                <span className='word'>{this.props.response[this.firstKey].question.split('[[answer]]')[0]}</span>
                                <span className='word'><b>{this.props.correctAnswer[0]}</b></span>
                                <span className='word'>{this.props.response[this.firstKey].question.split('[[answer]]')[1] + '”'}</span>
                            </div>
                        )
                    case 'Select Multiple':
                        var answerString = ''
                        this.props.correctAnswer.map(function (answer, index, array) {
                            if (index === array.length - 1 && array.length > 1) {
                                answerString += ('and ' + answer + '.')
                            } else if (array.length === 1) {
                                answerString += (' ' + answer + '.')
                            } else {
                                answerString += (' ' + answer + (array.length === 2 ? ' ' : ', '))
                            }
                        })
                        return (
                            <div className='feedback-bar active'>
                                <span className='word'>Sorry! The correct {this.props.correctAnswer.length <= 1 ? 'answer is' : 'answers were'}</span>
                                <span className='word'><b>{answerString}</b></span>
                            </div>
                        )
                    case 'Input - Text':
                        return (
                            <div className='feedback-bar active'>
                                <span className='word'>Sorry! The correct answer is “</span>
                                <span className='word'><b>{this.props.correctAnswer[0]}</b>.”</span>
                            </div>
                        )
                }
            }
        } else {
            return (<div className='feedback-bar'></div>)
        }
    }
}
