import PropTypes from 'prop-types'
import React from 'react'
import QuestionInput from './forms/question-input.jsx'
import QuestionSingleSelect from './forms/question-single-select.jsx'
import QuestionMultiSelect from './forms/question-multi-select.jsx'


export default class Question extends React.Component {
    // Renders the correct form input element based on the question type
    static propTypes = {
        id: PropTypes.number.isRequired,
        form_element: PropTypes.string.isRequired,
        question: PropTypes.string.isRequired,
        answers: PropTypes.array.isRequired,
        setIsAnswered: PropTypes.func.isRequired,
        form_type: PropTypes.string,
        instructions: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        showingFeedback: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            form_element: props.form_element,
            question: props.question,
            answers: props.answers,
            form_type: props.form_type || 'text',
            instructions: props.instructions || '',
            label: props.label || '',
            placeholder: props.placeholder || 'Answer',
            answer: []
        }
    }

    handleInputChange = (id, answer) => {
        // Callback provided to the input components
        // to capture onChange user input events
        this.setState({answer: answer})
        this.props.setIsAnswered(id, answer)
    }

    handleEnterKey = evt => {
        // make sure that when they hit the Enter key in a quiz input
        // form that it triggers the NEXT instead of the PREV button.
        if (evt.key === 'Enter') {
            evt.preventDefault()
            document.getElementById('quiz-next-btn').click()
        }
    }

    render() {
        switch(this.state.form_element) {
            case 'input':
                return  (
                    <div className='question-wrapper'>
                        <p>{ this.state.instructions }</p>
                        <QuestionInput
                            name={ `inputField-${this.state.id}` }
                            label={ this.state.label }
                            input_type={ this.state.form_type }
                            handleInputChange={ this.handleInputChange }
                            on_key_press_func={ this.handleEnterKey }
                            question={this.state.question}
                            placeholder={ this.state.placeholder }
                            showingFeedback={ this.props.showingFeedback }
                            question_id={ this.state.id } />
                    </div>
                )
            case 'select':
                return (
                    <div className='question-wrapper'>
                        <p>{ this.state.instructions }</p>
                        <QuestionSingleSelect
                            name={ 'selectField-' + this.state.id }
                            handleInputChange={ this.handleInputChange }
                            question={this.state.question}
                            placeholder={ this.state.placeholder }
                            question_id={ this.state.id }
                            options={ this.state.answers }
                            options_display={this.state.answers.map(answer => {
                                return answer.answer
                            })} />
                    </div>
                )
            case 'select-multiple':
                return (
                    <div className='question-wrapper'>
                        <p>{ this.state.instructions }</p>
                        <QuestionMultiSelect
                            name={ 'selectMultipleField-' + this.state.id }
                            handleInputChange={ this.handleInputChange }
                            question={this.state.question}
                            question_id={ this.state.id }
                            options={ this.state.answers } />
                    </div>
                )
            case 'textarea':
                return 'textarea not implemented yet'
        }
    }
}
