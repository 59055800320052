import PropTypes from 'prop-types'
import React from 'react'


export default class SelectInput extends React.Component {

    static propTypes = {
        name: PropTypes.string.isRequired,
        handleChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.object).isRequired,
        valueName: PropTypes.string.isRequired,
        labelName: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string), //used for multi-select
        ]).isRequired,
        placeholder: PropTypes.string,
        klass: PropTypes.string,
        data_question_id: PropTypes.number, // used in question conmponents
        size: PropTypes.number
    }

    handleSelectChange = evt => {
        this.props.handleChange(evt)
    }

    render() {
        return (
            <select
                name={this.props.name}
                className={this.props.klass}
                placeholder={this.props.placeholder}
                value={this.props.value}
                size={this.props.size}
                multiple={Array.isArray(this.props.value) ? 'multiple' : ''}
                onChange={this.handleSelectChange}
                data-question-id={this.props.data_question_id}
            >
                {this.props.placeholder &&
                    <option value="" disabled hidden>{this.props.placeholder}</option>
                }
                {this.props.options.map((opt, index) => {
                    return (
                      <option key={index} value={opt[this.props.valueName]}>{opt[this.props.labelName]}</option>
                    )
                })}
            </select>
        )
    }
}
