import $ from 'jquery'
import urls from '../constants.js'
import {sendPasswordReset, loginUser, registerStudent} from './api.js'


export default function authSubmission() {
    // PASSWORD RESET

    $('.password-reset-form').submit(evt => {
        // listen for pw reset form submit
        evt.preventDefault()
        let data = $('.password-reset-form').serialize()
        sendPasswordReset(data, urls.passwordResetUrl)
    })

    $('.password-reset-confirm-form').submit(evt => {
        // listen for pw rest confirm form submit
        evt.preventDefault()
        let data = $('.password-reset-confirm-form').serialize()
        sendPasswordReset(data, urls.passwordResetConfirmUrl)
    })

    // REGISTRATION AND SIGN IN

    $('.register-student-form').submit(evt => {
        // listen for registration form submit
        evt.preventDefault()
        let data = $('.register-student-form').serialize()
        registerStudent(data);
    })

    $('#accept_terms').on('change', () => {
        const d = new Date();
        $('#accepted_at').val(d.toISOString());
    })

    $('.login-user-form').submit(evt => {
        // listen for login form submit
        evt.preventDefault()
        let data = $('.login-user-form').serialize()
        loginUser(data)
    })
}
