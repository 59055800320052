import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

import FCApi from '../../../fc-api.js';
import AttachmentManager from '../../../general/AttachmentManager.jsx';
import { FormErrorMessage } from '../../utils.jsx';
import DatePickerField from '../../DatePickerField.jsx';
import TimePickerField from '../../TimePickerField.jsx';
import { ToastNotificationsError } from '../../../notifications/ToastNotifications.jsx';
import ResourceCard from '../../generic/my_resources/ResourceCard.jsx';

const MyCoursesSessionEditView = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();

  const [ session, setSession ] = useState(null);

  const apiClient = new FCApi();

  useEffect(() => {
    refreshSession();
  }, [sessionId]);

  const refreshSession = () => {
    if (!sessionId) {
      return;
    }
    apiClient.v2FetchClassroomSession(
      sessionId,
      (error, response) => {
        if (!error) {
          setSession(response);
        }
      },
      'expand=teacher,teacher_attachments,course.template,resources.file,resources.media_type'
    );
  };

  const globalFormError = (errors) => {
    // Raise form errors that are not field specific to a particular field.
    let message = errors.non_field_errors[0].props.children[0];
    toast.error(
      <ToastNotificationsError closeToast={() => {}} htmlContent={message} />,
      { autoClose: 6400 }
    );
  };

  let summaryEditable = true;
  let initialValues = {
    'start_date': new Date(),
    'start_time': '00:00',
    'end_time': '00:00',
    'notes': ''
  };

  if (session) {
    const startAt = moment(session.start_at).tz(window.user.timezone);
    const endAt = moment(session.end_at).tz(window.user.timezone);

    initialValues.start_date = startAt.format('YYYY/MM/DD');
    initialValues.start_time = startAt.format('HH:mm');
    initialValues.end_time = endAt.format('HH:mm');
    initialValues.notes = session.notes || '';

    summaryEditable = session.status == 'complete' && session.teacher && session.teacher.id == window.user.id;
  }

  if (sessionId && !session) {
    return null;
  }

  return (
    <div className="MyCoursesSessionEditView">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          const cb = (error, response) => {
            if (error) {
              let errors = apiClient.formatErrors(response);
              if ('non_field_errors' in errors) {
                globalFormError(errors);
              } else {
                actions.setErrors(errors);
              }
            } else {
              navigate('../../', {replace: true, relative: 'path'});
            }
            actions.setSubmitting(false);
          };

          const data = {'notes': values.notes};
          apiClient.v2UpdateClassroomSession(session.id, data, cb);
        }}
      >
        {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
          <Form className="form-inline">
            <h2 className="inverted">Lesson Details</h2>

            <div className="edit-form">
              <div className="form-group student-name">
                <label><span>Course</span></label>
                <span>{session ? session.course.template.name : ''}</span>
              </div>
              {session ? (
                <div className="form-group student-name">
                  <label><span>Teacher</span></label>
                  {session.teacher ?
                    <span>{`${session.teacher.first_name} ${session.teacher.last_name}`}</span>
                  :
                    <span>No teacher</span>
                  }
                </div>
              ) : ''}

              <div className="form-group">
                <label htmlFor="start_date">
                  <span>Date</span>
                </label>
                <DatePickerField
                  disabled={true}
                  name="start_date"
                  value={values.start_date}
                  onChange={setFieldValue}
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="start_date" />

              <div className="form-group">
                <label>
                  <span>Start Time</span>
                </label>
                <TimePickerField
                  disabled={true}
                  name="start_time"
                  value={values.start_time}
                  onChange={setFieldValue}
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="start_time" />

              <div className="form-group">
                <label>
                  <span>End Time</span>
                </label>
                <TimePickerField
                  disabled={true}
                  name="end_time"
                  value={values.end_time}
                  onChange={setFieldValue}
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="end_time" />

              {session ?
                <React.Fragment>
                  <div className="form-group session-status">
                    <label><span>Status</span></label>
                    <span>{session.status}</span>
                  </div>

                  {session.resources.length > 0 && <>
                    <div className="form-group">
                      <label>Resources</label>
                      <div className="session-resources">
                        {session.resources.map((res, index) => (
                          <ResourceCard
                            key={index}
                            title={res.title}
                            desc={res.description}
                            url={res.url || res.file.url}
                            isVisible={true}
                          />
                        ))}
                      </div>
                    </div>
                  </>}

                  <div className="form-group">
                    <label htmlFor="notes">Lesson Summary</label>
                    <div className="help-text">
                      After your lesson, write a quick summary. This will be
                      shared with your students.
                    </div>
                    <Field
                      name="notes"
                      component="textarea"
                      disabled={!summaryEditable}
                      value={values.notes}
                    />
                  </div>

                  <AttachmentManager
                    initialAttachments={session.teacher_attachments}
                    sessionType="classroom"
                    sessionId={sessionId}
                    maxFiles={5}
                    disabled={!summaryEditable}
                    readOnly={false}
                  />
                </React.Fragment>
              : ''}
            </div>

            <div className="form-toolbar">
              {isSubmitting ? <div className="submit-spinner spinner"></div> : ''}
              <Link
                to=".."
                className="cancel inverted"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >Go Back</Link>
              {summaryEditable ?
                <a href="#" onClick={handleSubmit} className={isSubmitting ? 'save disabled' : 'save'}>Save</a>
                : ''
              }
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyCoursesSessionEditView;
