
import $ from 'jquery'
import rouletteWheel from './rouletteWheel.js'


/*
* Normalization function for preprocessing text before grading.
*/
function cleanAnswer(text) {
  text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  text = text.toLowerCase();
  text = text.trim();
  return text;
}

/*
 * function for doing an exact match answer check on a fill-in-blank problem.
*/
function checkAnswer(question) {
  let input = question.find('input[type="text"]');
  let correct_answer = input.data().answer || '';
  let user_answer = input.val() || '';
  let is_correct = cleanAnswer(correct_answer) === cleanAnswer(user_answer);

  if (is_correct) {
    input.addClass('correct');
    input.removeClass('incorrect');
  } else {
    input.addClass('incorrect');
    input.removeClass('correct');
  }
  return is_correct;
}

/*
 * Reveal a questions correct answer in a div below.
*/ 
function showAnswer(question) {
  let data = question.find('input[type="text"]').data();
  let answer_div = question.find('.nlp-answer');
  answer_div.html(data.answer);
  if (answer_div) {
    answer_div.toggleClass('hidden');
  }
}

/*
 * Clear the user submitted answer from the input forms.
 * Also resets question from a graded state back to the default state.
*/
function clearAnswer(question) {
  const input = question.find('input[type="text"]');
  input.val('');
  input.removeClass('correct');
  input.removeClass('incorrect');
}


function initAnswerButtons() {

  $('button').click(event => {

    event.preventDefault();
    const $button = $(event.target);

    if (!$button.hasClass('btn')) { return; }

    const $section = $button.closest('.nlp-lesson-block');
    const $questions = $section.find('.nlp-question');

    if ($button.hasClass('btn-check-answers')) {
      $.each($questions, function() { checkAnswer($(this)) });
    } else if ($button.hasClass('btn-show-answers')) {
      $.each($questions, function() { showAnswer($(this)) });
      $button.text(function(i, text) {
        return text === 'Show Answers' ? 'Hide Answers' : 'Show Answers';
      });
    } else if ($button.hasClass('btn-clear-answers')) {
      $.each($questions, function() { clearAnswer($(this)) });
    }
  });
}


export default function initActivity() {

    if ($('#activity-roulette-wheel').length) {
      rouletteWheel();
    }

    if ($('.answer-buttons').length) {
      initAnswerButtons();
    }
}
