
function updateContent(ev) {
    var toShow = ev.target
    history.pushState({}, toShow.innerHTML, '/account/me');
    if (!(toShow.tagName.toLowerCase() === 'a' && !toShow.classList.contains('active'))) {
        return
    }
    var currentlyShownLink = document.querySelector('#account-details-nav a.active')
    currentlyShownLink.classList.remove('active')
    document.getElementById(currentlyShownLink.dataset.target).classList.add('hide')
    toShow.classList.add('active')
    document.getElementById(toShow.dataset.target).classList.remove('hide')
}

function updateContentFromHeader(ev) {
    var toShow = ev.target
    $('.account-content-container > div').addClass('hide');
    $('#'+toShow.dataset.target+'').removeClass('hide');
    $('#mobile-menu-overlay, #mobile-menu').toggleClass('hide')
    $('.hamburger').toggleClass('is-active')
    $('body').toggleClass('no-scroll')
}

export default function profile() {
    var nav = document.querySelector('#account-details-nav')
    if (nav) {
        nav.addEventListener('click', updateContent)
    }
    $('.plain.mobile-profile-link').click((e) => updateContentFromHeader(e));
}
