import PropTypes from 'prop-types'
import React from 'react'


export default function CartNotification(props) {
    // Component used for displaying general Cart related notifications to
    // the user. Typically this manifests itself as a list of errors that
    // display at the top of the OrderSummary.
    const LineItems = props.messages.map((msg, index) => {
        return <li key={index.toString()} dangerouslySetInnerHTML={{
          __html: msg
        }}></li>
    })

    if (props.messages.length === 0) { return null }

    return (
        <ul className={`CartNotification CartNotification__${props.message_type}`}>
            {LineItems}
        </ul>
    )
}

CartNotification.propTypes = {
    name: PropTypes.string.isRequired,
    messages: PropTypes.array.isRequired,
    message_type: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
}
