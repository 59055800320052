import PropTypes from 'prop-types'
import React from 'react'


export default function CustomerReview(props) {

    var starSection = props.has_stars ? (
      <div className='customer-review-stars'>
          <div className='review-stars-fill-bar full-stars' style={
              {width: (props.stars / 5) * 134 + 'px'}
          }>★★★★★</div>
          <div className='empty-stars'>☆☆☆☆☆</div>
      </div>
    ) : (
      <div className='customer-review-stars'></div>
    )

    return (
        <div className='customer-review'>
            <div className='quote-header'>
                {starSection}
                <img className={'review-logo ' + props.source + '-logo'}
                     src={props.sourceLogo}></img>
            </div>
            <div className='main-quote-block'>
                <div className='customer-review-quote'>{'“' + props.quote + '”'}</div>
                <div className='customer-review-info'>
                    <div className='avatar-frame'>
                        <img className='customer-photo' src={props.reviewerImage}></img>
                    </div>
                    <div className='customer-name'>{props.reviewerName}</div>
                </div>
            </div>
        </div>
    )
}

CustomerReview.propTypes = {
    has_stars: PropTypes.bool,
    quote: PropTypes.string,
    reviewerImage: PropTypes.string,  // image url
    reviewerName: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    sourceLogo: PropTypes.string,  // image url
    stars: PropTypes.number,
}
