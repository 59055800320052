import $ from 'jquery'


export default function salesleadsSubmit() {
    $('.email-capture-form, .questions-form, .get-in-touch, #get-in-touch, .pdp-info-form').submit(function(event) {
        event.preventDefault();
        const $form = $(this);

        // prevent user from creating duplicate content when spamming the submit button.
        $form.find(':submit').prop('disabled', true)

        $.post({
            url: '/sales-leads/',
            data: $form.serialize(),
        }).done(() => {
            window.dataLayer.push({
              'event': 'salesLead',
              'url': window.location.pathname
            })
            $form.parent().find('.email-capture-form-submitted').addClass('show')

            // Shareasale code for lead tracking
            var img = document.createElement('img')
            var email = $form.serializeArray()
                             .filter(entry => entry.name === 'email')
                             .reduce(entry => entry.value)
            img.src = '//shareasale.com/sale.cfm?amount=0.00&tracking=' + email.value + '&transtype=lead&merchantID=70785'

            $form.parent().find('.email-capture-form-submitted').append(img)
            $form.remove()
            $('.email-capture-form-errors').children().remove()
        }).fail(function(data) {
            console.error('SalesleadSubmit Failure:', data);
            let output = '';
            if (data.status === 500) {
                output = 'something went wrong';
            } else {
                let response = JSON.parse(data.responseText || {})
                let info = Object.keys(response);
                if (Array.isArray(info)) {
                    info.forEach(function(key) {
                        let message = response[key][0].message
                        output += `<p>${message}</p>`
                    });
                }
            }
            $('.email-capture-form-errors').html(output)
        }).always(function() {
            $form.find(':submit').prop('disabled', false)
        })
        return false
    })
}