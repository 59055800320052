import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { shiftTimezone, getTimeRangeString } from '../../utils.jsx';

const USER_TIMEZONE = window.user_timezone ? window.user_timezone : 'America/Detroit';

const BookLessonsCalendarCard = ({ event }) => {
  if ('type' in event && event.type == 'availability') {
    const event_start = moment(event.start_at);
    return (
      <div className={`availability-segment calendar-card ${event_start.minutes() == 30 ? 'double-bottom-margin' : ''}`}></div>
    )
  }

  if (event.type != 'selected_timeslot') {
    const flag_image = event.session_type == event.language ? event.language.flag_image : null;
    return (
      <div className='calendar-card'>
        {flag_image &&
          <img className='flag-image' src={flag_image} />
        }
        <div className='inner-content'>
          <span className='event-type'>{event.is_online ? 'Online' : 'Private'}</span>
          <span className='card-title'>{event.teacher.privacy_protected_name}</span>
          <img className='session-type-icon' src={event.is_online ? '/static/img/assets/account/my_lessons/online-session-icon.jpg' : '/static/img/assets/account/my_lessons/private-session-icon.jpg'} />
        </div>
      </div>
    )
  }

  let startAt = moment(event.start_at);
  let endAt = moment(event.end_at);

  if (startAt._isUTC) {
    startAt = moment(shiftTimezone(event.start_at, moment.tz.guess(), USER_TIMEZONE));
    endAt = moment(shiftTimezone(event.end_at, moment.tz.guess(), USER_TIMEZONE));
  }

  return (
    <div className='temp-calendar-card calendar-card has-overlap' id="temp-event">
      <span className="small">{getTimeRangeString(startAt, endAt)}</span>
      <span>Book Lesson</span>
    </div>
  );
}

BookLessonsCalendarCard.propTypes = {
  event: PropTypes.object.isRequired,
};

export default BookLessonsCalendarCard;
