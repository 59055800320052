import React from 'react';
import PropTypes from 'prop-types';

const TeacherCTA = ({ header, linkURL, linkText }) => {
  return(
    <div className="profile-teacher-cta-wrapper">
      <section className="profile-teacher-cta profile-cta">
        <div className="center-container">
          <h2>{header}</h2>
          <a href={linkURL} className="dark-button">{linkText}</a>
        </div>
      </section>
      <section className="profile-teacher-cta profile-cta space-holder">
        <div className="center-container">
          <h2>{header}</h2>
          <a href={linkURL} className="dark-button">{linkText}</a>
        </div>
      </section>
    </div>
  );
}

TeacherCTA.propTypes = {
  header: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
}

export default TeacherCTA;
