import PropTypes from 'prop-types'

import React from 'react'
import SelectInput from './select-input.jsx'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import validators from '../../validators.jsx'
import {isEmpty} from '../../util.js'


export default class LeadForm extends React.Component {

    static propTypes = {
        submit_func: PropTypes.func.isRequired,
        form_location: PropTypes.string,
        submit_button_label: PropTypes.string,
        first_name: PropTypes.shape({
            included: PropTypes.bool.isRequired,
            hidden: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
        }),
        last_name: PropTypes.shape({
            included: PropTypes.bool.isRequired,
            hidden: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
        }),
        phone_number: PropTypes.shape({
            included: PropTypes.bool.isRequired,
            hidden: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
        }),
        email: PropTypes.shape({
            included: PropTypes.bool.isRequired,
            hidden: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
        }),
        zip_code: PropTypes.shape({
            included: PropTypes.bool.isRequired,
            hidden: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
        }),
        language: PropTypes.shape({
            included: PropTypes.bool.isRequired,
            hidden: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
        }),
        level: PropTypes.shape({
            included: PropTypes.bool.isRequired,
            hidden: PropTypes.bool.isRequired,
            value: PropTypes.string.isRequired,
        }),
    }

    constructor(props) {
        super(props)
        this.state = {
            submitSuccess: false,
            show_validation_error: false,
            show_api_error: false,
            validation_errors: {},
            api_errors: [],
            first_name: this.props.first_name || null,
            last_name: this.props.last_name || null,
            phone_number: this.props.phone_number || null,
            email: this.props.email || null,
            zip_code: this.props.zip_code || null,
            language: this.props.language || null,
            level: this.props.level || null,
        }
        // errorClassName='invalid-input'
    }

    handleChange = evt => {
        // set the value of the input object
        const form_input = this.state[evt.target.name]
        form_input.value = evt.target.value

        // update the state object
        let stateObj = {}
        stateObj[evt.target.name] = form_input
        this.setState(stateObj)
    }

    validateForm = () => {
        // This is a custom override of the forms validation logic.
        // this method differs from form.validateAll() in 3 ways:
        //   1. it can render sever side API errors
        //   2. it propagates error data to LeadForm.state
        //   3. it can validate/handle custom fields (i.e. SelectInput)
        var is_valid = true,
            form = this.form,
            values = form.getValues(),
            validation_errors = {}

        // start with the react-validations default validation
        form.validateAll()

        // get all the input forms that have validators to run
        Object.keys(values).forEach(name => {
            let input = this['input_' + name]
            let validator_array = input.props.validations || []
            let is_required = Boolean(validator_array.find(x => x.name == 'required'))
            let value = values[name] || ''
            let has_value = !isEmpty(value)

            if (is_required && !has_value) {
                // if it is a required field and it has no value then skip
                // running all the validators but the is_required validator
                is_valid = false
                validation_errors[name] = ['required']
            } else {
                // otherwise lets run all the validators
                Object.values(validator_array).forEach(validator => {
                    let result = validator(value) || ''
                    if (result) {
                        is_valid = false
                        let errs = validation_errors[name] || [result]
                        if (errs[0] != result) { errs.push(result) }
                        validation_errors[name] = errs
                    }
                })
            }
        })

        // run special validation for our custom SelectInput field
        if (this.state.language.included && !this.state.language.value) {
            is_valid = false
            validation_errors['language'] = ['required']
        }

        this.setState({
            validation_errors: validation_errors,
            show_validation_error: !is_valid,
            show_api_error: this.state.api_errors.length > 0,
        })

        return is_valid
    }

    removeApiError = (evt) => {
        // onFocus removal of any preexisting errors.
        const prop_name = 'input_' + evt.target.name
        if (this.hasOwnProperty(prop_name) && isEmpty(evt.target.value)) {
            this.form.hideError(this[prop_name])
        }
    }

    submitForm = (evt) => {
        evt.preventDefault()
        if (this.validateForm() === true) {
            this.props.submit_func(results => this.setState(results))
        }
    }

    render() {
        var languageOptions = document.languageOptions.map(language => {
            return {value: language.slug, label: language.name}
        })
        languageOptions.push({value: 'not-sure', label: 'Not Sure'})

        return (
            <div className='quiz-lead-form-wrapper'>
                <Form
                    ref={c => { this.form = c }}
                    id={'quiz-lead-form'}
                    className='quiz-lead-form'
                    noValidate='novalidate'
                    onSubmit={this.submitForm.bind(this)}>
                    <div className='input-wrapper'>
                        <input
                            type='hidden'
                            name='form_location'
                            value={this.props.form_location}/>
                        <input
                            type='hidden'
                            name='page_url'
                            value={location.pathname}/>
                        { (this.state.first_name || {}).included &&
                            <div className='quiz-lead-input'>
                                <Input
                                    ref={c => { this.input_first_name = c }}
                                    validations={[validators.required]}
                                    value={this.state.first_name.value}
                                    onFocus={this.removeApiError.bind(this)}
                                    onChange={this.handleChange}
                                    className='first-name'
                                    autoComplete='on'
                                    type={this.state.first_name.hidden ? 'hidden': 'text'}
                                    name='first_name'
                                    placeholder='First Name' />
                            </div>
                        }
                        { (this.state.last_name || {}).included &&
                            <div className='quiz-lead-input'>
                                <Input
                                    ref={c => { this.input_last_name = c }}
                                    validations={[validators.required]}
                                    className='last-name'
                                    name='last_name'
                                    value={this.state.last_name.value}
                                    onFocus={this.removeApiError.bind(this)}
                                    onChange={this.handleChange}
                                    autoComplete='on'
                                    type={this.state.last_name.hidden ? 'hidden': 'text'}
                                    placeholder='Last Name' />
                            </div>
                        }
                        { (this.state.phone_number || {}).included &&
                            <div className='quiz-lead-input'>
                                <Input
                                    ref={c => { this.input_phone_number = c }}
                                    validations={[validators.required, validators.isMobilePhone]}
                                    className='phone'
                                    name='phone_number'
                                    value={this.state.phone_number.value}
                                    onFocus={this.removeApiError.bind(this)}
                                    onChange={this.handleChange}
                                    autoComplete='on'
                                    type={this.state.phone_number.hidden ? 'hidden': 'tel'}
                                    maxLength='10'
                                    placeholder='Phone #' />
                            </div>
                        }
                        { (this.state.email || {}).included &&
                            <div className='quiz-lead-input'>
                                <Input
                                    ref={c => { this.input_email = c }}
                                    validations={[validators.required, validators.email]}
                                    className='email'
                                    name='email'
                                    value={this.state.email.value}
                                    onFocus={this.removeApiError.bind(this)}
                                    onChange={this.handleChange}
                                    autoComplete='on'
                                    type={this.state.email.hidden ? 'hidden': 'email'}
                                    placeholder='Email' />
                            </div>
                        }
                        { (this.state.zip_code || {}).included &&
                            <div className='quiz-lead-input'>
                                <Input
                                    ref={c => { this.input_zip_code = c }}
                                    validations={[validators.required, validators.isZipCode]}
                                    className='zip_code'
                                    name='zip_code'
                                    value={this.state.zip_code.value}
                                    onFocus={this.removeApiError}
                                    onChange={this.handleChange}
                                    autoComplete='on'
                                    type={this.state.zip_code.hidden ? 'hidden': 'text'}
                                    maxLength='5'
                                    placeholder='Zip' />
                            </div>
                        }
                        { (this.state.language || {}).included &&
                            <div className='quiz-lead-input'>
                                <SelectInput
                                    name='language'
                                    handleChange={this.handleChange}
                                    value={this.state.language.value.toLowerCase()}
                                    options={languageOptions}
                                    valueName='value'
                                    labelName='label'
                                    placeholder={this.state.language.value || 'Language of Study'}
                                    klass={
                                        (this.state.language.hidden ? 'hide ': 'form-select ') +
                                        (this.state.validation_errors.language ? 'invalid-input' : '' ) }
                                    size={1}
                                />
                            </div>
                        }
                        { (this.state.level || {}).included &&
                            <div className='quiz-lead-input'>
                                <Input
                                    ref={c => { this.input_level = c }}
                                    validations={[validators.required, validators.isNumeric]}
                                    className='level'
                                    name='level'
                                    value={this.state.level.value}
                                    onFocus={this.removeApiError}
                                    onChange={this.handleChange}
                                    autoComplete='on'
                                    type={this.state.level.hidden ? 'hidden': 'text'}
                                    maxLength='2'
                                    placeholder='Level' />
                            </div>
                        }
                    </div>
                    <input type="submit" className='black-gray-button' value={this.props.submit_button_label || 'SUBMIT'} />
                </Form>
            </div>
        )
    }
}

