import React, { useState } from 'react';

import FCApi from '../../../fc-api.js';
import TimezoneSelectField from '../../TimezoneSelectField.jsx';

const TimezoneView = () => {
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const apiClient = new FCApi();

  const setTimezone = (value) => {
    setIsSubmitting(true);
    apiClient.v2UpdateStudent(
      window.user.id,
      {timezone: value},
      (error) => {
        if (!error) {
          window.location.reload();
        }
        setIsSubmitting(false);
      }
    );
  };

  return (
    <div className="TimezoneView">
      <h2 className="inverted">Account Settings</h2>
      <label>Timezone
        <TimezoneSelectField
          name="timezone"
          disabled={isSubmitting}
          value={window.user.timezone}
          onChange={(option) => {
            setTimezone(option.value);
          }}
        />
      </label>
    </div>
  );
};

export default TimezoneView;
