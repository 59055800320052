import $ from 'jquery';
require('select2');

export default function initFindclassesCTA() {

  function formatOptions(state) {
    if (!state.disabled && state.element.dataset && state.element.dataset.iconUrl) {
      return $(`<img src="${state.element.dataset.iconUrl}"><span class="select-icon-text">${state.text}</span>`);
    }
    else {
      return $(`<span>${state.text}</span>`)
    }
  }

  $(function() {

    $('.v2-plugin-findclasses-cta select').on('change',function() {
      const $ctaElement = $(this).closest('.v2-plugin-findclasses-cta');
      const userType = $ctaElement.find('select.findclasses-user-type').val();
      const subject = $ctaElement.find('select.findclasses-subject').val();


      if (userType) {
        const $btn =  $ctaElement.find('a');
        const $ctaLinkData = $ctaElement.data('linkData');

        if ($ctaLinkData[userType] && $ctaLinkData[userType][subject]) {
          $btn.prop('href',$ctaLinkData[userType][subject]);
          $btn.removeClass('disabled');
        }

      }
      else {
        $ctaElement.find('a').prop('href','').addClass('disabled');
      }

      // handle subject checkmark updates
      $ctaElement.find('.subject.subject-selected').removeClass('subject-selected');
      if (subject) {
        $ctaElement.find(`.subject[data-subject="${subject}"]`).addClass('subject-selected');
      }

    }).select2({
      templateResult: formatOptions,
      templateSelection: formatOptions
    });


    $('.v2-plugin-findclasses-cta .findclasses-cta-linkdata script[type="application/json"]').each(function(){
      $(this).closest('.v2-plugin-findclasses-cta').data('linkData', JSON.parse($(this).text()));
    });

    $('.v2-plugin-findclasses-cta .subject-icons > .subject').on('click',function(){
      const $this = $(this);
      const $subjectSelect = $this.closest('.v2-plugin-findclasses-cta').find('select.findclasses-subject');

      if (!$this.hasClass('subject-selected')) {

        $this.parent().find('.subject.subject-selected').removeClass('subject-selected');
        $this.addClass('subject-selected');

        const subjectName = $this.find('.subject-name').text();

        // note - this assumes the values are unique (which they should be...)
        $subjectSelect.val(subjectName).trigger('change');
      }
      else { // reset select to default option
        $this.removeClass('subject-selected');
        $subjectSelect.find('option:first-child').prop('selected',true).val('All Subjects');
        $subjectSelect.trigger('change');
      }

    })
    .each(function(){
      $(this).attr('data-subject', $(this).find('.subject-name').text())
    })
    .append('<img class="subject-selected-icon" src="/static/img/icon-check-pumpkin.svg">');

  });

}