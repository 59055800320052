import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import FCApi from '../../../fc-api.js';
import { ToastNotificationsError, ToastNotificationsSuccess } from '../../../notifications/ToastNotifications.jsx';

const modalStyle = {
  content: {
    margin: '15% auto',
    padding: '20px',
    border: '1px solid black',
    maxWidth: '750px',
    borderRadius: '0'
  }
};

const stripeStyle = {
  base: {
    fontSize: '16px',
    color: '#424770',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#9e2146',
  },
};

export default function UpdateCardView() {
  const stripe = useStripe();
  const elements = useElements();

  const [card, setCard] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  /*
  ** Refresh the page and fetch fresh data from the API endpoint.
  */
  const refresh = () => {
    apiClient.getDefaultCard((error, response) => {
      setCard(response);
    });
  };

  const closeUpdateModal = (e) => {
    e.preventDefault();
    setIsUpdateModalOpen(false);
  };

  const openUpdateModal = (e) => {
    e.preventDefault();
    setIsUpdateModalOpen(true);
  };

  const handleCardChange = (e) => {
    setCanSubmit(e.complete);
  }

  const doUpdate = (e) => {
    e.preventDefault();
    if (!canSubmit || isUpdating) return;

    setIsUpdating(true);

    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then(result => {
      if (result.error) {
        setIsUpdating(false);
        toast.error(
          <ToastNotificationsError closeToast={() => {}} htmlContent={<span>Error updating payment method.</span>} />,
          { autoClose: 3200 }
        );
        return;
      } else {
        apiClient.updateDefaultCard(
          {'token': result.token.id},
          (error, response) => {
            setIsUpdating(false);
            setIsUpdateModalOpen(false);
            if (!error) {
              toast.success(
                <ToastNotificationsSuccess closeToast={() => {}} htmlContent={<span>Payment method updated.</span>} />,
                { autoClose: 3200 }
              );
              setCard(response);
            } else {
              toast.error(
                <ToastNotificationsError closeToast={() => {}} htmlContent={<span>Error updating payment method.</span>} />,
                { autoClose: 3200 }
              );
            }
          }
        );
      }
    });
  };

  return (
    <div className="StudentPaymentInfoView">
      <h2 className="inverted">Payment Info</h2>
      <div className="card-info">
      {card ?
        <div className="card-details"><strong>{card.brand}</strong>: •••• •••• •••• {card.last4}</div>
      :
        <div className="card-details">No payment method added.</div>
      }
        <a onClick={openUpdateModal} className="update-payment" href="#">update payment</a>

      </div>

      <Modal
        className="update-payment-modal"
        isOpen={isUpdateModalOpen}
        style={modalStyle}
      >
        <h2 className="inverted">Update payment info</h2>

        <div className="card-form">
          <CardElement onChange={handleCardChange} options={{style: stripeStyle}} />
        </div>

        <div className="toolbar">
          {isUpdating ? <div className="spinner"></div> : ''}
          <a onClick={closeUpdateModal} href="#" className="btn inverted">Cancel</a>
          <a onClick={doUpdate} href="#" className={isUpdating || !canSubmit ? 'btn disabled' : 'btn'}>Submit</a>
        </div>
      </Modal>
    </div>
  );

}
