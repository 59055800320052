import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const Input = props => (<components.Input {...props} autoComplete="off" />);

const DEFAULT_TIMEZONES = [
  {
    name: 'America/Detroit',
    offset: '-04:00',
    group: 'Eastern Time (US and Canada)',
  },
];

const TimezoneSelectField = ({ disabled, name, value, onChange }) => {
  const timezone_choices = window.timezones || DEFAULT_TIMEZONES;
  const options = timezone_choices.map((val) => {
    return {
      value: val.name,
      label: `(GMT${val.offset}) ${val.group}`
    }
  });

  return (
    <Select
      autoComplete="nope"
      className='TimezoneSelectField mobile-bottom-margin react-select-container'
      isDisabled={disabled}
      isSearchable={false}
      name={name}
      options={options}
      value={options.filter(op => op.value === value)}
      onChange={onChange}
      components={{Input}}
    />
  );
};

TimezoneSelectField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onChange: PropTypes.func
};

export default TimezoneSelectField;
