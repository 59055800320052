import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const DatePickerField = ({ disabled, name, value, onChange }) => {
  return (
    <DatePicker
      disabled={disabled}
      name={name}
      selected={(value && new Date(value)) || null}
      onChange={val => { onChange(name, val); }}
    />
  );
};

DatePickerField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onChange: PropTypes.func.isRequired
};

export default DatePickerField;
