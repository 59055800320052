import $ from 'jquery';

/*
Toggles the slide out for the specific teacher and class combo
*/
function toggleRelevantTeacherInfo() {
    let $this = $(this),
        course_id = $this.data('course-id'),
        collapse_state = $this.data('state'),
        teacher_info = $(`.teacher-info-expandout[data-course-id="${course_id}"]`);

    if(teacher_info.length === 0) {
        console.error(`Tried to show non-existant teacher info for course id ${course_id}`);
        return;
    }
    if(teacher_info.length > 1) {
        console.error(`Tried to show duplicate teacher info for course id ${course_id}`);
        return;
    }

    if(collapse_state === 'collapsed') {
        teacher_info.slideDown(400, function() {
            $this.data('state', 'expanded')
            $this.children('img').css('transform', 'scaleY(-1)')
        })
    }
    else {
        teacher_info.slideUp(400, function() {
            $this.data('state', 'collapsed')
            $this.children('img').css('transform', 'scaleY(1)')
        })
    }
}

/*
When the close button on a teacher expandout is clicked, it is the same behavior
as the teacher link being clicked, which is the above implemented behavior, so
we just call click on that specific link.
*/
function toggleRelevantTeacher() {
    let $this = $(this),
        course_id = $this.data('course-id'),
        teacher_link = $(`.teacher-expand-link[data-course-id="${course_id}"]`);

    if(teacher_link.length === 0) {
        console.error(`Tried to hide non-existant teacher info for course id ${course_id}`);
        return;
    }
    if(teacher_link.length > 1) {
        console.error(`Tried to hide duplicate teacher info for course id ${course_id}`);
        return;
    }

    teacher_link.click()
}

export default function toggleTeacherInfoDisplay() {
    $('.teacher-expand-link').on('click', toggleRelevantTeacherInfo)
    $('.teacher-close-button').on('click', toggleRelevantTeacher)
}
