import React from 'react';
import PropTypes from 'prop-types';

const ToastNotificationsSuccess = ({ htmlContent, closeToast }) => (
  <div className='toast-notification toast-notification-success'>
    <div className='toast-notification-type-wrapper'>
      <img className='toast-notification-type' src='/static/img/checkmark-circle-black.svg' />
    </div>
    <div className='toast-notification-content-wrapper'>
      {htmlContent}
    </div>
    <div className='close' onClick={closeToast}></div>
  </div>
)

ToastNotificationsSuccess.propTypes = {
  htmlContent: PropTypes.element.isRequired,
  closeToast: PropTypes.func.isRequired,
};

const ToastNotificationsError = ({ htmlContent, closeToast }) => (
  <div className='toast-notification toast-notification-error'>
    <div className='toast-notification-type-wrapper'>
      <img className='toast-notification-type' src='/static/img/x-black.svg' />
    </div>
    <div className='toast-notification-content-wrapper'>
      {htmlContent}
    </div>
    <div className='close' onClick={closeToast}></div>
  </div>
)

ToastNotificationsError.propTypes = {
  htmlContent: PropTypes.element.isRequired,
  closeToast: PropTypes.func.isRequired,
};

export {
  ToastNotificationsSuccess,
  ToastNotificationsError
}