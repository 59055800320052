import React from 'react';
import PropTypes from 'prop-types';

import ResourceCard from './ResourceCard.jsx';

const ResourceCollectionCard = ({ collection, searchFilter, expanded, expandCard }) => {
  // Hide resource collections not visible to students
  if (window.user_type == 'student' && collection.isTeacherFacing) {
    return null;
  }

  // Sort resources by weight ascending
  let resources;
  switch (collection.collectionType) {
    case 'course':
      resources = collection.activity_collection.weighted_external_resources;
      resources.sort((a, b) => {
        return a.weight - b.weight;
      });
      break;
    case 'uploads':
      resources = collection.resources;
      break;
    case 'activityCollection':
      resources = collection.weighted_external_resources;
      resources.sort((a, b) => {
        return a.weight - b.weight;
      });
  }

  const getCollectionTitle = () => {
    switch (collection.collectionType) {
      case 'activityCollection':
        return collection.title;
      case 'uploads':
        return collection.name;
      case 'course':
        return collection.template.name;
    }
  }

  const getCollectionBackgroundImage = () => {
    if (!collection.language) {
      return null;
    }
    return collection.language.flag_url;
  }

  const getResourceTitle = (res) => {
    if (collection.collectionType == 'uploads') {
      return res.name || res.original_filename;
    }
    return res.external_resource.title;
  };

  const getResourceDesc = (res) => {
    if (collection.collectionType == 'uploads') {
      return '';
    }
    return res.external_resource.description;
  };

  const getResourceUrl = (res) => {
    if (collection.collectionType == 'uploads') {
      return res.url;
    }
    return res.external_resource.url || res.external_resource.file.url;
  };

  const getIsVisible = (res) => {
    if (collection.collectionType == 'uploads') {
      return true;
    }

    if (res.external_resource.is_teacher_facing && window.user_type == 'student') {
      return false;
    }

    return true;
  }

  // Filter resources if search term provided
  if (searchFilter) {
    const regex = new RegExp(searchFilter, 'i');

    resources = resources.filter(res => (
      regex.test(getResourceTitle(res)) ||
      regex.test(getResourceDesc(res))
    ));
  }

  let resourceCount = 0;
  for (const res of resources) {
    if (getIsVisible(res)) {
      resourceCount += 1;
    }
  }

  return (
    <div className="resource-collection-card">
      <div className="card-header" onClick={expandCard}>
        <div className="flag-img" style={{
          backgroundImage: `url(${getCollectionBackgroundImage()})`
        }}></div>
        <div className="card-header-right">
          <h2>{getCollectionTitle()}</h2>

          <span className="resource-count">
            {resourceCount} resource(s)
          </span>
        </div>
        <span className={`toggle ${expanded ? 'expanded' : ''}`}>{'\u25c0'}</span>
      </div>
      <div className={`card-body ${!expanded ? 'hidden' : ''}`}>
        <div className="resources">
        {resources.map((resource, index) => (
          <ResourceCard
            key={index}
            title={getResourceTitle(resource)}
            desc={getResourceDesc(resource)}
            url={getResourceUrl(resource)}
            isVisible={getIsVisible(resource)}
          />
        ))}
        </div>
      </div>
    </div>
  );
};

ResourceCollectionCard.propTypes = {
  collection: PropTypes.object.isRequired,
  searchFilter: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandCard: PropTypes.func.isRequired
};

export default ResourceCollectionCard;
