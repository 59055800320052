import moment from 'moment-timezone';
import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';

const BookLessonsCalendarTooltip = ({ session, onCancel, onSave }) => {
  const startAt = moment(session.timeslot.start);
  const endAt = moment(session.timeslot.end);

  const tooltipRef = createRef();

  useEffect(() => {
    // 900px is the mobile/desktop breakpoint set in _breakpoints.scss
    if (window.innerWidth >= 900) {
      const node = tooltipRef.current;
      let adjustment = 410;
      // 1200px is the desktop/large desktop breakpoint set in _breakpoints.scss
      if (window.innerWidth >= 1200) {
        adjustment = 510;
      }
      // the purpose of the < 0 code is to make sure the tooltip doesn't go off the left side of the screen
      // if the click event on the left side of the screen
      const shouldShiftRight = (
        session.timeslot.box !== undefined
        ? (session.timeslot.box.x - adjustment) < 0
        : (session.timeslot.bounds.left - adjustment) < 0
      );
      let left = (
        session.timeslot.box !== undefined
        ? (shouldShiftRight ? session.timeslot.box.x + 20 : session.timeslot.box.x - adjustment)
        : (shouldShiftRight ? session.timeslot.bounds.left + 20 : session.timeslot.bounds.left - adjustment)
      );

      const top = (
        session.timeslot.box !== undefined
        ? session.timeslot.box.y - 60
        : session.timeslot.bounds.top - 60
      );

      if (shouldShiftRight) {
        node.classList.add('shifted-right');
      } else {
        node.classList.remove('shifted-right');
      }
      node.style.left = `${left}px`;
      node.style.top = `${top}px`;
    }
  }, [session]);

  moment.tz.setDefault(window.user_timezone || 'America/Detroit')

  const flag_image = session.language && session.language.flag_image;

  return (
    <div className="book-lessons-calendar-tooltip calendar-tooltip" ref={tooltipRef}>
      <div className="flag-image-wrapper">
        {flag_image &&
          <img className="flag-image" src={flag_image} />
        }
      </div>

      <div className="inner-content">
        <div className="close" onClick={onCancel}></div>
        <div className='tooltip-row-outer'>
          <h2>Book A Lesson</h2>

          <div className='tooltip-row'>
            <span className="icon-clock-o row-icon"></span>
            <span>{startAt.format('ddd, MMM Do')} &#47;&#47; {startAt.format('h:mm a')} - {endAt.format('h:mm a')} {startAt.format('z')}</span>
          </div>

          <div className="tooltip-row">
            <span className="icon-user row-icon"></span>
            <span><b>Instructor:</b>&nbsp;{session.teacher.privacy_protected_name}</span>
          </div>
        </div>
        <div className="tooltip-row flex-end">
          <a onClick={() => onCancel()}>Cancel</a>
          <button onClick={() => onSave()}>Save</button>
        </div>
      </div>
    </div>
  )
};

BookLessonsCalendarTooltip.propTypes = {
  session: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default BookLessonsCalendarTooltip;
