import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types'
import { toast } from 'react-toastify';

import { apiUrls } from '../constants.js';
import FCApi from '../fc-api.js';
import { ToastNotificationsError } from '../notifications/ToastNotifications.jsx';


const AttachmentManager = ({ sessionType, sessionId, initialAttachments, maxFiles, disabled, readOnly }) => {
  const [attachments, setAttachments] = useState(initialAttachments);

  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(null);

  const fileFieldRef = useRef();
  const apiClient = new FCApi();

  const canAdd = () => {
    return !disabled && attachments.length < maxFiles;
  }

  const canDelete = () => {
    return !disabled;
  }

  const showHelp = () => {
    return !readOnly;
  }

  const resetFileField = () => {
    fileFieldRef.current.value = '';
  };

  const showErrorMessage = (msg) => {
    toast.error(
      <ToastNotificationsError closeToast={() => {}} htmlContent={msg} />,
      { autoClose: 6400 }
    );
  };

  const addFile = (file) => {
    setAttachments(attachments => [...attachments, file]);
  };

  const removeFile = (fileId) => {
    setAttachments(attachments => attachments.filter(a => a.id !== fileId));
  }

  const uploadFile = (file) => {
    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    let method = apiClient.v2CreatePrivateSessionAttachment.bind(apiClient);
    if (sessionType == 'classroom') {
      method = apiClient.v2CreateClassroomSessionAttachment.bind(apiClient);
    }

    method(
      sessionId,
      formData,
      (error, response) => {
        if (!error) {
          addFile(response);
        } else {
          if (response.file) {
            showErrorMessage(response.file[0]);
          } else {
            showErrorMessage('Unable to upload file. Please try again.');
          }
        }
        resetFileField();
        setIsUploading(false);
      }
    );
  };

  const deleteFile = (fileId) => {
    setIsDeleting(fileId);

    let method = apiClient.v2DestroyPrivateSessionAttachment.bind(apiClient);
    if (sessionType == 'classroom') {
      method = apiClient.v2DestroyClassroomSessionAttachment.bind(apiClient);
    }

    method(
      sessionId,
      fileId,
      (error) => {
        if (!error) {
          removeFile(fileId);
        } else {
          showErrorMessage('Unable to remove file. Please try again.');
        }
        setIsDeleting(null);
        setIsUploading(false)
      }
    );
  };

  const downloadLink = (attachmentId) => {
    let url = apiUrls.v2.privateSessionAttachments;
    if (sessionType == 'classroom') {
      url = apiUrls.v2.classroomSessionAttachments;
    }

    return `/api${url(sessionId, attachmentId)}/download`;
  }

  return (
    <div className="AttachmentManager">
      {showHelp() && <div className="help-text">
        Upload up to {maxFiles} files less than 5MB. Allowed types are .doc, .docx, .gif, .jpg,
        .mp3, .mp4, .odt, .png, .pdf, .txt.
      </div>}
      <ul className="attachment-list">
        {attachments.map((attachment, index) => (
          <li key={index} className="attachment">
            <a className="download-link" href={downloadLink(attachment.id)}>{attachment.name}</a>
            {canDelete() && <a onClick={(e) => {e.preventDefault(); deleteFile(attachment.id)}} className="delete-link" href="#">{'\u274C'}</a>}
            {isDeleting === attachment.id && <div className="delete-spinner spinner"></div>}
          </li>
        ))}
      </ul>
      {canAdd() &&
        <>
          <input
            onChange={(e) => uploadFile(e.target.files[0])}
            type="file"
            ref={fileFieldRef}
            disabled={isUploading}
          />
          {isUploading && <div className="upload-spinner spinner"></div>}
        </>
      }
    </div>
  );
};

AttachmentManager.propTypes = {
  sessionType: PropTypes.string,
  sessionId: PropTypes.string.isRequired,
  initialAttachments: PropTypes.array.isRequired,
  maxFiles: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired
};

AttachmentManager.defaultProps = {
  sessionType: 'private'
};

export default AttachmentManager;
