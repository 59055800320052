import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import isInt from 'validator/es/lib/isInt';

const ChildParticipantForm = ({ participant, isValidating, onChange }) => {
  const values = participant || {
     first_name: '',
     last_name: '',
     age: '',
     accept_terms: false
  };

  const handleChange = (e) => {
    // get name and value properties from event target
    const {name, value} = e.target
    const newValues = {
      ...values,
      [name]: value
    };
    onChange(isValid(newValues), newValues);
  }

  const handleTermsChange = () => {
    const newValues = {
      ...values,
      accept_terms: !values.accept_terms
    };

    if (newValues.accept_terms) {
      newValues.accepted_at = moment().toISOString();
    } else {
      newValues.accepted_at = null;
    }

    onChange(isValid(newValues), newValues);
  }

  const getErrors = (values) => {
    let errors = {};
    if (!values.first_name) {
      errors.first_name = 'This field is required.';
    }
    if (!values.last_name) {
      errors.last_name = 'This field is required.';
    }
    if (!values.age || !isInt(values.age)) {
      errors.age = 'Please enter a number.';
    }
    if (!values.accept_terms) {
      errors.accept_terms = 'You must accept the terms to continue.';
    }
    return errors;
  }

  const isValid = (values) => {
    const errors = getErrors(values);
    return $.isEmptyObject(errors);
  }

  const showErrors = (name) => {
    const errors = getErrors(values);
    if (!isValidating || !errors[name]) {
      return;
    }
    return (
      <div className="form-errors">{errors[name]}</div>
    );
  }

  return (
    <div className="participant-form">
      <div className="form-group">
        <label htmlFor="first_name">First Name</label>
        <input name="first_name" value={values.first_name} onChange={handleChange} />
        {showErrors('first_name')}
      </div>
      <div className="form-group">
        <label htmlFor="last_name">Last Initial</label>
        <input className="input-small" maxLength="1" name="last_name" value={values.last_name} onChange={handleChange} />
        {showErrors('last_name')}
      </div>
      <div className="form-group">
        <label htmlFor="age">Age</label>
        <input className="input-small" name="age" value={values.age} onChange={handleChange} />
        {showErrors('age')}
      </div>
      <div className="form-group checkbox-group">
        <input id="accept_terms" type="checkbox" name="accept_terms" checked={values.accept_terms} onChange={handleTermsChange} />
        <div>
          <label htmlFor="accept_terms"><strong>I have reviewed Cricket eLearning’s below Privacy and Disclaimer Notices for Minors</strong></label>
          {showErrors('accept_terms')}
        </div>
      </div>
    </div>
  );
};

ChildParticipantForm.propTypes = {
  participant: PropTypes.object.isRequired,
  isValidating: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ChildParticipantForm;
