import React from 'react';
import PropTypes from 'prop-types';

const TeacherPicture = (props) => {
  let teacher = props.teacher;

  return (
    <div className="teacher-picture">
      <img src={teacher.photo} />
      <div className="teacher-name-background">
        <div className="teacher-name no-translate">{teacher.privacy_protected_name}</div>
      </div>
    </div>
  )
};

TeacherPicture.propTypes = {
  teacher: PropTypes.object.isRequired,
};

export default TeacherPicture;
