import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import GroupProductModalDetails from './GroupProductModalDetails.jsx';
import PrivateProductModalDetails from './PrivateProductModalDetails.jsx';

const ProductDetailModal = ({ isOpen, closeModal, product }) => {
  if (!product) {
    return null;
  }

  const renderDetails = () => {
    if (product.enrolled_in) {
      return <GroupProductModalDetails product={product} />
    } else {
      return <PrivateProductModalDetails product={product} />
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      className="ProductDetailModal"
      shouldCloseOnOverlayClick={true}
      appElement={$('.ProfileApp').get(0)}
      onRequestClose={closeModal}
    >
      <div className='close' onClick={() => closeModal()}></div>
      {renderDetails()}
    </Modal>
  );
};

ProductDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  product: PropTypes.object
};

export default ProductDetailModal;
