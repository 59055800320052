import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MyResourcesListView from './MyResourcesListView.jsx';

const MyResourcesView = () => {
  return (
    <div className="MyResourcesView">
      <Routes>
        <Route index element={<MyResourcesListView />} />
      </Routes>
    </div>
  );
};

export default MyResourcesView;
