import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import FCApi from '../../../fc-api.js';

const USER_TIMEZONE = window.user_timezone ? window.user_timezone : 'America/Detroit';
moment.tz.setDefault(USER_TIMEZONE);

const MyCoursesDetailView = () => {
  const { courseId } = useParams();

  const backUrl = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
  const [ course, setCourse ] = useState(null);

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    apiClient.v2FetchCourse(
      courseId,
      (error, response) => {
        if (!error) {
          setCourse(response);
        }
      },
      'expand=template,enrollments.student,class_sessions.teacher'
    );
  };

  const getEndTime = (course) => {
    const dur = moment.duration(course.end_time) - moment.duration(course.start_time);
    return moment(course.start_at).add(dur);
  }

  const fmtSkipDates = (course) => {
    let fmtDates = [];
    for (const skipDate of course.skip_dates) {
      fmtDates.push(moment(skipDate).format('MMM. D, YYYY'));
    }
    return fmtDates.join(', ');
  }

  return (
    <div className="MyCoursesDetailView">
      <div className="my-courses-header">
        <Link to={backUrl} className="back-link" href="#">&larr; Back to my courses</Link>
      </div>
      {course ? (<>
        <h2 className="inverted my-courses-name">
          <span>Course information</span>
          {course.template.name}
        </h2>
        <div className="my-courses-profile">
          <div className="profile-item">
            <h2>Schedule</h2>
            <span><strong>Start date: </strong>{moment(course.start_at).format('MMM. D, YYYY')}</span>
            <span><strong>End date: </strong>{moment(course.end_date).format('MMM. D, YYYY')}</span>
            <span><strong>Time: </strong>{moment(course.start_at).format('h:mmA')}-{getEndTime(course).format('h:mmA z')}</span>
            <span><strong>Skip dates: </strong>{fmtSkipDates(course)}</span>
          </div>
        </div>
      </>) : ''}
      {course && course.enrollments ? (<>
        <div className="my-courses-sessions">
          <h3 className="inverted">Students</h3>
          <table className="sessions-table">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Dropped</th>
            </tr>
          {course.enrollments.map((enrollment, index) => (
            <tr key={index}>
              <td>{enrollment.first_name} {enrollment.last_name}</td>
              <td>{enrollment.email}</td>
              <td>{enrollment.dropped ? '✔' : ''}</td>
            </tr>
          ))}
          </tbody>
          </table>
        </div>
      </>) : ''}
      {course && course.class_sessions ? (<>
        <div className="my-courses-sessions">
          <h3 className="inverted">Lessons History</h3>
          <table className="sessions-table">
          <tbody>
            <tr>
              <th>Date</th>
              <th>Status</th>
              <th>Teacher</th>
              <th>Details</th>
            </tr>
          {course.class_sessions.map((session, index) => (
            <tr key={index}>
              <td>{moment(session.start_at).tz(window.user.timezone).format('MMMM D, YYYY')}</td>
              <td className="session-status">{session.status}</td>
              <td>{session.teacher
                ? session.teacher.first_name + ' ' + session.teacher.last_name[0] + '.'
                : 'No Teacher'}
              </td>
              <td>
                <Link to={`sessions/${session.id}`} className="edit-session">
                  {!session.teacher || window.user.id != session.teacher.id ? 'View' : ''}
                  {session.teacher && window.user.id == session.teacher.id ? (
                    session.status == 'scheduled' ? 'View' :
                    session.status == 'complete' && !session.notes ? <span className="add-summary">Add summary</span>
                    : 'View'
                  ): ''}
                </Link>
              </td>
            </tr>
          ))}
          </tbody>
          </table>
        </div>
      </>) : ''}
    </div>
  );
};

export default MyCoursesDetailView;
