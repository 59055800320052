import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { private_lessonURL, kidsPurchaseUrl } from '../../../constants.js';
import FCApi from '../../../fc-api.js';
import ProgressBar from '../../../general/ProgressBar.jsx';
import SelectLearnerView from './SelectLearnerView.jsx';
import SelectLessonView from './SelectLessonView.jsx';
import TeacherSelectorView from './TeacherSelectorView.jsx';
import BookLessonsCalendarView from './BookLessonsCalendarView.jsx';
import LanguageList from './LanguageList.jsx';

export default function BookLessonsView() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ learners, setLearners ] = useState([]);
  const [ privateLessons, setPrivateLessons ] = useState([]);
  const [ languages, setLanguages ] = useState([]);

  let callCount = 0;

  const apiClient = new FCApi();

  const progressSteps = [
    'Select a Learner',
    'Select a Package',
    'Select a Subject',
    'Select a Teacher',
    'Select a Time'
  ];

  const startLoad = () => {
    callCount += 1;
    setIsLoading(true);
  };

  const endLoad = () => {
    callCount -= 1;
    if (callCount <= 0) {
      callCount = 0;
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const imageUrl = '/static/img/assets/account/student-book-lessons-header.jpg'
    $('.medium-hero.student-view').css('background-image', 'url(' + imageUrl + ')');
    preFetch();
  }, []);

  const preFetch = () => {
    startLoad();
    apiClient.v2ListLearners((error, response) => {
      if (!error) {
        setLearners(response);
      }
      endLoad();
    });

    startLoad();
    apiClient.v2ListPrivateLessons(
      (error, response) => {
        if (!error) {
          setPrivateLessons(response);
        }
        endLoad();
      },
      'expand=learner,package.valid_languages,language'
    );

    startLoad();
    apiClient.getLanguages(
      (error, response) => {
        if (!error) {
          setLanguages(response);
        }
        endLoad();
      }
    )
  };

  const SelectLearner = () => (
    <div>
      <ProgressBar
        currentStep={0}
        steps={progressSteps}
      />
      <SelectLearnerView
        learners={learners}
      />
    </div>
  );

  const SelectLesson = () => {
    const { learnerId } = useParams();
    const navigate = useNavigate();

    let lessons = privateLessons.filter(lesson => lesson.learner.id == learnerId && lesson.is_active);
    let learner = learners.find(learner => learner.id == learnerId);

    const navigatePreview = (e) => {
      e.preventDefault();
      navigate('preview');
    };

    return (
      <div>
        <ProgressBar
          currentStep={1}
          steps={progressSteps}
        />
        <SelectLessonView
          learner={learner}
          lessons={lessons}
        />
        <div className='navigation-buttons'>
          <button
            onClick={() => {
              $('#me')[0].scrollIntoView(true);
              navigate('../', {relative: 'path'});
            }}
            className='dark-button'
          >Back</button>
          {lessons.length > 0 ? (
            <a
              className="right-btn dark-button"
              href={learner.learner_type == 'child' ? kidsPurchaseUrl : private_lessonURL + '#subscription-plans'}
            >Add Package</a>
          ) : (
            <>
              <a
                className="right-btn dark-button tooltip"
                href="#"
                onClick={navigatePreview}
              >
                Preview<span className="tooltiptext">Check out when our teachers are available for private lessons!</span>
              </a>
              <span className="mobile-text">Check out when our teachers are available for private lessons!</span>
            </>
          )}
        </div>
      </div>
    );
  };

  const SelectLanguage = () => {
    const { learnerId, lessonId } = useParams();
    const navigate = useNavigate();

    return (
      <div>
        <ProgressBar
          currentStep={2}
          steps={progressSteps}
        />
        <LanguageList
          learner={learners.find(learner => learner.id == learnerId)}
          lesson={privateLessons.find(lesson => lesson.id == lessonId)}
        />
        <div className='navigation-buttons'>
          <button
            onClick={() => {
              $('#me')[0].scrollIntoView(true);
              navigate('../', {relative: 'path'});
            }}
            className='dark-button'
          >Back</button>
        </div>
      </div>
    );
  };

  const SelectTeacher = () => {
    const { learnerId, lessonId, languageSlug } = useParams();
    const navigate = useNavigate();

    return (
      <div>
        <ProgressBar
          currentStep={3}
          steps={progressSteps}
        />
        <TeacherSelectorView
          learner={learners.find(learner => learner.id == learnerId)}
          lesson={privateLessons.find(lesson => lesson.id == lessonId)}
          language={languageSlug}
        />
        <div className='navigation-buttons'>
          <button
            onClick={() => {
              $('#me')[0].scrollIntoView(true);
              navigate('../', {relative: 'path'});
            }}
            className='dark-button'
          >Back</button>
        </div>
      </div>
    );
  };

  const TeacherCalendar = () => {
    const { learnerId, lessonId, languageSlug } = useParams();
    const navigate = useNavigate();

    return (
      <div>
        <ProgressBar
          currentStep={4}
          steps={progressSteps}
        />
        <BookLessonsCalendarView
          learner={learners.find(learner => learner.id == learnerId)}
          lesson={privateLessons.find(lesson => lesson.id == lessonId)}
          language={languages.find(language => language.slug == languageSlug)}
        />
        <div className='navigation-buttons'>
          <button
            onClick={() => {
              $('#me')[0].scrollIntoView(true);
              navigate('../', {relative: 'path'});
            }}
            className='dark-button'
          >Back</button>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="book-lessons-calendar loading">Loading...</div>
    );
  }

  return (
    <div className="BookLessonsView">
      <h2 className='inverted'>Book Lessons</h2>
      <Routes>
        <Route index element={<SelectLearner />} />
        <Route path=":learnerId" element={<SelectLesson />} />
        <Route path=":learnerId/:lessonId" element={<SelectLanguage />} />
        <Route path=":learnerId/:lessonId/:languageSlug" element={<SelectTeacher />} />
        <Route path=":learnerId/:lessonId/:languageSlug/:teacherId" element={<TeacherCalendar />} />
      </Routes>
    </div>
  );
}
