import React, { useState, useEffect } from 'react';

import FCApi from '../../../fc-api.js';

import LearnerCard from './LearnerCard.jsx';

const MyStudentsListView = () => {
  const [ filterLearners, setFilterLearners ] = useState('active');
  const [ learners, setLearners ] = useState([]);

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    apiClient.v2ListLearners(
      (error, response) => {
        if (!error) {
          setLearners(response);
        }
      }
    );
  };

  const onLearnersFilterChange = (event) => {
    const value = event.target.value;
    setFilterLearners(value);
  }

  const renderLearnerCards = () => {
    let filteredLearners = learners.filter(l => l.active);
    if (filterLearners == 'inactive') {
      filteredLearners = learners.filter(l => !l.active);
    }

    return (
      <div className="learner-cards">
      {filteredLearners.map((learner, index) => (
        <LearnerCard
          key={index}
          learner={learner}
        />
      ))}
      </div>
    );
  }

  return (
    <div className="MyStudentsListView">
      <h2 className="inverted">My Private Students</h2>
      <div className='filter-section'>
        <div className='filter-wrapper'>
          Filter students:&nbsp;&nbsp;<select value={filterLearners} onChange={onLearnersFilterChange}>
            <option value={'active'}>Active</option>
            <option value={'inactive'}>Inactive</option>
          </select>
        </div>
        { renderLearnerCards() }
      </div>
    </div>
  );
};

export default MyStudentsListView;
