import PropTypes from 'prop-types'
import React from 'react'
import urls from '../constants.js'


export default class OverviewTotal extends React.Component {
    // Displays cart total for CartOverview page
    static propTypes = {
        lineitems: PropTypes.array.isRequired,
    }

    getPriceSubtotal = () => {
        let total = 0
        this.props.lineitems.forEach(line => {
            if (line.children.length > 0) {
                line.children.forEach(child => {
                    total += child.quantity * child.price
                })
            }
            total += line.quantity * line.price
        })
        return total.toFixed(2)
    }

    getItemTotal = () => {
        let total = 0
        this.props.lineitems.forEach(line => {
            total += line.quantity
            if (line.children.length > 0) {
                line.children.forEach(child => {
                    total += child.quantity
                })
            }
        })
        return total
    }

    render() {
        let item_count = this.getItemTotal()
        let txt = `${item_count} item${item_count > 1 ? 's' : ''}`
        return (
            <div className='OverviewTotal'>
                <h2 className='OverviewTotal__header'>Order Details</h2>
                <div className='OverviewTotal__total'>
                    <h3>Total Order</h3>
                    <h3>{`(${txt}) $${this.getPriceSubtotal()}`}</h3>
                </div>
                <div className='OverviewTotal__button'>
                    <a href={urls.cartCheckoutLearnersUrl} className='dark-button on-color'>Check Out</a>
                    <p>Got a discount code? <br />Add it on the third step.</p>
                </div>
            </div>
        )
    }
}
