import React, { useState, useEffect } from 'react';

import Order from './Order.jsx';

export default function OrderHistory() {
  const [ orders, setOrders ] = useState([]);

  // TODO: get this via API request! NOT VIA WINDOW OBJECT

  useEffect(() => {
    const imageUrl = '/static/img/assets/account/student-my-lessons-header.jpg'
    $('.medium-hero.student-view').css('background-image', 'url(' + imageUrl + ')');
    if (Array.isArray(window.orders) && window.orders.length > 0) {
      setOrders(window.orders);
    }
  }, []);
  if (orders.length > 0 ) {
    return (
      <div className="OrderHistoryView">
        <div className="orders-container">
          <h2 className="inverted">Order History</h2>
          <div className="row labels desktop-labels">
            <div className='label name'>name</div>
            <div className='label date'>order date</div>
            <div className='label order'>order</div>
            <div className='label total'>total</div>
          </div>
          {orders.map((order, index) => {
            return <Order key={index} order={order} />
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="OrderHistoryView">
        <h2 className="inverted">Order History</h2>
        <div className='account-details-emptystate'>
          <h1 className='emptystate-header'>nothing yet</h1>
          <img alt="sadface" className="sadface" src='/static/img/assets/account/face.svg'/>
          <div>
            <div className="need-to-try-callout">You never know. Until you try.</div>
            <a className="navigation" href="/group-language-courses">what&apos;s new</a>
          </div>
        </div>
      </div>
    );
  }
}
