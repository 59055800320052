import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

moment.tz.setDefault(window.user_timezone || 'America/Detroit')

export default function Order({order}) {
  return (
    <div className='order-container'>
      <div className="row labels mobile-labels">
        <h6 className='label name'>name</h6>
        <h6 className='label date'>date</h6>
        <h6 className='label order'>order no.</h6>
        <h6 className='label total'>total</h6>
      </div>
      {order.lineitems.map((lineitem, index) => {
        const title = lineitem.product !== null ? lineitem.product.title || lineitem.product.template || lineitem.product.name : '';
        return (
          <div key={index} className="row order-details">
            <div className='order-detail name'>{ title } (for { lineitem.quantity })</div>
            <div className='order-detail date'>{ moment(order.created).format('MMMM D, YYYY') }</div>
            <div className='order-detail order'>{ order.id }</div>
            <div className='order-detail total'>${ lineitem.price }</div>
          </div>
        );
      })}
    </div>
  );
}

Order.propTypes = {
    order: PropTypes.object.isRequired,
}
