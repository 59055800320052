import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';


export default class TimePickerField extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    onChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    let time, amPm;
    const selected = this.props.value && moment(this.props.value, [moment.ISO_8601, 'HH:mm']) || null;
    if (selected) {
      time = selected.format('hh:mm');
      amPm = selected.format('a');
    }

    // TODO(BDH): This could probably be done stateless, but the solution
    // wasn't immediately clear.
    this.state = {
      time: time,
      amPm: amPm
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(key, event) {
    this.setState({
      [key]: event.target.value
    }, () => {
      let selected = moment(
        `${this.state.time} ${this.state.amPm}`,
        'hh:mm a'
      )
      this.props.onChange(
        this.props.name,
        selected.format('HH:mm')
      )
    });
  }

  render() {
    const quarters = ['00', '15', '30', '45'];
    const hours = ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09',
                   '10', '11'];

    return (
      <React.Fragment>
        <select
          disabled={this.props.disabled}
          value={this.state.time}
          name={`${this.props.name}_time`}
          onChange={(e) => this.handleInputChange('time', e)}
        >
        {hours.map(hour => {
          return quarters.map(quarter => {
              let val = `${hour}:${quarter}`;
              return (
                <option key={val} value={val}>{val}</option>
              );
          });
        })}
        </select>
        &nbsp;
        <select
          disabled={this.props.disabled}
          value={this.state.amPm}
          name={`${this.props.name}_ampm`}
          onChange={(e) => this.handleInputChange('amPm', e)}
        >
          <option value={'am'}>am</option>
          <option value={'pm'}>pm</option>
        </select>
      </React.Fragment>
    );
  }
}
