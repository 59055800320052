import $ from 'jquery';

function updateDynStyle(mediaQuery, styleName) {
  const $dynStyle = $(`.dyn-${styleName}`);
  if ($dynStyle.length) {
    $dynStyle.each(function(){
      const $el = $(this);

      if (mediaQuery.matches) {
        $el.css(styleName, $el.data(`${styleName}-mobile`));
      } else {
        $el.css(styleName, $el.data(`${styleName}-std`));
      }

    });
  }
}

export default function calcDynamicMargins() {
  $(function() {
    // medium breakpoint - there is probably a way to pull this
    // value programmatically rather than hard-coding...
    const mediaQuery = window.matchMedia('(max-width: 54em)');

    function updateDynMargins() {
      updateDynStyle(mediaQuery, 'margin');
      updateDynStyle(mediaQuery, 'padding');
    }

    // Initial check
    updateDynMargins();

    // Add listener for changes
    mediaQuery.addEventListener('change',updateDynMargins);
  });
}
