import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { private_lessonURL, kidsPurchaseUrl } from '../../../constants.js';
import LearnerProfile from './LearnerProfile.jsx';

const LearnerDetails = ({ learner, languages, onUpdateLearner, openModal }) => {
  const [ showLearnerProfile, setShowLearnerProfile ] = useState(false);
  const [ showInactivePackages, setShowInactivePackages ] = useState(false);

  learner.private_lessons.sort((a, b) => a.expires_on < b.expires_on ? 1 : -1);
  learner.enrollments.sort((a, b) => a.enrolled_in.start_date < b.enrolled_in.start_date ? 1 : -1);

  let activePrivateLessons = learner.private_lessons.filter(pl => pl.is_active);
  let expiredPrivateLessons = learner.private_lessons.filter(pl => !pl.is_active);
  let activeEnrollments = learner.enrollments.filter(e => e.is_active);
  let expiredEnrollments = learner.enrollments.filter(e => !e.is_active);

  let hasExpired = expiredPrivateLessons.length > 0 || expiredEnrollments.length > 0;
  let hasSubscription = learner.private_lessons.filter(pl => pl.package.is_subscription).length > 0;

  const toggleLearnerProfile = (e) => {
    e.preventDefault();
    if (showLearnerProfile) {
      setShowLearnerProfile(false);
    } else {
      setShowLearnerProfile(true);
    }
  };

  const toggleInactivePackages = (e) => {
    e.preventDefault();
    if (showInactivePackages) {
      setShowInactivePackages(false);
    } else {
      setShowInactivePackages(true);
    }
  };

  const toggleProductModal = (e, product) => {
    e.preventDefault();
    openModal(product);
  };

  return (
    <div className="LearnerDetails">
      <div className="learner-header">
        <strong className="learner-name no-translate">{learner.first_name} {learner.last_name}</strong>
        <a className="learner-edit-profile" href="#" onClick={toggleLearnerProfile}>View/Edit Profile</a><i onClick={toggleLearnerProfile} className={'arrow ' + (showLearnerProfile ? 'down' : 'right')}></i>
      </div>

      {showLearnerProfile &&
        <div className="learner-profile">
          <LearnerProfile
            learner={learner}
            languages={languages}
            onUpdateLearner={onUpdateLearner}
          />
        </div>
      }

      <div className="learner-packages">
        <table className="learner-packages-table inactive-packages">
          <tbody>
          <tr className="package-header">
            <th>NAME</th>
            <th>SUBJECT</th>
            <th>DETAILS</th>
            <th className="package-header-time">TIME</th>
           </tr>
          {activePrivateLessons.map((pl, index) => (
            <tr key={index}>
              <td className="package-title">{pl.package.title} {pl.package.is_subscription ? 'Plan*' : 'Package'}</td>
              <td className="package-language">{pl.language ? pl.language.name : 'Unassigned'}</td>
              <td><a className="no-transform" href="#" onClick={(e) => toggleProductModal(e, pl)}>Details</a></td>
              <td className="hours-remaining">{parseFloat(pl.hours_remaining)} hour{parseFloat(pl.hours_remaining) == 1 ? '' : 's'} remaining</td>
            </tr>
          ))}
          {activeEnrollments.map((enrollment, index) => (
            <tr key={index}>
              <td className="package-title">Group Course</td>
              <td className="package-language">{enrollment.enrolled_in.template.name}</td>
              <td><a className="no-transform" href="#" onClick={(e) => toggleProductModal(e, enrollment)}>Details</a></td>
              <td className="hours-remaining">
                {parseFloat(enrollment.enrolled_in.session_count)}
                {parseFloat(enrollment.enrolled_in.session_count) == 1
                  ? ' total session'
                  : ' total sessions' }
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        <div className="add-package-cta">
          <a
            className="dark-button"
            href={learner.learner_type == 'child' ? kidsPurchaseUrl : private_lessonURL + '#subscription-plans'}
          >Add Package</a>
        </div>

        {hasExpired && <>
          <a className="learner-show-inactive no-transform" href="#" onClick={toggleInactivePackages}>Inactive Products</a><i onClick={toggleInactivePackages} className={'arrow ' + (showInactivePackages ? 'down' : 'right')}></i>
          {showInactivePackages &&
            <table className="learner-packages-table">
              <tbody>
              {expiredPrivateLessons.map((pl, index) => (
                <tr key={index}>
                  <td className="package-title">{pl.package.title} {pl.package.is_subscription ? 'Plan*' : 'Package'}</td>
                  <td className="package-language">{pl.language ? pl.language.name : 'Unassigned'}</td>
                  <td><a className="no-transform" href="#" onClick={(e) => toggleProductModal(e, pl)}>Details</a></td>
                  <td className="hours-remaining">Expired</td>
                </tr>
              ))}
              {expiredEnrollments.map((enrollment, index) => (
                <tr key={index}>
                  <td className="package-title">Group Course</td>
                  <td className="package-language">{enrollment.enrolled_in.template.name}</td>
                  <td><a className="no-transform" href="#" onClick={(e) => toggleProductModal(e, enrollment)}>Details</a></td>
                  <td className="hours-remaining">Completed</td>
                </tr>
              ))}
              </tbody>
            </table>
          }
        </>}
        {hasSubscription &&
          <div className="subscription-disclaimer">
            *Billed automatically
          </div>
        }
      </div>
    </div>
  );
};

LearnerDetails.propTypes = {
  learner: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  onUpdateLearner: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

export default LearnerDetails;

