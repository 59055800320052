import axios from 'axios';
import queryString from 'query-string';
import React from 'react';

import { apiUrls } from './constants.js';

export default class FCApi {
  constructor() {
    this.options = {
      baseUri: `${location.protocol}//${location.host}/api`
    }

    this.baseRequestOptions = {
      // For future use
    };
  }

  makeUri(path) {
    return `${this.options.baseUri}${path}`;
  }

  doRequest(method, path, cb, data, requestOptions) {
    let localRequestOptions = requestOptions || {};
    let params = localRequestOptions.params || {};
    params._ts = Date.now();
    localRequestOptions.params = params;

    const url = this.makeUri(path);
    const options = {
      method,
      url,
      data,
      ...this.baseRequestOptions,
      ...localRequestOptions,
    };

    let headers = options.headers || {};
    headers['X-CSRFToken'] = window.csrfToken;
    options.headers = headers;

   axios.request(options).then((response) => {
      cb(false, response.data);
    }).catch((error) => {
      if (error.response && error.response.status == 400) {
        cb(true, error.response.data);
      }
      else {
        cb(true, null);
        console.error('API error:', error);
      }
    });
  }

  formatErrors(errors) {
    errors = errors || {};
    let formatted = {};
    if ('data' in errors) { errors = errors['data']; }
    for (var key in errors) {
      if (!Array.isArray(errors[key])) {
        errors[key] = [errors[key]]
      }
      formatted[key] = errors[key].map((text, index) => (
        <React.Fragment key={`${text}-${index}`}>
          {text}
          <br />
        </React.Fragment>
      ));
    }
    return formatted;
  }

  // Common

  getLanguages(cb, languageId) {
    this.doRequest('GET', apiUrls.v1.languages(languageId), cb);
  }

  // Payments

  listPayments(cb, args={}) {
    this.doRequest(
      'GET',
      args ?
        `${apiUrls.v1.payments}?${queryString.stringify(args)}` :
        apiUrls.v1.payments,
      cb
    );
  }

  // Private lesson packages

  listPrivateLessonPackages(cb) {
    this.doRequest('GET', apiUrls.v1.privateLessonPackages, cb);
  }

  // Private lessons

  createPrivateSessionByLesson(lessonId, data, cb) {
    this.doRequest('POST', apiUrls.v1.privateSessions(lessonId), cb, data);
  }

  deletePrivateSessionByLesson(lessonId, sessionId, cb) {
    this.doRequest('DELETE', apiUrls.v1.privateSessionDetail(lessonId, sessionId), cb);
  }

  cancelPrivateSessionByLesson(lessonId, sessionId, cb) {
    this.doRequest('POST', apiUrls.v1.privateSessionCancel(lessonId, sessionId), cb);
  }

  // Private Sessions

  listPrivateSessions(cb, args='') {
    this.doRequest(
      'GET',
      args ?
        `${apiUrls.v1.standalonePrivateSessions()}?${args}` :
        apiUrls.v1.standalonePrivateSessions(),
      cb
    );
  }

  getPrivateSession(sessionId, cb, args='') {
    this.doRequest(
      'GET',
      args ?
        `${apiUrls.v1.standalonePrivateSessions(sessionId)}?${args}` :
        apiUrls.v1.standalonePrivateSessions(sessionId),
      cb
    );
  }

  updatePrivateSession(sessionId, data, cb, args='') {
    this.doRequest(
      'PATCH',
      args ?
        `${apiUrls.v1.standalonePrivateSessions(sessionId)}?${args}` :
        apiUrls.v1.standalonePrivateSessions(sessionId),
      cb,
      data
    );
  }

  deletePrivateSession(sessionId, cb, args='') {
    this.doRequest(
      'DELETE',
      args ?
        `${apiUrls.v1.standalonePrivateSessions(sessionId)}?${args}` :
        apiUrls.v1.standalonePrivateSessions(sessionId),
      cb
    );
  }

  cancelPrivateSession(sessionId, cb, args='') {
    this.doRequest(
      'POST',
      args ?
        `${apiUrls.v1.standalonePrivateSessions(sessionId)}/cancel?${args}` :
        `${apiUrls.v1.standalonePrivateSessions(sessionId)}/cancel`,
      cb
    );
  }

  createStudentPrivateSession(data, cb, args='') {
    this.doRequest(
      'POST',
      args ?
        `${apiUrls.v1.standalonePrivateSessions()}?${args}` :
        apiUrls.v1.standalonePrivateSessions(),
      cb,
      data
    );
  }

  // Teacher Availabilities
  listTeacherAvailabilities(cb, args='') {
    this.doRequest(
      'GET',
      args ?
        `${apiUrls.v1.teacherAvailabilities()}?${args}` :
        apiUrls.v1.teacherAvailabilities(),
      cb
    );
  }

  createTeacherAvailability(data, cb, args='') {
    this.doRequest(
      'POST',
      args ?
        `${apiUrls.v1.teacherAvailabilities()}?${args}`:
        apiUrls.v1.teacherAvailabilities(),
      cb,
      data
    );
  }

  deleteTeacherAvailability(availabilityId, cb, args='') {
    this.doRequest(
      'DELETE',
      args ?
      `${apiUrls.v1.teacherAvailabilities(availabilityId)}?${args}`:
      apiUrls.v1.teacherAvailabilities(availabilityId),
      cb
    );
  }

  // Skip Dates

  listSkipDates(cb, args='') {
    this.doRequest(
      'GET',
      args ?
        `${apiUrls.v1.skipDates}?${args}` :
        apiUrls.v1.skipDates,
      cb
    );
  }

  // Profiles

  fetchProfile(cb) {
    this.doRequest('GET', apiUrls.v1.myProfile, cb);
  }

  updateProfile(data, cb) {
    var formData = new FormData();
    let key;
    for (key in data) {
      formData.append(key, data[key]);
    }
    this.doRequest('PATCH', apiUrls.v1.myProfile, cb, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    });
  }

  // Teachers

  listTeachers(cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v1.teachers()}?${args}` : apiUrls.v1.teachers(),
      cb
    )
  }

  getTeacher(id, cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v1.teachers(id)}?${args}` : apiUrls.v1.teachers(id),
      cb
    )
  }

  // Stripe/subscriptions

  getDefaultCard(cb) {
    this.doRequest('GET', apiUrls.v1.defaultCard, cb);
  }

  updateDefaultCard(data, cb) {
    this.doRequest('POST', apiUrls.v1.defaultCard, cb, data);
  }

  getSubscription(id, cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v1.subscriptions(id)}?${args}` : apiUrls.v1.subscriptions(id),
      cb
    )
  }

  updateSubscription(id, data, cb) {
    this.doRequest('PATCH', apiUrls.v1.subscriptions(id), cb, data);
  }

  // V2 URLS

  // Account

  v2FetchStudent(id, cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.students(id)}?${args}` : apiUrls.v2.students(id),
      cb
    )
  }

  v2UpdateStudent(id, data, cb, args='') {
    this.doRequest(
      'PATCH',
      args ? `${apiUrls.v2.students(id)}?${args}` : apiUrls.v2.students(id),
      cb,
      data
    )
  }

  v2ListStudents(cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.students()}?${args}` : apiUrls.v2.students(),
      cb
    )
  }

  // Classroom

  v2ListCourses(cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.courses()}?${args}` : apiUrls.v2.courses(),
      cb
    )
  }

  v2FetchCourse(id, cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.courses(id)}?${args}` : apiUrls.v2.courses(id),
      cb
    )
  }

  v2ListClassroomSessions(cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.classroomSessions()}?${args}` : apiUrls.v2.classroomSessions(),
      cb
    )
  }

  v2FetchClassroomSession(id, cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.classroomSessions(id)}?${args}` : apiUrls.v2.classroomSessions(id),
      cb
    )
  }

  v2UpdateClassroomSession(id, data, cb, args='') {
    this.doRequest(
      'PATCH',
      args ? `${apiUrls.v2.classroomSessions(id)}?${args}` : apiUrls.v2.classroomSessions(id),
      cb,
      data
    )
  }

  v2CreateClassroomSessionAttachment(sessionId, data, cb) {
    this.doRequest(
      'POST',
      apiUrls.v2.classroomSessionAttachments(sessionId),
      cb,
      data
    )
  }

  v2DestroyClassroomSessionAttachment(sessionId, id, cb) {
    this.doRequest(
      'DELETE',
      apiUrls.v2.classroomSessionAttachments(sessionId, id),
      cb
    )
  }

  // Learners

  v2CreateLearner(data, cb, args='') {
    this.doRequest(
      'POST',
      args ? `${apiUrls.v2.learners()}?${args}`: apiUrls.v2.learners(),
      cb,
      data
    );
  }

  v2ListLearners(cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.learners()}?${args}` : apiUrls.v2.learners(),
      cb
    )
  }

  v2FetchLearner(id, cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.learners(id)}?${args}` : apiUrls.v2.learners(id),
      cb
    );
  }

  v2UpdateLearner(id, data, cb, args='') {
    this.doRequest(
      'PATCH',
      args ? `${apiUrls.v2.learners(id)}?${args}` : apiUrls.v2.learners(id),
      cb,
      data
    );
  }

  // Private lessons

  v2ListPrivateLessons(cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.privateLessons()}?${args}` : apiUrls.v2.privateLessons(),
      cb
    )
  }

  v2FetchPrivateSession(id, cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.privateSessions(id)}?${args}` : apiUrls.v2.privateSessions(id),
      cb
    )
  }

  v2UpdatePrivateSession(id, data, cb, args='') {
    this.doRequest(
      'PATCH',
      args ? `${apiUrls.v2.privateSessions(id)}?${args}` : apiUrls.v2.privateSessions(id),
      cb,
      data
    )
  }

  v2ListPrivateSessions(cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.privateSessions()}?${args}` : apiUrls.v2.privateSessions(),
      cb
    )
  }

  v2CreatePrivateSessionAttachment(sessionId, data, cb) {
    this.doRequest(
      'POST',
      apiUrls.v2.privateSessionAttachments(sessionId),
      cb,
      data
    )
  }

  v2DestroyPrivateSessionAttachment(sessionId, id, cb) {
    this.doRequest(
      'DELETE',
      apiUrls.v2.privateSessionAttachments(sessionId, id),
      cb
    )
  }

  // Resources

  v2ListActivityCollections(cb, args='') {
    this.doRequest(
      'GET',
      args ? `${apiUrls.v2.activityCollections()}?${args}` : apiUrls.v2.activityCollections(),
      cb
    )
  }
}
