import PropTypes from 'prop-types'
import React from 'react'
import $ from 'jquery'
import constants from '../constants.js'
import LeadForm from './forms/lead-form.jsx'


export default class PhoneCapture extends React.Component {

    static propTypes = {
        quizOverviewReturn: PropTypes.func.isRequired,
        language_prefill: PropTypes.string,
        form_location: PropTypes.string,
        languageOptions: PropTypes.array,
        cta_btn_url: PropTypes.string,
        cta_btn_text: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            showCompletionPage: false,
        }
    }

    componentDidMount() {
        window.dataLayer.push({'event': 'talk_to_person'})
    }

    submitForm = callback => {
        $.post({
            url: constants.salesLeadsUrl,
            data: $('#quiz-lead-form').serialize(),
        }).done(function () {
            this.setState({showCompletionPage: true})
        }.bind(this)).fail(data => {
            // Returns obj with input fields as keys
            var errorObj = JSON.parse(data.responseText)
            var api_errors = []
            Object.keys(errorObj).map((key) => {
                Object.keys(errorObj[key]).map((index) => {
                    api_errors.push(errorObj[key][index].message)
                })
            })
            return callback({
                submitSuccess: false,
                show_api_error: true,
                api_errors: api_errors,
                validation_errors: {},
                show_validation_error: false,
            })
        })
    }

    returnToOverview = e => {
        e.preventDefault()
        this.props.quizOverviewReturn()
    }

    render() {
        if (this.state.showCompletionPage) {
            return (
                <section className="quiz-box lead-capture-page">
                    <div className="lead-capture-header">
                        <h1>Awesome</h1>
                        <p>We&apos;ll be in touch as soon as we can!</p>
                    </div>
                </section>
            )
        }
        return (
            <section className="quiz-box lead-capture-page">
                <div className='lead-capture-header'>
                    <h4>Fill out your contact information and we will have an advisor call you to chat!</h4>
                </div>
                <div className='lead-capture-content'>
                    <LeadForm
                        submit_button_label={'SUBMIT'}
                        submit_func={this.submitForm}
                        form_location={'quiz-phoneCapture'}
                        first_name={{
                            'included': true,
                            'hidden': false,
                            'value': ''}}
                        phone_number={{
                            'included': true,
                            'hidden': false,
                            'value': ''}}
                        email={{
                            'included': true,
                            'hidden': false,
                            'value': ''}}
                        zip_code={{
                            'included': true,
                            'hidden': false,
                            'value': ''}}
                        language={{
                            'included': true,
                            'hidden': false,
                            'value': this.props.language_prefill ? this.props.language_prefill : ''}} />
                    <a className="black-gray-button quiz-nav-btn" onClick={this.returnToOverview} href="#">
                        <img alt='right arrow icon' src='/static/img/logos/arrow-left.svg'/>
                        <span>return to quiz options</span>
                    </a>
                </div>
            </section>
        )
    }
}
