import PropTypes from 'prop-types'
import React from 'react'
import $ from 'jquery'
import PhoneCapture from './phone-capture.jsx'
import QuizSeries from './quiz-series.jsx'


export default class QuizOverview extends React.Component {
    // This Component represents the Quiz Series List View Page that provides
    // an overview of all QuizSeries that are available to the user.
    static propTypes = {
        quiz_series: PropTypes.array.isRequired,
        showPhoneCapture: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            // bool to trigger phone capture page
            showPhoneCapture: props.showPhoneCapture,
            // array of quiz series objects
            quiz_series: props.quiz_series,
            current_series: {'page_count': 0},
        }
    }

    componentDidMount() {
        $('#quiz-homepage').addClass('hide')
        window.dataLayer.push({'event': 'assessment_overview_page'})
    }

    startSeries = series => {
        window.dataLayer.push({ 'event': 'starts_quiz', 'language': series.name})
        // start the series if pages exist, else toggle the phone
        // capture state prefilled with the language of the selected series
        this.setState({
            current_series: series,
            showPhoneCapture: series.page_count === 0,
        })
    }

    clearCurrentSeries = () => {
        this.setState({current_series: {}})
    }

    togglePhoneCapture = () => {
        this.setState({showPhoneCapture: !this.state.showPhoneCapture})
    }

    render() {
        if (this.state.showPhoneCapture) {
            return (
                <PhoneCapture
                    language_prefill={this.state.current_series ? this.state.current_series.language : ''}
                    quizOverviewReturn={this.togglePhoneCapture}
                />
            )
        }
        else if (this.state.current_series.page_count > 0) {
            return (
                <QuizSeries
                    description={this.state.current_series.description}
                    image={this.state.current_series.image}
                    name={this.state.current_series.name}
                    page_count={this.state.current_series.page_count}
                    question_count={this.state.current_series.question_count}
                    quizzes={this.state.current_series.quizzes}
                    quizOverviewReturn={this.clearCurrentSeries}
                    show_page_feedback={this.state.current_series.show_page_feedback}
                    series_id={this.state.current_series.id}
                />
            )
        } else {
            return (
                <section className="quiz-box">
                    <div className="quiz-header-sm">
                        <h2>Pick Your Language</h2>
                        <p>The test takes approximately 5 minutes to complete.</p>
                    </div>
                    <div className="quiz-series-options-wrapper">
                        <div className="series-options">
                            {this.props.quiz_series.map((series, index) => {
                                return (
                                    <div className="quiz-list-item" key={index} onClick={() => {this.startSeries(series)}}>
                                        <img src={ series.image } alt="quiz item image"/>
                                        <h3>{ series.name }</h3>
                                    </div>
                                )
                            })}
                        </div>
                        <a className='black-gray-button quiz-nav-btn' onClick={this.togglePhoneCapture} href="#">
                        <span>TALK WITH AN ADVISOR</span>
                        <img alt='right arrow icon' src='/static/img/logos/speech-bubbles.svg'/>
                        </a>
                    </div>
                </section>
            )
        }
    }
}
