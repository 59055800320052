// helpful general purpose utilties


export function isString(obj) {
    // return true if an object is a string
    return toString.call(obj) == '[object String]';
}

export function isArguments(obj) {
    // return true if an object is Arguments type
    return toString.call(obj) == '[object Arguments]';
}

export function isArray(obj) {
    // return true if an object is an array
    return toString.call(obj) == '[object Array]';
}

export function isFunction(obj) {
    // copy of underscore.js isFunction method
    // https://jsperf.com/alternative-isfunction-implementations/36
    return obj && {}.toString.call(obj) === '[object Function]';
}

export function isObject(obj) {
    // copy of underscore.js isObject method
    // http://underscorejs.org/#isObject
    var type = typeof obj;
    return type === 'function' || type === 'object' && !!obj;
}

export function isJSONString(obj) {
    // detect if an object is a parasable JSON string.
    if (!isString(obj)) return false;
    try {
        JSON.parse(obj);
        return true;
    } catch (err) {
        return false;
    }
}

export function isEmpty(obj) {
    // return true if a given array, string, or object is empty
    if (obj == null) return true;
    return Object.getOwnPropertyNames(obj).length === 0;
}

export function getattr(object, key, default_value=false) {
    // copy of Lodash/underscore.js _.has() function
    // to loosely replicate Pythons getattr() funciton
    return isObject(object) ? hasOwnProperty.call(object, key) : default_value;
}

export function allKeys(obj) {
    // copy of underscore.js allKeys method
    // http://underscorejs.org/#allKeys
    if (!isObject(obj)) return [];
    var keys = [];
    for (var key in obj) keys.push(key);
    return keys;
}

export function pick(obj, ...fields) {
    // ghetto version of underscores pick method
    // example use:
    //   var obj = {a: 1, b: 2, c: 3}
    //   var two = pick(obj, 'a', 'b');
    return fields.reduce((a, name) => {
        if (obj.hasOwnProperty(name)) a[name] = obj[name];
        return a;
    }, {});
}

export function arraysEqual(a, b) {
    // compare two arrays for equality
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    // remove the calls to .sort() if you care about order.
    a.sort();
    b.sort();

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export function ghettoCopy(item) {
  // ghetto version of deepCopy.
  // NOTE: this breaks on things like nested Date() objects.
  // For a more comprehensive version checkout lodash.deepCopy
  return JSON.parse(JSON.stringify(item));
}

export function bankersRound(n, d = 0) {
    // round-half-even method
    let x = n * Math.pow(10, d);
    let r = Math.round(x);
    let br = (((((x>0)?x:(-x))%1)===0.5)?(((0===(r%2)))?r:(r-1)):r);
    return br / Math.pow(10, d);
}

export function collapse_whitespace(txt) {
    // Collapse extranious white space into a single space.
    // NOTE: this leaves the leading/trailing spaces intact.
    // EXAMPLE:
    //     "hello     there"  =>  "hello there"
    try {
        txt = txt.replace(/\s{2,}/g, ' ');  // remove 2+ character spaces
    } catch(err) {
        if (!(err instanceof TypeError)) throw err;
    }
    return txt;
}

export function shuffleArray(d) {
    // Fisher-Yates shuffle algorithm.
    for (var c = d.length - 1; c > 0; c--) {
        var b = Math.floor(Math.random() * (c + 1))
        var a = d[c]
        d[c] = d[b]
        d[b] = a
    }
    return d
}

export function titleCase(str) {
    str = str.split(' ');

    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(' ');
}
