import PropTypes from 'prop-types'
import React from 'react'
import { courseDetailUrl } from '../constants.js'

export default class CourseCard extends React.Component {

    static propTypes = {
        city: PropTypes.string,
        course: PropTypes.object,
    }

    goToProduct = () => {
        const course = this.props.course
        let url = course.url || ''
        if (!url) {
            url = courseDetailUrl(course.parent_cat_slug, course.slug);
        }
        window.location.pathname = url
    }

    nameConcisely = fullName => {
        // Return a concise title based on full course name.
        // TODO: do title manipulation on the server side
        const oneWordTitles = ['Essentials', 'Progressions', 'Conversation']
        const words = fullName.split(' ')
        if (words.length === 2 && !isNaN(words[1])) {
            // 1. Change 'Arabic 2' to 'Level 2':
            return 'Level ' + words[1]
        } else if (words.length === 2 && oneWordTitles.includes(words[1])) {
            // 2. Change 'French Essentials' to 'Essentials:.
            return words[1]
        } else if (words[2] + ' ' + words[3] === 'Crash Course') {
            // 3. Change 'Spanish 2 Crash Course' to 'Crash Course 2:.
            return 'Crash Course ' + words[1]
        } else {
            return fullName
        }
    }

    render () {
        const is_pl = this.props.course.name === 'Private'
        return (
            <div>
                <div className='lang-course-card'>
                    <div>
                        <img src={'/static/img/assets/stats_icons/icon-group-classes.svg'}></img>
                        <h2>{this.nameConcisely(this.props.course.name)}</h2>
                    </div>
                    <h4>{is_pl ? 'Personalized Experience': 'GROUP COURSE'}</h4>
                    <p>{this.props.course.short_description}</p>
                    <h2>{is_pl ? 'From $32/Hour' : ( `$${this.props.course.price}`)}</h2>
                    <button onClick={this.goToProduct} className={this.props.course.slug + '-button' + ' dark-button'}>Details</button>
                </div>
            </div>
        )
    }
}
