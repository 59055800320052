const cms = document.getElementById('cms-top');

export default function selectFix() {
    if (!cms) {
        document.addEventListener('DOMContentLoaded', () => {
            $('select').on('select2:open', () => {
                const containers = document.getElementsByClassName('select2-dropdown--below');
                for (const container of containers) {
                    container.style.position = 'static';
                }
            })
        })
    }
}