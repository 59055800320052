import React from 'react';
import PropTypes from 'prop-types';

const EmptySessionsCard = ({ userType }) => {
  return (
    <div className='empty-sessions-card'>
      <h2 className='empty-sessions-card-header'>Nothing Yet</h2>
      {
        (userType == 'teacher')
        ? (
          <h6>Stay tuned, students will be booking lessons soon.</h6>
        ) :
        (
          <div className='empty-sessions-card-inner'>
            <h6>There’s no time like the present! Schedule a lesson and start learning now.</h6>
          </div>
        )
      }
    </div>
  );
};

EmptySessionsCard.propTypes = {
  userType: PropTypes.string.isRequired,
};

export default EmptySessionsCard;
