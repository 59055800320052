import PropTypes from 'prop-types'
import React from 'react'
import ChildLineItem from './ChildLineItem.jsx'


export default class LineItem extends React.Component {
  // Display component for a parent LineItem
  // renders any child lineitems if they exist
  // shows increment, decrement and remove options if 'allowEdits' is true
  static propTypes = {
    line: PropTypes.object.isRequired,
    allowEdits: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    removeLine: PropTypes.func,
    incrementLine: PropTypes.func,
    decrementLine: PropTypes.func,
    getLineTotal: PropTypes.func,
  }

  getLineTotal = line => {
    // calculate the total price of an individual lineitem
    let price = parseFloat(line.price) * line.quantity;
    price = parseFloat(price).toFixed(2);
    if (line.stripe_plan) {
      price = price + '*';
    }
    return price;
  }

  render() {
    return (
      <div className='LineItem'>
        <div className="LineItem__body">
          <div className="LineItem__body__left">
            <h2>{this.props.line.cart_display.title}</h2>
            <div className='LineItem__desc'>
              {this.props.line.cart_display.details.map((detail, index) => {
                return (<p key={index}>{detail}</p>)
              })}
            </div>

            {this.props.line.children.map((child, index) => {
              return (
                <ChildLineItem
                  key={index}
                  line={child}
                  getLineTotal={this.getLineTotal} />
              )
            })}
          </div>

          <div className="LineItem__body__right">
            <p className="LineItem__price">${this.getLineTotal(this.props.line)}</p>

            {!this.props.line.stripe_plan ?
              <div className='LineItem__qty'>
                { this.props.allowEdits &&
                  <div
                    className='LineItem__incr'
                    onClick={() => {this.props.decrementLine(this.props.line)}}>
                    <h4>-</h4>
                  </div>
                }
                <h4>{`${this.props.allowEdits ? '' : 'x'} ${this.props.line.quantity}`}</h4>
                { this.props.allowEdits &&
                  <div
                    className='LineItem__incr'
                    onClick={() => {this.props.incrementLine(this.props.line)}}>
                    <h4>+</h4>
                  </div>
                }
              </div>
            :
              <div className='LineItem__qty'>
                Renews Monthly
              </div>
            }
          </div>

        </div>

        <div className="LineItem__extras">
        {this.props.line.cart_display.disclaimer &&
          <div className='LineItem__sub_warning'>
            {this.props.line.cart_display.disclaimer}
          </div>
        }
        {this.props.allowEdits &&
          <a className='LineItem__remove'
             onClick={(e) => {e.preventDefault(); this.props.removeLine(this.props.line)}}>
             <div className='CircleIcon'><span>&#215;</span></div>
             <span className='CircleIcon__label'>Remove</span>
          </a>
        }
        </div>
      </div>
    )
  }
}
