
/*
 * Grab the roulette options from a hidden HTML element.
 */
function getOptionsData() {
  var elem = document.getElementById('roulette-wheel-data');
  return JSON.parse(elem.dataset.options);
}


export default function rouletteWheel() {

  const options = getOptionsData() || [];
  const coloroptions = [
    '#b6f8ff',  // blue
    '#FFC1CA',  // pink
    '#FBEE75',  // yellow
  ];
  const font_family = 'BureauGrotCondensed, MaisonNeue, sans-serif';

  var canvas = document.getElementById('canvas')
  var startAngle = 0;
  var arc = Math.PI / (options.length / 2);
  var spinTimeout = null;
  var rotateWheel = null;
  var spinAngleStart = 10;
  var spinTime = 0;
  var spinTimeTotal = 0;
  var ctx;

  canvas.addEventListener('click', spin);
  window.addEventListener('resize', resize, false);

  /*
   * Adjust the canvas for mobile and desktop.
   */
  function resize() {
    var canvas = document.getElementById('canvas');
    var windowwidth = window.innerWidth
    var width;
    var height;

    // resize for mobile version
    if (windowwidth < 600) {
      height = 320;  // window.innerHeight;
      width = 320;
    } else {
      width = 500;
      height = 500;
    }
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
  }

  /*
   * Ease out the spinning motion of the roulette wheel
   */
  function easeOut(t, b, c, d) {
    var ts = (t /= d) * t;
    var tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  }


  /*
   * Draw a downward facing arrow at the top of the Roulette wheel.
   */
  function drawArrow(ctx, outsideRadius) {
    ctx.fillStyle = 'black';
    ctx.beginPath();
    ctx.moveTo(250 - 4, 250 - (outsideRadius + 5));
    ctx.lineTo(250 + 4, 250 - (outsideRadius + 5));
    ctx.lineTo(250 + 4, 250 - (outsideRadius - 5));
    ctx.lineTo(250 + 9, 250 - (outsideRadius - 5));
    ctx.lineTo(250 + 0, 250 - (outsideRadius - 13));
    ctx.lineTo(250 - 9, 250 - (outsideRadius - 5));
    ctx.lineTo(250 - 4, 250 - (outsideRadius - 5));
    ctx.lineTo(250 - 4, 250 - (outsideRadius + 5));
    ctx.fill();
  }

  /*
   * Render the text that appears inside the center of the circle
   */
  function drawText(text, ctx) {
      let text_width = -ctx.measureText(text).width / 2;
      ctx.fillText(text, text_width, 0);
  }


  /*
   * Construct a segemented roulette wheel.
   */
  function drawRouletteWheel(outsideRadius=200) {
    const insideRadius = Math.floor(outsideRadius * 0.6);
    const textRadius = Math.floor(outsideRadius * 0.77);
    const size = outsideRadius * 2 + 100;

    if (canvas.getContext) {
      ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, size, size);
      ctx.strokeStyle = 'Teal';
      ctx.lineWidth = 1;
      ctx.font = '18px ' + font_family;

      for (var i = 0; i < options.length; i++) {
        var angle = startAngle + i * arc;
        ctx.fillStyle = coloroptions[i % 3];

        ctx.beginPath();
        ctx.arc(250, 250, outsideRadius, angle, angle + arc, false);
        ctx.arc(250, 250, insideRadius, angle + arc, angle, true);
        ctx.stroke();
        ctx.fill();
        ctx.shadowOffsetX = -10;
        ctx.shadowOffsetY = -10;
        ctx.shadowBlur = 30;
        ctx.shadowColor = 'rgba(0,0,0,0.2)';
        ctx.save();
        ctx.fillStyle = 'black';
        ctx.translate(
          250 + Math.cos(angle + arc / 2) * textRadius, 
          250 + Math.sin(angle + arc / 2) * textRadius
        );
        ctx.rotate(angle + arc / 2 + Math.PI / 2);
        var text = options[i];

        drawText(text, ctx, outsideRadius);
        ctx.restore();
      } 

      drawArrow(ctx, outsideRadius);
    }
  }

  function stopRotateWheel() {
    clearTimeout(spinTimeout);
    var degrees = startAngle * 180 / Math.PI + 90;
    var arcd = arc * 180 / Math.PI;
    var index = Math.floor((360 - degrees % 360) / arcd);
    var text = options[index]

    ctx.save();
    ctx.font = '25px ' + font_family;
    ctx.fillStyle = 'black';
    ctx.fill();
    ctx.fillText(text, 250 - ctx.measureText(text).width / 2, 250 + 10);
    ctx.restore();
  }

  rotateWheel = function() {
    spinTime += 30;
    if(spinTime >= spinTimeTotal) {
      stopRotateWheel();
      return;
    }
    var spinAngle = spinAngleStart - easeOut(spinTime, 0, spinAngleStart, spinTimeTotal);
    startAngle += (spinAngle * Math.PI / 180);
    drawRouletteWheel();
    spinTimeout = setTimeout(function() { rotateWheel() }, 30);
  }

  function spin() {
    spinAngleStart = Math.random() * 10 + 10;
    spinTime = 0;
    spinTimeTotal = Math.random() * 3 + 4 * 1000;
    rotateWheel();
  }

  drawRouletteWheel();

}
