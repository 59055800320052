import moment from 'moment-timezone';
import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';

import { shiftTimezone } from './utils.jsx';

const USER_TIMEZONE = window.user_timezone !== '' ? window.user_timezone : 'America/Detroit';
moment.tz.setDefault();

const StudentCalendarTooltip = ({ session, onCloseTooltip, onCancel }) => {
  let left = session.x;
  let top = session.y - 20;

  const startAt = moment(shiftTimezone(session.start_at, USER_TIMEZONE, moment.tz.guess()));
  const endAt = moment(shiftTimezone(session.end_at, USER_TIMEZONE, moment.tz.guess()));

  const tooltipRef = createRef()

  useEffect(() => {
    // 900px is the mobile/desktop breakpoint set in _breakpoints.scss
    if (window.innerWidth >= 900) {
      const node = tooltipRef.current;
      node.style.top = `${top - node.offsetHeight}px`;
      if (left > document.body.clientWidth - 300) {
        node.style.left = `${left - 450}px`;
        node.classList.add('shifted-left');
      } else {
        node.style.left = `${left - 250}px`;
        node.classList.remove('shifted-left');
      }
    }
  }, [session]);

  const is_online_with_link = (
    session.teacher.virtual_classroom
    && startAt > moment().startOf('day')
  )

  const flag_image = session.language ? session.language.flag_image : null;

  return (
    <div
      className='teacher-calendar-tooltip calendar-tooltip'
      ref={tooltipRef}
    >
      <div className='close' onClick={() => onCloseTooltip()}></div>
      <div className='flag-image-wrapper'>
        {flag_image &&
          <img className='flag-image' src={flag_image} />
        }
      </div>
      <div className='inner-content'>
        <div className='session-type-wrapper'>
          <img className='session-type-icon' src={session.is_online ? '/static/img/assets/account/my_lessons/online-session-icon.jpg' : '/static/img/assets/account/my_lessons/private-session-icon.jpg'} />
          <h6 className='session-type'>{session.is_online ? 'Online' : 'Private'}{session.lesson.language && ` // ${session.lesson.language.name}`}</h6>
        </div>
        <h2
          className={(is_online_with_link) ? 'reduce-bottom-margin' : ''}
        >
          {session.teacher.privacy_protected_name}
        </h2>
        <span className='session-datetime'>{startAt.format('dddd[,] MMMM Do')} <br/> {startAt.format('h:mm a')}-{endAt.format('h:mm a')} {startAt.format('z')}</span>
        <div className='action-container'>
          {session.status == 'scheduled' &&
            <div className='session-link-wrapper'>
              <a onClick={() => onCancel()}>
                <h6>Cancel Lesson</h6>
              </a>
            </div>
          }
          {is_online_with_link &&
            <div className='session-link-wrapper online-session-link-wrapper'>
              <a
                href={session.teacher.virtual_classroom}
                target='_blank'
                rel="noopener noreferrer"
              >
                <h6>Start Lesson</h6>
              </a>
              <img src='/static/img/right-arrow.jpg'/>
            </div>
          }
        </div>
      </div>
    </div>
  )
};

StudentCalendarTooltip.propTypes = {
  session: PropTypes.object.isRequired,
  onCloseTooltip: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default StudentCalendarTooltip;
