import React from 'react';

import StudentLearnersView from './StudentLearnersView.jsx';
import TimezoneView from './TimezoneView.jsx';

const AccountOverviewView = () => {

  return (
    <>
      <StudentLearnersView />
      <TimezoneView />
    </>
  );
};

export default AccountOverviewView;
