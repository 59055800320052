import $ from 'jquery';

export default function initQuestionAndAnswerSections() {

  $(function() {
    $('.v2-plugin-q-and-a .q-and-a-question').on('click', function(e) {
      e.preventDefault();
      const $parent = $(this).parent();
      if ($parent.hasClass('active')) {
        $parent.removeClass('active');
      } else {
        $parent.parent().find('.q-and-a-item.active').removeClass('active');
        $parent.addClass('active');
      }
    });
  });

}