import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { ToastNotificationsError, ToastNotificationsSuccess } from '../../../notifications/ToastNotifications.jsx';

import FCApi from '../../../fc-api.js';

const MyStudentsDetailView = () => {
  const { studentId } = useParams();

  const backUrl = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
  const [ learner, setLearner ] = useState(null);
  const [ startingPoint, setStartingPoint ] = useState('');
  const [ isUpdating, setIsUpdating ] = useState(false);
  const [ activityCollection, setActivityCollection ] = useState({});
  const [teacherActivityCollections, setTeacherActivityCollections ] = useState([])
  const [resources, setResources ] = useState([])

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    fetchActivityCollections()
    apiClient.v2FetchLearner(
      studentId,
      (error, response) => {
        if (!error) {
          setLearner(response);
          sortResources(response)
          if (response.starting_point) {
            setStartingPoint(response.starting_point);
          }
          if (response.activity_collection){
            setActivityCollection(response.activity_collection)
          } else {
            setActivityCollection(defaultActivityCollection)
          }
        }
      },
      'expand=student,language_of_interest,private_lessons.package,private_lessons.language,private_sessions.teacher,activity_collection.weighted_external_resources.external_resource'
    );
  };

  const fetchActivityCollections = () => {
    apiClient.v2ListActivityCollections(
      (error, response) => {
        setTeacherActivityCollections(response.concat(defaultActivityCollection))
      },
      ''
    )
  }

  const changeActivityCollection = (event) => {
    const val = event.target.value;
    if (val == activityCollection) {
      return;
    }
    const oldActivityCollection = activityCollection;
    val === '-1' ? setActivityCollection(defaultActivityCollection) : setActivityCollection(val)
    setIsUpdating(true);
    apiClient.v2UpdateLearner(
      studentId,
      {activity_collection: val === '-1' ? null : val,
       starting_point: val === '-1' ? null : 1},
      (error) => {
        setIsUpdating(false);
        if (error) {
          const message = 'Unable to update activity collection.';
          toast.error(
            <ToastNotificationsError closeToast={() => {}} htmlContent={message} />,
            { autoClose: 6400 }
          );
          setActivityCollection(oldActivityCollection);
        } else {
          refresh()
          const message = 'Activity Collection updated.';
          toast.success(
            <ToastNotificationsSuccess closeToast={() => {}} htmlContent={message} />,
            { autoClose: 3200 }
          );
        }
      }
    );
  }

  const changeStartingPoint = (event) => {
    const val = event.target.value;
    if (val == startingPoint) {
      return;
    }

    const oldStartingPoint = startingPoint;
    setStartingPoint(val);
    setIsUpdating(true);

    apiClient.v2UpdateLearner(
      studentId,
      {starting_point: val},
      (error) => {
        setIsUpdating(false);
        if (error) {
          const message = 'Unable to update learning plan.';
          toast.error(
            <ToastNotificationsError closeToast={() => {}} htmlContent={message} />,
            { autoClose: 6400 }
          );
          setStartingPoint(oldStartingPoint);
        } else {
          const message = 'Learning plan updated.';
          toast.success(
            <ToastNotificationsSuccess closeToast={() => {}} htmlContent={message} />,
            { autoClose: 3200 }
          );
        }
      }
    );
  }

  const sortResources = (learner) => {
    let activities = []

    if (learner && learner.activity_collection) {
      activities = learner.activity_collection.weighted_external_resources;
      activities.sort((a, b) => {
        let w1 = a.session_number || 0;
        let w2 = b.session_number || 0;
        return w1 - w2;
      });
    }
    setResources(activities)
  }

  let privateLessons = [];
  if (learner && learner.private_lessons) {
    // Filter private lessons that either expire in future or have `ignore_expiration`
    // and have remaining hours.
    privateLessons = learner.private_lessons.filter(pl => pl.is_active);

    // Sort by `ignore_expiration` followed by expiration date.
    privateLessons.sort((pl1, pl2) => (
      ((pl1.ignore_expiration ? 1 : -1) - (pl2.ignore_expiration ? 1 : -1)) ||
      moment(pl1.expires_on).unix() - moment(pl2.expires_on).unix()
    ));
  }

  const defaultActivityCollection = {
    id: -1, title: '---', slug: '---', description: 'Select an Activity Collection', is_teacher_facing: true
  }

  return (
    <div className="MyStudentsDetailView">
      <div className="my-students-header">
        <Link to={backUrl} className="back-link" href="#">&larr; Back to my private students</Link>
      </div>
      {learner ? (<>
        <h2 className="inverted my-students-name">
          <span>Student information</span>
          {learner.first_name} {learner.last_name}
        </h2>
        <div className="my-students-profile">
          <div className="profile-item">
            <h2>Contact information</h2>
            <span><strong>Name: </strong>{learner.first_name} {learner.last_name}</span>
            <span><strong>Email: </strong><a className="no-transform" href={'mailto:'+learner.contact_email}>{learner.contact_email}</a></span>
            <span><strong>Learner type: </strong>{learner.learner_type[0].toUpperCase() + learner.learner_type.slice(1)}</span>
            {learner.learner_type === 'child' ?
              <span><strong>Age: </strong>{learner.age}</span>
            : ''}
            <span><strong>Time zone: </strong>{moment.tz(learner.student.timezone).format('z [(GMT]Z[)]')}</span>
          </div>
          <div className="profile-item">
            <h2>Profile</h2>
            <span><strong>Last updated: </strong>{moment(learner.modified).format('MMMM D, YYYY')}</span>
            <span><strong>Level: </strong>{learner.level}</span>
            <span><strong>Goals: </strong>{learner.goals}</span>
            <span><strong>Additional Notes: </strong>{learner.preferences}</span>
          </div>
          <div className="profile-item full-width">
            <h2>Learning Path</h2>
            <span><strong>Activity Collection: </strong>{(
                <span className="profile-starting-point">
                  <select value={activityCollection.id} disabled={isUpdating} onChange={changeActivityCollection}>
                  {teacherActivityCollections.map((actycolln, index) => (
                    <option key={index} value={actycolln.id}>{actycolln.title}: {actycolln.description}</option>
                  ))}
                  </select>
                  {isUpdating && <div className="spinner"></div>}
                </span>
            )}</span>
            <span><strong>Starting Point: </strong>{(!learner.activity_collection
              ? 'N/A'
              : (
                <span className="profile-starting-point">
                  <select value={startingPoint} disabled={isUpdating} onChange={changeStartingPoint}>
                  {resources.map((resource, index) => (
                    <option key={index} value={resource.session_number}>Session {resource.session_number}: {resource.external_resource.title}</option>
                  ))}
                  </select>
                  {isUpdating && <div className="spinner"></div>}
                </span>
              )
            )}</span>
          </div>
        </div>
      </>) : ''}

      {privateLessons ?
        <div className="my-students-private-lessons">
          <h3 className="inverted">Private Lesson Packages</h3>
          {privateLessons.map((pl, index) => (
            <div key={index} className="lessons-block">
              <table className="lessons-table">
                <thead>
                  <tr>
                    <th>Package Type</th>
                    <th>Language</th>
                    <th>Total Hours</th>
                    <th>Hours Available</th>
                    <th>Hours Expiry</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{pl.package.is_subscription ? 'Monthly subscription' : 'Prepaid package'}</td>
                    <td>{pl.language ? pl.language.name : 'N/A'}</td>
                    <td>{parseFloat(pl.total_hours)}</td>
                    <td>{parseFloat(pl.hours_unbooked)}</td>
                    <td>{pl.ignore_expiration ? 'N/A' : moment(pl.expires_on).format('MMMM D, YYYY')}</td>
                  </tr>
                </tbody>
              </table>
              {pl.hours_unbooked > 0 ?
                <div className="my-students-footer">
                  <Link to={`${pl.id}/add-session`} className="add-lesson" href="#">+ Add Lesson</Link>
                </div>
              : ''}
            </div>
          ))}
        </div>
      : ''}

      {learner && learner.private_sessions ? (<>
        <div className="my-students-sessions">
          <h3 className="inverted">Lessons History</h3>
          <table className="sessions-table">
          <tbody>
            <tr>
              <th>Date</th>
              <th>Status</th>
              <th>Teacher</th>
              <th>Details</th>
            </tr>
          {learner.private_sessions.map((session, index) => (
            <tr key={index}>
              <td>{moment(session.start_at).tz(window.user.timezone).format('MMMM D, YYYY')}</td>
              <td className="session-status">{session.status}</td>
              <td>{session.teacher
                ? session.teacher.first_name + ' ' + session.teacher.last_name[0] + '.'
                : 'No Teacher'}
              </td>
              <td>
                <Link to={`sessions/${session.id}`} className="edit-session">
                  {!session.teacher || window.user.id != session.teacher.id ? 'View' : ''}
                  {session.teacher && window.user.id == session.teacher.id ? (
                    session.status == 'scheduled' ? 'View/edit' :
                    session.status == 'complete' && !session.notes ? <span className="add-summary">Add summary</span>
                    : 'View'
                  ): ''}
                </Link>
              </td>
            </tr>
          ))}
          </tbody>
          </table>
        </div>
      </>) : ''}
    </div>
  );
};

export default MyStudentsDetailView;
