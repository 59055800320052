import moment from 'moment-timezone';
import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';


const TeacherCreateTooltip = ({ event, isRepeating, onCancel, onSave, onRepeatChange }) => {
  const start = moment(event.start_at);
  const end = moment(event.end_at);

  const tooltipRef = createRef()

  useEffect(() => {
    // 900px is the mobile/desktop breakpoint set in _breakpoints.scss
    if (window.innerWidth >= 900) {
      const node = tooltipRef.current;
      let adjustment = 410;
      // 1200px is the desktop/large desktop breakpoint set in _breakpoints.scss
      if (window.innerWidth >= 1200) {
        adjustment = 510;
      }
      // the purpose of the < 0 code is to make sure the tooltip doesn't go off the left side of the screen
      // if the click event on the left side of the screen
      const shouldShiftRight = (
        event.tempData.box !== undefined
        ? (event.tempData.box.x - adjustment) < 0
        : (event.tempData.bounds.left - adjustment) < 0
      );
      let left = (
        event.tempData.box !== undefined
        ? (shouldShiftRight ? event.tempData.box.x + 20 : event.tempData.box.x - adjustment)
        : (shouldShiftRight ? event.tempData.bounds.left + 20 : event.tempData.bounds.left - adjustment)
      );
      const top = (
        event.tempData.box !== undefined
        ? event.tempData.box.y - 60
        : event.tempData.bounds.top + ((event.tempData.bounds.bottom - event.tempData.bounds.top) / 2) - 60
      );

      if (shouldShiftRight) {
        node.classList.add('shifted-right');
      } else {
        node.classList.remove('shifted-right');
      }
      node.style.left = `${left}px`;
      node.style.top = `${top}px`;
    }
  }, [event]);

  return (
    <div
      className='teacher-create-availability-tooltip calendar-tooltip'
      ref={tooltipRef}
    >
      <div className='close' onClick={() => onCancel()}></div>
      <div className='tooltip-row-outer'>
        <h2>{'Schedule Availability'}</h2>
        <div className='tooltip-row'>
          <img className='row-icon' src='/static/img/assets/account/calendar/clock-icon.jpg' />
          <span>{start.format('dddd, MMM Do  LT')} - {end.format('LT')}</span>
        </div>
        <div className='tooltip-row'>
          <div>
            <input className='custom-checkbox black-green' type='checkbox' id='should_availability_repeat' checked={!!isRepeating} readOnly={true} />
            <label htmlFor="should_availability_repeat" onClick={() => onRepeatChange()}></label>
          </div>
          <span>Repeat every {start.format('dddd')} for the next 4 weeks</span>
        </div>
      </div>
      <div className='tooltip-row flex-end'>
        <a onClick={() => onCancel()}>Cancel</a>
        <button onClick={() => onSave()}>Save</button>
      </div>
    </div>
  )
};

TeacherCreateTooltip.propTypes = {
  event: PropTypes.object.isRequired,
  isRepeating: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRepeatChange: PropTypes.func.isRequired,
};

export default TeacherCreateTooltip;
