import moment from 'moment-timezone';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { profileUrls, sessionStates, sessionTypes, abstractTimeRanges } from '../constants.js';

if (window.user_timezone !== '') {
  moment.tz.setDefault(window.user_timezone)
} else {
  moment.tz.setDefault('America/Detroit');
}

const SessionCard = ({ session, state, onCancel }) => {
  const [ actionDropdownOpen, setActionDropdownOpen ] = useState(false);

  const startAt = moment(session.start_at);
  const endAt = moment(session.end_at);

  if (session.course) {
    session.session_type = sessionTypes.CLASSROOMSESSION;
  } else {
    session.session_type = sessionTypes.PRIVATESESSION;
  }

  if (!session.teacher) {
    session.teacher = {};
  }

  if (session.teacher.first_name) {
    session.teacher.privacy_protected_name = `${session.teacher.first_name} ${session.teacher.last_name[0]}.`;
  } else {
    session.teacher.privacy_protected_name = 'TBD';
  }

  let photo = null;
  if (session.session_type == sessionTypes.CLASSROOMSESSION && session.teacher) {
    photo = session.teacher.photo;
  } else if (session.lesson && session.teacher) {
    photo = session.teacher.photo;
  }
  const teacherPhoto = photo ? photo : '/static/img/assets/account/default-profile-photo.png';
  const is_online_with_link = (state == abstractTimeRanges.UPCOMING && session.teacher.virtual_classroom);

  // fix me
  const userType = window.user_type;
  const isTeacher = userType == 'teacher';
  const isStudent = userType == 'student';

  const getClassroomSessionTitle = (session) => {
    let title = session.course.template.name;
    if (isStudent) {
      return <>{title} <span className="no-translate">(For {session.course.my_enrollments[0].learner.first_name})</span></>;
    }
    return <>{title}</>;
  }

  if (session.session_type == sessionTypes.PRIVATESESSION || session.session_type == sessionTypes.PRIVATEONLINESESSION) {

    const studentProfileUrl = `${profileUrls.myPrivateStudents}/${session.lesson.learner.id}`;
    const privateSessionHeader = isTeacher
      ? <Link to={studentProfileUrl}><span className="no-translate">{session.lesson.student.first_name} {session.lesson.student.last_name} (For {session.lesson.learner.first_name})</span></Link>
      : <>Private Session <span className="no-translate">(For {session.lesson.learner.first_name})</span></>;

    return (
      <div className='session-card' data-session-id={session.id}>
        <div className='session-content'>
          {isStudent &&
            <div className='session-user-card' style={{backgroundImage: 'url(' + teacherPhoto + ')'}}>
              <h6 className='session-user-name'>{session.teacher.privacy_protected_name}</h6>
            </div>
          }
          <div className='inner-content grow'>
            <div className='session-type-wrapper'>
              <img className='session-type-icon' src='/static/img/assets/account/my_lessons/private-session-icon.jpg' />
              <h6>Private Lesson</h6>
            </div>

            {isStudent && session.status == 'scheduled' &&
              <div className='action-dropdown-wrapper'>
                <img onClick={() => setActionDropdownOpen(!actionDropdownOpen)} className='action-dropdown-arrow' src='/static/img/dropdown-arrow-box.png' />
                <div className={`action-dropdown ${actionDropdownOpen ? 'is-open' : ''}`}>
                  <div onClick={() => onCancel(session)} className='action-link force-uppercase'><b>Cancel</b></div>
                  <Link
                    className="action-link force-uppercase"
                    to={`${profileUrls.myLessons}/${session.id}`}
                  ><div>View/Edit Details</div></Link>
                </div>
              </div>
            }

            <h2
              className={(is_online_with_link || state == abstractTimeRanges.COMPLETED) ? 'reduce-bottom-margin' : undefined}
            >
              {privateSessionHeader}
            </h2>
            <span className={`session-datetime ${state}`}>{startAt.format('dddd[,] MMMM Do')} <br/> {startAt.format('h:mm a')}-{endAt.format('h:mm a')} {startAt.format('z')}</span>
            {( is_online_with_link &&
              <div className='session-link-wrapper online-session-link-wrapper'>
                <a
                  href={session.teacher.virtual_classroom}
                  target='_blank'
                  rel="noopener noreferrer"
                  >
                    <h6>Start Lesson</h6>
                  </a>
                  <img src='/static/img/right-arrow.jpg'/>
              </div>
            )}
            {(state == abstractTimeRanges.COMPLETED && session.status != sessionStates.CANCELLED &&
              <div className='completed-wrapper'>
                <img className='completed-icon' src='/static/img/assets/account/my_lessons/completed-session-icon.jpg'/>
                <h6 className='completed-text'>Completed</h6>
              </div>
            )}
            {(session.status == sessionStates.CANCELLED &&
              <div className='completed-wrapper'>
                <img className='completed-icon' src='/static/img/assets/account/my_lessons/cancelled-session-icon.png'/>
                <h6 className='completed-text'>Cancelled</h6>
              </div>
            )}
            <h6 className='session-length'>{session.duration_string}</h6>
            {isTeacher &&
              <div className="session-cta">
                <a
                  className={session.status === 'complete' && !session.notes ? 'session-cta-btn' : 'session-cta-btn inverted'}
                  href={`${profileUrls.myPrivateStudents}/${session.lesson.learner.id}/sessions/${session.id}`}
                >{session.status == 'scheduled' ? 'View/Edit Lesson Details' : (
                  session.status == 'complete' && !session.notes ? 'Add Lesson Summary' : 'View Lesson Details'
                )}</a>
              </div>
            }
            {isStudent && session.status == 'complete' &&
              <div className="session-cta">
                <Link
                  className="session-cta-btn inverted"
                  to={`${profileUrls.myLessons}/${session.id}`}
                >View Lesson Details</Link>
              </div>
            }
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='session-card' data-session-id={session.id}>
        <div className='session-content'>
          {isStudent &&
            <div className='session-user-card' style={{backgroundImage: 'url(' + teacherPhoto + ')'}}>
              <h6 className='session-user-name'>{session.teacher.privacy_protected_name}</h6>
            </div>
          }
          <div className='inner-content grow'>
            <div className='session-type-wrapper'>
              <img src='/static/img/assets/account/my_lessons/group-session-icon.jpg' />
              <h6>Group</h6>
            </div>
            <h2 className='no-bottom-margin'>{getClassroomSessionTitle(session)}</h2>
            <h6 className={`session-location ${state == abstractTimeRanges.COMPLETED && 'reduce-bottom-margin'}`}>At {session.venue.name} - {session.venue.street_address}</h6>
            <span className={`session-datetime ${state}`}>{startAt.format('dddd[,] MMMM Do')} <br/> {startAt.format('h:mm a')}-{endAt.format('h:mm a')} {startAt.format('z')}</span>
            {( is_online_with_link &&
              <div className='session-link-wrapper online-session-link-wrapper'>
                <a
                  href={session.teacher.virtual_classroom}
                  target='_blank'
                  rel="noopener noreferrer"
                  >
                    <h6>Start Lesson</h6>
                  </a>
                  <img src='/static/img/right-arrow.jpg'/>
              </div>
            )}
            {(state == abstractTimeRanges.COMPLETED && session.status != sessionStates.CANCELLED &&
              <div className='completed-wrapper'>
                <img className='completed-icon' src='/static/img/assets/account/my_lessons/completed-session-icon.jpg'/>
                <h6 className='completed-text'>Completed</h6>
              </div>
            )}
            {(session.status == sessionStates.CANCELLED &&
              <div className='completed-wrapper'>
                <img className='completed-icon' src='/static/img/assets/account/my_lessons/cancelled-session-icon.png'/>
                <h6 className='completed-text'>Cancelled</h6>
              </div>
            )}
            <h6 className='session-length'>{session.duration_string}</h6>
            {isTeacher &&
              <div className="session-cta">
                <a
                  className={session.status === 'complete' && !session.notes ? 'session-cta-btn' : 'session-cta-btn inverted'}
                  href={`${profileUrls.myCourses}/${session.course.id}/sessions/${session.id}`}
                >{session.status == 'scheduled' ? 'View Lesson Details' : (
                  session.status == 'complete' && !session.notes ? 'Add Lesson Summary' : 'View Lesson Details'
                )}</a>
              </div>
            }
            {!isTeacher &&
              <div className="session-cta">
                <a
                  className="session-cta-btn inverted"
                  href={`${profileUrls.myLessons}/group/${session.id}`}
                >View Lesson Details</a>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
};

SessionCard.propTypes = {
  session: PropTypes.object.isRequired,
  state: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SessionCard;
