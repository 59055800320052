import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MyLessonsListView from './MyLessonsListView.jsx';
import MyLessonsDetailView from './MyLessonsDetailView.jsx';
import MyLessonsGroupDetailView from './MyLessonsGroupDetailView.jsx';

const MyLessonsView = () => {
  return (
    <div className="MyLessonsView">
      <Routes>
        <Route path="" element={<MyLessonsListView />} />
        <Route path=":sessionId" element={<MyLessonsDetailView />} />
        <Route path="group/:sessionId" element={<MyLessonsGroupDetailView />} />
      </Routes>
   </div>
  );
};

export default MyLessonsView;
