import moment from 'moment-timezone';
import React, { useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { shiftTimezone } from './utils.jsx';
import { profileUrls } from '../constants.js';

const USER_TIMEZONE = window.user_timezone !== '' ? window.user_timezone : 'America/Detroit';
moment.tz.setDefault();

const TeacherCalendarTooltip = ({ session, onCloseTooltip, onCancel }) => {
  let left = session.x;
  let top = session.y - 20;

  const startAt = moment(shiftTimezone(session.start_at, USER_TIMEZONE, moment.tz.guess()));
  const endAt = moment(shiftTimezone(session.end_at, USER_TIMEZONE, moment.tz.guess()));

  const tooltipRef = createRef()

  useEffect(() => {
    // 900px is the mobile/desktop breakpoint set in _breakpoints.scss
    if (window.innerWidth >= 900) {
      const node = tooltipRef.current;
      node.style.top = `${top - node.offsetHeight}px`;
      if (left > document.body.clientWidth - 300) {
        node.style.left = `${left - 450}px`;
        node.classList.add('shifted-left');
      } else {
        node.style.left = `${left - 250}px`;
        node.classList.remove('shifted-left');
      }
    }
  }, [session]);

  const is_online_with_link = (
    (session.course
      ? session.teacher.virtual_classroom
      : session.teacher.virtual_classroom)
    && startAt > moment().startOf('day')
  )

  const flag_image = session.course
    ? session.course.template.category.language.flag_image
    : (session.language ? session.language.flag_image : null);


  return (
    <div
      className='teacher-calendar-tooltip calendar-tooltip'
      ref={tooltipRef}
    >
      <div className='close' onClick={() => onCloseTooltip()}></div>
      <div className='flag-image-wrapper'>
        {flag_image &&
          <img className='flag-image' src={flag_image} />
        }
      </div>
      {session.lesson ?
      (
        <div className='inner-content'>
          <div className='session-type-wrapper'>
            <img className='session-type-icon' src={session.is_online ? '/static/img/assets/account/my_lessons/online-session-icon.jpg' : '/static/img/assets/account/my_lessons/private-session-icon.jpg'} />
            <h6 className='session-type'>{session.is_online ? 'Online' : 'Private'}{session.language && ` // ${session.language.name}`}</h6>
          </div>
          <h2
            className={(is_online_with_link) ? 'reduce-bottom-margin' : ''}
          >
            <Link to={`${profileUrls.myPrivateStudents}/${session.lesson.learner.id}`}>{session.lesson.learner.first_name} {session.lesson.learner.last_name}</Link>
          </h2>
          <span className='session-datetime'>{startAt.format('dddd[,] MMMM Do, YYYY')} <br/> {startAt.format('h:mm a')}-{endAt.format('h:mm a')} {moment.tz(USER_TIMEZONE).format('z')}</span>

          <div className='action-container'>
            {startAt > moment().startOf('day') &&
              <a className="action-btn inverted" onClick={() => onCancel()}>Cancel Lesson</a>
            }
            <Link
              to={`${profileUrls.myPrivateStudents}/${session.lesson.learner.id}/sessions/${session.id}`}
              className={session.status === 'complete' && !session.notes ? 'action-btn' : 'action-btn inverted'}
            >{session.status == 'scheduled' ? 'View/Edit Lesson Details' : (
              session.status == 'complete' && !session.notes ? 'Add Lesson Summary' : 'View Lesson Details'
            )}</Link>
          </div>
        </div>
      ):
      (
        <div className='inner-content'>
          <div className='session-type-wrapper'>
            <img src='/static/img/assets/account/my_lessons/group-session-icon.jpg' />
            <h6 className='session-type'>Group</h6>
          </div>
          <h2 className='no-bottom-margin'>{session.course.template.name}</h2>
          <h6 className='session-location'>At {session.venue.name} - {session.venue.street_address}</h6>
          <span className='session-datetime'>{startAt.format('dddd[,] MMMM Do')} <br/> {startAt.format('h:mm a')}-{endAt.format('h:mm a')} {startAt.format('z')}</span>
          <div className='action-container'>
            <div className='session-link-wrapper'>
            </div>
            {is_online_with_link &&
              <div className='session-link-wrapper online-session-link-wrapper'>
                <a
                  href={session.teacher.virtual_classroom}
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  <h6>Start Lesson</h6>
                </a>
                <img src='/static/img/right-arrow.jpg'/>
              </div>
            }
            <Link
              to={`${profileUrls.myCourses}/${session.course.id}/sessions/${session.id}`}
              className={session.status === 'complete' && !session.notes ? 'action-btn' : 'action-btn inverted'}
            >{session.status == 'scheduled' ? 'View Lesson Details' : (
              session.status == 'complete' && !session.notes ? 'Add Lesson Summary' : 'View Lesson Details'
            )}</Link>
          </div>
        </div>
      )}
    </div>
  )
};

TeacherCalendarTooltip.propTypes = {
  session: PropTypes.object.isRequired,
  onCloseTooltip: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TeacherCalendarTooltip;
