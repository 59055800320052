import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

import HeroBanner from './HeroBanner.jsx';
import NavItem from './NavItem.jsx';
import MobileMenu from './MobileMenu.jsx';
import TeacherCalendarView from './TeacherCalendarView.jsx';
import MyLessonsView from './MyLessonsView.jsx';
import MyResourcesView from './generic/my_resources/MyResourcesView.jsx';
import PayrollView from './PayrollView.jsx';
import ProfileView from './ProfileView.jsx';
import MyCoursesView from './teachers/my_courses/MyCoursesView.jsx';
import MyStudentsView from './teachers/my_students/MyStudentsView.jsx';

import { profileUrls } from '../constants.js';

const routes = [
  {
    path: profileUrls.myLessons,
    header: 'My Lessons',
    component: MyLessonsView,
    subRoutes: true
  },
  {
    path: profileUrls.myCalendar,
    header: 'My Calendar',
    component: TeacherCalendarView
  },
  {
    path: profileUrls.myResources,
    header: 'My Resources',
    component: MyResourcesView,
    subRoutes: true
  },
  {
    path: profileUrls.accountOverview,
    header: 'Profile',
    component: ProfileView,
    exact: true
  },
  {
    path: profileUrls.payroll,
    header: 'Payments',
    component: PayrollView
  },
  {
    path: profileUrls.myCourses,
    header: 'My Courses',
    component: MyCoursesView,
    subRoutes: true
  },
  {
    path: profileUrls.myPrivateStudents,
    header: 'My Private Students',
    component: MyStudentsView,
    subRoutes: true
  },
];

const ProfileApp = (props) => {
  return (
    <div className="ProfileApp">
      <Router>
        <HeroBanner backgroundUrl={props.backgroundurl} routes={routes} />
        <ToastContainer draggable={false} closeButton={false} />
        <div className="account-inner-container padded-container teacher-view">
          <div id="account-details-nav">
            <div className="links">
            {routes.map((route, index) => (
              <NavItem
                key={index}
                url={route.path}
                exact={route.exact}
                label={route.header}
              />
            ))}
              <div className="nav-item">
                <a href="/account/logout">Log Out</a>
              </div>
            </div>
          </div>

          <div className="account-content-container">
            <div className="account-mobile-menu">
              <MobileMenu routes={routes} />
            </div>
            <div id="me">
              <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.subRoutes ? `${route.path}/*` : route.path}
                  exact={route.exact}
                  element={<route.component />}
                />
              ))}
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}

ProfileApp.propTypes = {
  backgroundurl: PropTypes.string.isRequired
};

export default ProfileApp;
