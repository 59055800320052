import PropTypes from 'prop-types'
import React from 'react'
import Question from './question.jsx'
import {shuffleArray} from '../util.js'


export default class QuizPage extends React.Component {

    static propTypes = {
        description: PropTypes.string,
        enableNextBtn: PropTypes.func.isRequired,
        image_url: PropTypes.string,
        page_id: PropTypes.number.isRequired,
        quiz_id: PropTypes.number.isRequired,
        questions: PropTypes.array.isRequired,
        requiredQuestions: PropTypes.object.isRequired,
        showingFeedback: PropTypes.bool,
        title: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            requiredQuestions: props.requiredQuestions,
        }
    }

    setIsAnswered = (id, answer) => {
        // Callback passed to each Question to capture its answered status
        // Passes back bool indicating whether all required questions have
        // have been answered to the enableNextBtn prop func
        const props = this.props
        let requiredQuestions = this.state.requiredQuestions
        let prev_answers = requiredQuestions[id] || []
        requiredQuestions[id] = [...prev_answers, ...answer]
        this.setState({requiredQuestions: requiredQuestions}, () => {
            // If answer array is empty for a required question,
            // disable the next btn on in QuizSeries parent component
            let page_complete = true
            for (var qid in requiredQuestions) {
                if (requiredQuestions[qid].length == 0) {
                    page_complete = false
                }
            }
            props.enableNextBtn(page_complete)
        })
    }

    render() {
        const props = this.props
        var image = ''
        if (props.image_url) {
            image = <img className='page-img' src={props.image_url} alt="quiz page image"/>
        }
        return (
            <form id='quiz-form' name='quiz-form'>
                {image}
                {props.questions.map(question => {
                    return <Question
                        answers={ shuffleArray([].concat(question.answers)) }
                        form_element={ question.question_type.form_element }
                        form_type={ question.question_type.form_type }
                        id={ question.id }
                        instructions={ question.instructions }
                        key={ question.id }
                        label={ question.label }
                        placeholder={ question.placeholder }
                        question={ question.question }
                        setIsAnswered={this.setIsAnswered}
                        showingFeedback={ props.showingFeedback }
                    />
                })}
            </form>
        )
    }
}
