import React from 'react';
import PropTypes from 'prop-types';


const CalendarHeader = ({ label }) => {
  if (label.includes('holiday ')) {
    label = label.replace('holiday ', '');
    return <span className='is-holiday'><span className='holiday-icon' />{label}</span>
  }
  return <span>{label}</span>
}

CalendarHeader.propTypes = {
  label: PropTypes.node,
}

export default CalendarHeader;