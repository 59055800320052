import React, { useState, useEffect } from 'react';

import ResourceCollectionCard from './ResourceCollectionCard.jsx';
import FCApi from '../../../fc-api.js';

const uploadType = window.user_type == 'teacher' ? 'student' : 'teacher';

const MyResourcesListView = () => {
  const [ filterLanguage, setFilterLanguage ] = useState('');
  const [ availableLanguages, setAvailableLanguages ] = useState({});
  const [ courses, setCourses ] = useState([]);
  const [ uploads, setUploads ] = useState(null);
  const [ expandedCollection, setExpandedCollection ] = useState(null);
  const [ collections, setCollections ] = useState([]);
  const [ searchFilter, setSearchFilter ] = useState('');

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    updateAvailableLanguages();
  }, [courses, uploads, collections]);

  const refresh = () => {
    // We show activity collections here as well as some constructed activity collections
    // such as teacher/student uploads and course resources.
    //
    // In order to differentiate for rendering we assume each of these has
    // `collectionType` and `collectionId` attributes as well as a `resources` attribute
    // containing a list of resource objects.
    //
    // `ActivityCollectionCard` will switch on `collectionType` to determine how each
    // type should be rendered.

    // Course resources
    apiClient.v2ListCourses(
      (error, response) => {
        if (!error) {
          // Filter out any courses without an activity collection.
          response = response.filter(r => r.activity_collection);
          response = response.map(e => ({
            ...e,
            collectionType: 'course',
            collectionId: `course${e.id}`,
            language: e.template.category.language,
            isTeacherFacing: e.activity_collection.is_teacher_facing
          }));
          setCourses(response);
        }
      },
      'expand=template.category.language,activity_collection.language,activity_collection.weighted_external_resources.external_resource.file,activity_collection.weighted_external_resources.external_resource.media_type'
    );

    // Teacher/student uploads
    let key = `${uploadType}_attachments`;
    apiClient.v2ListPrivateSessions(
      (error, response) => {
        if (!error) {
          // Collect any uploads from private sessions.
          let tmpUploads = [];
          for (const ps of response) {
            tmpUploads = tmpUploads.concat(ps[key]);
          }

          if (tmpUploads.length > 0) {
            setUploads({
              collectionId: 'uploads',
              collectionType: 'uploads',
              name: `${uploadType} uploads`,
              language: null,
              resources: tmpUploads
            });
          } else {
            setUploads(null);
          }
        }
      },
      `expand=${key}&has_${key}=true`
    );

    // Default visible activity collections
    apiClient.v2ListActivityCollections(
     (error, response) => {
        if (!error) {
          response = response.map(e => ({
            ...e,
            collectionType: 'activityCollection',
            collectionId: `activityCollection${e.id}`,
            isTeacherFacing: e.is_teacher_facing
          }));
          setCollections(response);
        }
      },
      'expand=language,weighted_external_resources.external_resource.file,weighted_external_resources.external_resource.media_type'
    );
  };

  const showAll = (e) => {
    e.preventDefault();
    setSearchFilter('');
    setFilterLanguage('');
  };

  const updateSearchFilter = (event) => {
    setSearchFilter(event.target.value);
  };

  const updateAvailableLanguages = () => {
    // Gather all unique language slug/name combinations from courses, uploads, and
    // collections and store in availableLanguages, used for populating the language
    // filter options.
    let languages = {};

    for (const course of courses) {
      if (!course.language) {
        continue;
      }

      if (!(course.language.slug in languages)) {
        languages[course.language.slug] = course.template.category.language.name;
      }
    }

    for (const collection of collections) {
      if (!collection.language) {
        continue;
      }

      if (!(collection.language.slug in languages)) {
        languages[collection.language.slug] = collection.language.name;
      }
    }

    setAvailableLanguages(languages);
  };

  const onFilterLanguageChange = (event) => {
    // Handle a change in the language filter.
    const value = event.target.value;
    setFilterLanguage(value);
    setExpandedCollection(null);
  };

  const expandCollection = (collectionId) => {
    // Expand (or close) a resource collection card.
    if (collectionId == expandedCollection) {
      setExpandedCollection(null);
    } else {
      setExpandedCollection(collectionId);
    }
  }

  const renderResourceCollections = () => {
    let filteredCollections = [...collections, ...courses]
    if (uploads) {
      filteredCollections = [uploads, ...filteredCollections];
    }

    if (filterLanguage) {
      filteredCollections = filteredCollections.filter(c => !c.language || c.language.slug == filterLanguage);
    }

    return (
      <div className="resource-cards no-translate">
      {filteredCollections.map((collection, index) => (
        <ResourceCollectionCard
          key={index}
          searchFilter={searchFilter}
          collection={collection}
          expandCard={() => {expandCollection(collection.collectionId)}}
          expanded={expandedCollection == collection.collectionId}
        />
      ))}
      </div>
    );
  }

  const renderLanguageFilter = () => {
    // Render the languages filter at the top of the listing

    let slugs = Object.keys(availableLanguages);

    // Keep languages in alphabetical order
    slugs.sort();

    return (
      <label>
        Language:
        <select value={filterLanguage} onChange={onFilterLanguageChange}>
          <option value="">All Languages</option>
          {slugs.map((slug, index) => (
          <option value={slug} key={index}>{availableLanguages[slug]}</option>
          ))}
        </select>
      </label>
    );
  }

  return (
    <div className="MyResourcesListView">
      <h2 className="inverted">My Resources</h2>
      <div className="filter-section">
        <div className="filter-wrapper">
          {renderLanguageFilter()}
          <label>
            Search resources:
            <input value={searchFilter} type="text" id="search-filter" onChange={updateSearchFilter} />
          </label>
          <a className="no-transform" href="#" onClick={showAll}>Show all</a>
        </div>
      </div>
      {renderResourceCollections()}
    </div>
  );
};

export default MyResourcesListView;
