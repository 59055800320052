import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { ToastNotificationsError, ToastNotificationsSuccess } from '../notifications/ToastNotifications.jsx';

import autoScrollTo from '../autoscroll-to.js'
import { updateLineItem } from './api.js';
import FCApi from '../fc-api.js';
import { cartCheckoutUrl, cartCheckoutPaymentUrl } from '../constants.js';
import AdultParticipantForm from './participant_forms/AdultParticipantForm.jsx';
import ChildParticipantForm from './participant_forms/ChildParticipantForm.jsx';

export default function CartLearners(props) {
  const [ learners, setLearners ] = useState([]);
  const [ showLearnerForm, setShowLearnerForm ] = useState(false);
  const [ learnerFormType, setLearnerFormType ] = useState('adult');
  const [ learnerFormIsValidating, setLearnerFormIsValidating ] = useState(false);
  const [ learnerFormIsSubmitting, setLearnerFormIsSubmitting ] = useState(false);
  const [ learnerFormIsValid, setLearnerFormIsValid ] = useState(false);
  const [ learnerFormValues, setLearnerFormValues ] = useState({});

  const [ lineItems, setLineItems ] = useState([]);
  const [ lineItemsIsValidating, setLineItemsIsValidating ] = useState(false);

  const apiClient = new FCApi();

  useEffect(() => {
    setLearners(props.learners);
    setLineItems(props.lineitems.filter(li => ['course', 'privatelessonpackage'].includes(li.content_type)));
  }, []);

  const toggleLearnerForm = () => {
    setShowLearnerForm(!showLearnerForm);
    resetLearnerForm();
  }

  const toggleLearnerFormType = (e) => {
    setLearnerFormType(e.target.value);
    resetLearnerForm();
  }

  const resetLearnerForm = () => {
    setLearnerFormIsValidating(false);
    setLearnerFormIsValid(false);
    setLearnerFormValues({});
  }

  const saveLearner = () => {
    setLearnerFormIsValidating(true);
    if (!learnerFormIsValid) {
      return;
    }

    setLearnerFormIsSubmitting(true);
    apiClient.v2CreateLearner(
      {
        ...learnerFormValues,
        learner_type: learnerFormType
      },
      (error, response) => {
        setLearnerFormIsSubmitting(false);
        if (!error) {
          const msg = <span>Learner saved.</span>;
          toast.success(
            <ToastNotificationsSuccess closeToast={() => {}} htmlContent={msg} />,
            { autoClose: 3200 }
          );
          setLearners([...learners, response]);
          resetLearnerForm();
          setShowLearnerForm(false);
        } else {
          const msg = <span>Unable to save learner.</span>;
          toast.error(
            <ToastNotificationsError closeToast={() => {}} htmlContent={msg} />,
            { autoClose: 6400 }
          );
        }
      }
    );
  };

  const assignLearner = (e) => {
    const tokens = e.target.value.split('_');
    const liId = tokens[1];
    const learnerIdx = tokens[2]
    const learnerId = tokens[3];

    let lineItem = lineItems.filter(li => li.id === parseInt(liId))[0];
    let learners = lineItem.learners || [];
    if (!learners || learners.length != lineItem.quantity) {
      learners = Array(lineItem.quantity).fill(null);
    }
    learners[learnerIdx] = parseInt(learnerId);

    updateLineItem({
      object_id: lineItem.object_id,
      content_type: lineItem.content_type,
      learners: learners,
      quantity: lineItem.quantity
    }, () => {
      lineItem.learners = learners;
      setLineItems([...lineItems]);
    });
  };

  const selectLearnerLabel = (li) => {
    if (li.product.enrollment_types.toString() == ['adult'].toString()) {
      return 'Select Adult Learner';
    } else if (li.product.enrollment_types.toString() == ['child'].toString()) {
      return 'Select Child Learner';
    } else {
      return 'Select Learner';
    }
  };

  const selectLearnerInputId = (learner, li, idx) => {
    return 'learner_' + li.id + '_' + idx + '_' + learner.id;
  };

  const selectLearnerInputName = (learner, li, idx) => {
    return 'lineitem_' + li.id + '_' + idx;
  };

  const isChecked = (learner, li, idx) => {
    if (!li.learners || li.learners.length != li.quantity) {
      return false;
    }

    return li.learners[idx] === learner.id
  };

  const showError = (li, idx) => {
    if (!lineItemsIsValidating) {
      return false;
    }

    if (!li.learners || li.learners.length != li.quantity) {
      return true;
    }

    return li.learners[idx] === null;
  };

  const learnerFormOnChange = (isValid, newValues) => {
    setLearnerFormIsValid(isValid);
    setLearnerFormValues(newValues);
  };

  const lineItemIsValid = (liId) => {
    let lineItem = lineItems.filter(li => li.id === parseInt(liId))[0];

    if (!lineItem.learners || lineItem.learners.length != lineItem.quantity) {
      return false;
    }

    return !lineItem.learners.includes(null);
  };

  const onGoBack = () => {
    window.location.href = cartCheckoutUrl;
  }

  const onContinue = () => {
    // Validate all line items have an assigned learner
    let isValid = true;
    lineItems.map((li) => {
      if (!lineItemIsValid(li.id)) {
        isValid = false;
      }
    });

    if (!isValid) {
      setLineItemsIsValidating(true);
      autoScrollTo('assign-learners');
      return;
    }

    // Continue on to next view
    window.location.href = cartCheckoutPaymentUrl;
  };

  return (
    <div className="CartLearners">
      <div className="manage-learners">
        <h2 className="title">Confirm Learners</h2>
        <p className="subtitle">Purchasing for yourself or someone else? Let us know who will be learning with us!</p>
        <div className="learners-container">
          <div className="existing-learners">
            <div className="existing-learners-container">
              <div className="existing-learners-left">
                <p>Available Learners:</p>
              </div>
              <div className="existing-learners-right">
              {learners.map((learner, index) => (
                <div key={index} className="learner">
                  {learner.first_name} {learner.last_name[0]}.
                </div>
              ))}
              </div>
            </div>
          </div>
          <div className="add-learner">
            {!showLearnerForm ?
              <button href="#" className="add-learner-btn btn inverted" onClick={toggleLearnerForm}>Add Learner</button>
            :
            <div className="add-learner-form CartCheckout__participant_form">
              <div className="learner-form-type">
                <span className="learner-type-label">Learner Type</span>
                <input type="radio" id="learner_type_adult" name="learner_type" value="adult" checked={learnerFormType=='adult'} onChange={toggleLearnerFormType} /><label htmlFor="learner_type_adult">Adult</label>
                <input type="radio" id="learner_type_child" name="learner_type" value="child" checked={learnerFormType=='child'} onChange={toggleLearnerFormType} /><label htmlFor="learner_type_child">Child</label>
              </div>
              {learnerFormType == 'adult' &&
                <AdultParticipantForm
                  isValidating={learnerFormIsValidating}
                  onChange={learnerFormOnChange}
                  participant={learnerFormValues}
                  isSubmitting={learnerFormIsSubmitting}
                />
              }
              {learnerFormType == 'child' &&
                <ChildParticipantForm
                  isValidating={learnerFormIsValidating}
                  onChange={learnerFormOnChange}
                  participant={learnerFormValues}
                  isSubmitting={learnerFormIsSubmitting}
                />
              }
              <div className="add-learner-toolbar">
                {learnerFormIsSubmitting && <div className="spinner"></div>}
                <button className="white-button" disabled={learnerFormIsSubmitting} onClick={toggleLearnerForm}>Cancel</button>
                <button className="btn inverted" disabled={learnerFormIsSubmitting} onClick={saveLearner}>Save</button>
              </div>
            </div>
            }
          </div>
        </div>

        {showLearnerForm &&
          <div className="terms-container">
            {learnerFormType == 'adult' &&
              <div className="terms" dangerouslySetInnerHTML={{__html: window.adultLearnerPolicy}} />
            }

            {learnerFormType == 'child' &&
              <div className="terms" dangerouslySetInnerHTML={{__html: window.childLearnerPolicy}} />
            }
          </div>
        }
      </div>
      <div className="assign-learners">
        <div className="lineitems">
          {lineItems.map((li, liIndex) => (
            <React.Fragment key={liIndex}>
              {[...Array(li.quantity).fill(0)].map((_, i) => (
                <div key={liIndex+'_'+i} className="lineitem">
                  <div className="lineitem-left">
                    <div className="lineitem-left-inner">
                      <h2>{li.cart_display.title}</h2>
                      {li.cart_display.details.map((detail, cdIndex) => (
                        <p key={cdIndex}>{detail}</p>
                      ))}
                    </div>
                  </div>
                  <div className="lineitem-right">
                    <div className="learner-group" key={i}>
                      <h4>{selectLearnerLabel(li)}</h4>
                      <div>
                        {learners.filter(l => li.product.enrollment_types.includes(l.learner_type)).map((learner, lIndex) => (
                          <div key={lIndex} className="participant-item">
                            <input
                              name={selectLearnerInputName(learner, li, i)}
                              id={selectLearnerInputId(learner, li, i)}
                              value={selectLearnerInputId(learner, li, i)}
                              checked={isChecked(learner, li, i)}
                              type="radio"
                              onChange={assignLearner}
                            />
                            <label htmlFor={selectLearnerInputId(learner, li, i)}>{learner.first_name} {learner.last_name[0]}.</label>
                          </div>
                        ))}
                        {showError(li, i) && <div className="form-errors">Please select a learner.</div>}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="toolbar">
        <button className="white-button" onClick={onGoBack}>Go Back</button>
        <button className="btn inverted" onClick={onContinue}>Continue</button>
      </div>
    </div>
  );
}

CartLearners.propTypes = {
  learners: PropTypes.array.isRequired,
  lineitems: PropTypes.array.isRequired
};
