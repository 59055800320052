import $ from 'jquery'

/**
 * This function implements the functionality for the toggle in the Choose Your Package
 * section of https://www.cricketelearning.com/online-language-tutors. Users can click
 * the toggle to toggle between the prices of monthly and prepaid plans.
 */
export default function togglePlanType() {
    const $toggle = $('.subscription-toggle');
    const $prepaid = $('#one-time-plans-list');
    const $monthly = $('#subscriptions-list');

    $('.subscription-prepaid').on('click', () => {
        $toggle.removeClass('subscription-toggle-monthly');
        $toggle.addClass('subscription-toggle-prepaid');
        $prepaid.show();
        $monthly.hide();
    })

    $('.subscription-monthly').on('click', () => {
        $toggle.addClass('subscription-toggle-monthly');
        $toggle.removeClass('subscription-toggle-prepaid');
        $monthly.show();
        $prepaid.hide();
    })
}