/* global Stripe */
import React from 'react';
import isEmail from 'validator/es/lib/isEmail';
import validatorIsNumeric from 'validator/es/lib/isNumeric';
import validatorIsMobilePhone from 'validator/es/lib/isMobilePhone';

const error_wrap = txt => {
    // wrap the text in an error
    return (<span className='invalid-field-message'>{txt}</span>)
}

const required = value => {
    if (value == null) { return error_wrap('required') }
    if (!value.toString().trim().length) {
        return error_wrap('required')
    }
}

const email = value => {
    if (!isEmail(value)) {
        return error_wrap('email is invalid');
    }
}

const isNumeric = value => {
    if (!validatorIsNumeric(value)) {
        return error_wrap('not a valid number.')
    }
}

const isZipCode = value => {
    if (!isNumeric(value) || !(value.length >= 5)) {
        return error_wrap('zip code is not valid.')
    }
}

const isMobilePhone = value => {
    if (!validatorIsMobilePhone(value, 'en-US')) {
        return error_wrap('phone number is not valid.')
    }
}

// Stripe Validators

const validateCardNumber = value => {
    if (!Stripe.card.validateCardNumber(value)) {
        return error_wrap('credit card number is invalid. Try again.')
    }
}

const validateCVC = value => {
    if (!Stripe.card.validateCVC(value)) {
        return error_wrap('Check your card\'s security code and try again.')
    }
}

const validateExpiry = value => {
    if (!Stripe.card.validateExpiry(value)) {
        return error_wrap('Check your card\'s expiration date and try again.')
    }
}

export default {
    required: required,
    email: email,
    isNumeric: isNumeric,
    isZipCode: isZipCode,
    isMobilePhone: isMobilePhone,
    validateCardNumber: validateCardNumber,
    validateCVC: validateCVC,
    validateExpiry: validateExpiry,
}
