import $ from 'jquery';
import urls from '../constants.js';
import displayErrors from './error_display.js';
import csrf from '../csrf.js';

csrf.setupAjax();

export function sendPasswordReset(data, url) {
    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
    }).done(() => {
        // hide the form and show the success message
        $('.js-pw-reset-form-wrapper').addClass('hide');
        $('.js-pw-reset-success').removeClass('hide');
    }).fail((errors) => {
        displayErrors(errors.responseText, $('.js-pw-reset-errors'));
    });
}

export function loginUser(data) {
    return $.ajax({
        url: urls.loginUrl,
        type: 'POST',
        data: data,
        dataType: 'json',
    }).done((response) => {
        // redirect from response 'redirect' url
        $(location).attr('href', response.redirect_url);
    }).fail((errors) => {
        displayErrors(errors.responseText, $('.js-login-form-errors'));
    });
}

export function registerStudent(data) {
    return $.ajax({
        url: urls.registerUrl,
        type: 'POST',
        data: data,
        dataType: 'json',
    }).done((response) => {
        // redirect from response 'redirect' url
        $(location).attr('href', response.redirect_url);
    }).fail((errors) => {
        displayErrors(errors.responseText, $('.js-register-form-errors'));
    });
}
