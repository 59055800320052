import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import FCApi from '../fc-api.js';
import AttachmentManager from '../general/AttachmentManager.jsx';
import ResourceCard from './generic/my_resources/ResourceCard.jsx';

const MyLessonsGroupDetailView = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const [ session, setSession ] = useState(null);

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    apiClient.v2FetchClassroomSession(
      sessionId,
      (error, response) => {
        if (!error) {
          setSession(response);
        }
      },
      'expand=teacher,teacher_attachments,course.template,resources.file,resources.media_type'
    );
  };

  if (!session) {
    return null;
  }

  return (
    <div className="MyLessonsDetailView">
      <div className="my-lessons-header">
        <Link
          to=".."
          className="back-link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >&larr; Back to my lessons</Link>
      </div>
      <div className="my-lessons-detail-container">
        <h2 className="inverted">
          Lesson Details
        </h2>
        <div className="my-lessons-detail-container-inner">
          <h2>{session.course.template.name} with {
            session.teacher ? session.teacher.first_name + ' ' + session.teacher.last_name
            : 'TBD'
          }</h2>
          <p>
            {moment(session.start_at).tz(window.user.timezone).format('dddd, MMMM Do YYYY, h:mma')}
            &nbsp;-&nbsp;
            {moment(session.end_at).tz(window.user.timezone).format('h:mma')}
          </p>

          {session.resources.length > 0 && <>
            <h2>Resources</h2>
            <div className="my-lessons-resources">
              {session.resources.map((res, index) => (
                <ResourceCard
                  key={index}
                  title={res.title}
                  desc={res.description}
                  url={res.url || res.file.url}
                  isVisible={!res.is_teacher_facing}
                />
              ))}
            </div>
          </>}

          <h2>Lesson Summary</h2>
          {session.notes ?
            <p>{session.notes}</p>
          :
            <p className="no-notes">
              Your teacher has not submitted a recap for this lesson. You will
              be notified by email as soon as the recap is available.
            </p>
          }

          <AttachmentManager
            initialAttachments={session.teacher_attachments}
            sessionType="classroom"
            sessionId={sessionId}
            maxFiles={5}
            disabled={true}
            readOnly={true}
          />

        </div>
      </div>
    </div>
  );
};

export default MyLessonsGroupDetailView;
