import $ from 'jquery';

function _isMobile() {
    return $(window).width() < 900;
}

export default function authMobile() {
    // handles mobile tab animation on auth sign-in page
    var is_mobile = _isMobile(),
        login_tab = $('.login-tab'),
        login_wrapper = $('.login-wrapper'),
        register_tab = $('.register-tab'),
        register_wrapper = $('.register-wrapper');

    // show both auth forms on the desktop
    // show only one form at a time on mobile
    // on mobile we give focus to the sign in form by default
    if ($(document).has('.student-auth-wrapper') && is_mobile) {
        register_wrapper.addClass('hide');
        register_tab.removeClass('tab-focus');
        login_tab.addClass('tab-focus');
    }

    // check to see if the forms need to be reset
    $(window).resize(() => {
        let still_mobile = _isMobile();

        // NOTE: we only reset the page when the size
        // switches from mobile to desktop or vice versa.
        if (is_mobile != still_mobile) {
            is_mobile = still_mobile;
            if (is_mobile) {
                register_wrapper.addClass('hide');
                register_tab.removeClass('tab-focus');
                login_tab.addClass('tab-focus');
            } else {
                // show both forms on desktop
                $('.auth-form-wrapper').removeClass('hide');
            }
        }

    });

    // toggle between registration and sign-in on mobile
    $('.auth-toggle-bar').click(evt => {
        evt.preventDefault();
        let tab = $(evt.target).closest('div.form-tab');

        tab.addClass('tab-focus');

        if (tab.hasClass('register-tab')) {
            login_tab.removeClass('tab-focus');
            register_wrapper.removeClass('hide');
            login_wrapper.addClass('hide');
        } else if (tab.hasClass('login-tab')) {
            register_tab.removeClass('tab-focus');
            login_wrapper.removeClass('hide');
            register_wrapper.addClass('hide');
        }

    });
}
