import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { profileUrls } from '../../../constants.js';

const PrivateProductModalDetails = ({ product }) => {
  if (!product) {
    return null;
  }

  console.log(product);

  return (
    <>
      <h3>{product.package.title}{product.language && <> ({product.language.name})</>}{product.is_active && product.package.is_subscription && '*'}{product.teacher && <> - {product.teacher.first_name} {product.teacher.last_name[0]}.</>}</h3><br/>
      <div>
      Purchase Date: {moment(product.created).format('dddd, MMM Do, YYYY')}<br/>
      Expiration Date: {moment(product.expires_on).format('dddd, MMM Do, YYYY')}<br/><br/>

      {product.is_active ?
        <>{parseFloat(product.total_hours)} Hour{parseFloat(product.total_hours) != 1 && 's'}</>
      :
        '0 Hours remaining'
      }
      </div>

      {product.is_active && (<>
        <div className="hours-bar">
          <div className="hours-item hours-completed">
            <div className="hours-box">{parseFloat(product.hours_used).toFixed(2)}</div>
            Completed
          </div>
          <div className="hours-item hours-scheduled">
            <div className="hours-box">{parseFloat(product.hours_scheduled).toFixed(2)}</div>
            Scheduled<br/>Not Completed
          </div>
          <div className="hours-item hours-unbooked">
            <div className="hours-box">{parseFloat(product.hours_unbooked).toFixed(2)}</div>
            Hours<br/>Unbooked
          </div>
        </div>
        <div className="details-toolbar">
          {product.package.is_subscription && (
            <div className="details-help">
              * Additional plan information located under payment information.
            </div>
          )}
          <div className="details-book">
            <a href={profileUrls.bookLessons} className="btn inverted">Book Lesson</a>
          </div>
        </div>
      </>)}
    </>
  );
};

PrivateProductModalDetails.propTypes = {
  product: PropTypes.object
};

export default PrivateProductModalDetails;
