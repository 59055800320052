import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import FCApi from '../../../fc-api.js';
import TeacherPicture from './TeacherPicture.jsx';
import { useWindowSize } from '../../utils.jsx';

export default function TeacherSelectorView(props) {
  const navigate = useNavigate();

  const [ teachers, setTeachers ] = useState([]);
  const [ timeFilter, setTimeFilter ] = useState('');
  const [ hasMore, setHasMore ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ selectedTeacher, setSelectedTeacher ] = useState(null);
  const [ windowWidth ] = useWindowSize();

  const apiClient = new FCApi();

  useEffect(() => {
    refreshTeacherList();
  }, [timeFilter, page]);

  const containerRef = useRef(null);

  const onTimeFilterChange = (e) => {
    setTeachers([]);
    setPage(1);
    setIsLoaded(false);
    setTimeFilter(e.target.value);
  }

  const onLoadMore = () => {
    setPage(page + 1)
  }

  const refreshTeacherList = () => {
    let params = {
      lesson: props.lesson ? props.lesson.id : null,
      languages: props.language,
      learner: props.learner.id,
      page: page,
      page_size: 5,
      order_by: props.lesson ? '-most_recent,-availabilities__count' : '-availabilities__count',
    };

    if (timeFilter !== '') {
      params.availability = timeFilter;
    }

    let paramsString = $.param(params);
    apiClient.listTeachers((error, response) => {
      setTeachers(teachers.concat(response.results));
      setHasMore(response.count > params.page * params.page_size);
      setIsLoaded(true);
    }, paramsString);
  }

  const onTeacherSelect = (teacher) => {
    setSelectedTeacher(null);
    window.scrollTo(0, containerRef.current.offsetTop);
    navigate(`${teacher.id}`);
  }

  const onTeacherClick = (teacher) => {
    if (windowWidth >= 900)
      onTeacherSelect(teacher);
    else
      setSelectedTeacher(teacher);
  }

  const renderTeacherInfo = (teacher) => {
    return (
      <React.Fragment>
        <div className="picture-container">
          <TeacherPicture teacher={teacher} />
          <div className="quotes-container">
            <div className="name no-translate">{teacher.privacy_protected_name}</div>
            <div className="teacher-quote no-translate">{teacher.quote}</div>

            {teacher.student_review_quote &&
              <div className="review-quote">
                <div className="quote left-quote"><span className="icon-quote-left"></span></div>
                <div className="no-translate">{teacher.student_review_quote}</div>
                <div className="quote right-quote"><span className="icon-quote-right"></span></div>
              </div>
            }
          </div>
        </div>
        <div className="mobile-quote">{teacher.quote}</div>
        <div className="about">
          <div className="about-title">About The Instructor</div>
          <div className="about-text no-translate">{teacher.bio}</div>
        </div>
      </React.Fragment>
    );
  }

  const closeModal = () => {
    setSelectedTeacher(null);
    window.scrollTo(0, containerRef.current.offsetTop);
  }

  const renderModal = () => {
    if (selectedTeacher === null || windowWidth >= 900)
      return;

    return (
      <Modal
        overlayClassName="profile-modal-overlay"
        className="profile-modal teacher-info-modal"
        isOpen={true}
        appElement={$('.account-content-container').get(0)}
        >
        {renderTeacherInfo(selectedTeacher)}

        <div className="modal-buttons">
          <button onClick={() => { closeModal() }}>Close</button>
          <button className="dark-button" onClick={() => { onTeacherSelect(selectedTeacher) }}>Select</button>
        </div>
      </Modal>
    );
  }

  const renderTeachersList = () => {
    if (isLoaded) {
      return teachers.map((teacher) => (
        <div className="teacher-list-item" key={teacher.id} onClick={() => onTeacherClick(teacher)}>
          {renderTeacherInfo(teacher)}
        </div>
      ));
    } else {
      return <div className="teacher-selector loading">Loading...</div>;
    }
  }

  return (
    <div ref={containerRef} className="teacher-selector">
      <div className="title">Select a Teacher</div>
      <div className="filter-wrapper">
        Show teachers with availability:
        <div className="filter">
          <select value={timeFilter} onChange={onTimeFilterChange}>
            <option value="">Any Time</option>
            <option value="morning">Mornings (8am-12pm)</option>
            <option value="afternoon">Afternoons (12pm-4pm)</option>
            <option value="evening">Evenings (4pm-9pm)</option>
            <option value="latenight">Late Night (9pm-8am)</option>
          </select>
        </div>
      </div>
      <section className="teacher-list">
        {renderTeachersList()}
      </section>

      {renderModal()}

      {hasMore &&
      <div className="button-container">
        <button className="load-more" onClick={onLoadMore}>Load More</button>
      </div>
      }
    </div>
  );
}

TeacherSelectorView.propTypes = {
  learner: PropTypes.object.isRequired,
  lesson: PropTypes.object,
  language: PropTypes.string.isRequired
}
