import React from 'react';

import { getTimeRangeString } from './utils.jsx';

function privateLessonCancelSuccess(name, start, end) {
  return (
    <p>Okay, we&apos;ve <b className='force-uppercase'>CANCELLED</b> your&nbsp;
      <b className='force-uppercase'>PRIVATE LESSON</b> with&nbsp;
      <b className='force-uppercase'>{name}</b> on&nbsp;
      <b className='force-uppercase'>{start.format('ddd, MMM Do')}</b> from&nbsp;
      <b className='force-uppercase'>{getTimeRangeString(start, end)}</b>.
    </p>
  )
}

function privateLessonCancelError(name, start, end){
  return(
    <p>Uh oh, we&apos;re unable to <b className='force-uppercase'>CANCEL</b> your&nbsp;
      <b className='force-uppercase'>PRIVATE LESSON</b> with&nbsp;
      <b className='force-uppercase'>{name}</b> on&nbsp;
      <b className='force-uppercase'>{start.format('ddd, MMM Do')}</b> from&nbsp;
      <b className='force-uppercase'>{getTimeRangeString(start, end)}</b>.
    </p>
  );
}

export {
  privateLessonCancelError,
  privateLessonCancelSuccess
}