import PropTypes from 'prop-types'
import React from 'react'


export default function DiscountCodeForm(props) {
    return (
        <div className='DiscountCodeForm'>
            { props.errors.length > 0 &&
                <div className='DiscountCodeForm__errors'>
                    {props.errors.map((e, index) => {
                        return <p key={index}>{e}</p>
                    })}
                </div>
            }
            <div className='DiscountCodeForm__line'>
                { props.isAccepted ?
                    <div className='DiscountCodeForm__applied'>
                        <h4>Code Applied: {props.value}</h4>
                    </div> :
                    <form
                        className='DiscountCodeForm__form'
                        onSubmit={(evt) => props.submitMethod(evt)}>
                        <label>
                            {props.label}
                            <input
                                name={props.name}
                                className='code_input'
                                placeholder={props.placeholder}
                                type='text'
                                maxLength='50'
                                minLength='1'
                                autoComplete='off'
                                value={props.value}
                                onChange={props.handleChange}
                            />
                        </label>
                        <button className='black-gray-button'>{props.submitButtonText}</button>
                    </form>
                }
                <div className='DiscountCodeForm__value'>
                    <h4>{props.appliedValue}</h4>
                </div>
            </div>
        </div>
    )
}

DiscountCodeForm.propTypes = {
    submitMethod: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    errors: PropTypes.array,
    isAccepted: PropTypes.bool,
    appliedValue: PropTypes.string,
}

DiscountCodeForm.defaultProps = {
    isAccepted: false,
    placeholder: '',
    errors: [],
    submitButtonText: 'Apply',
}
