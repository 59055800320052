import PropTypes from 'prop-types'
import React from 'react'
import $ from 'jquery'


export default class QuestionSingleSelect extends React.Component {
    // Question Single Select:
    // This component splits the question text by word and inlines a select
    // dropdown where the '[[answer]]' text is found within the question.
    // A custom select dropdown displays the answer options and updates
    // a hidden select element for form submission purposes
    static propTypes = {
        name: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        options_display: PropTypes.array.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        question: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        question_id: PropTypes.number,
    }

    constructor(props) {
        super(props)
        this.state = {
            selected: props.options_display[0],
            options: props.options_display,
        }
    }

    componentDidMount() {
        // on mount set the first input as the selected answer
        this.props.handleInputChange(this.props.question_id, [this.state.selected])
    }

    toggleDropdown = evt => {
        // Cancel if the element is disabled
        if (
            $(evt.currentTarget).find('.answer-option')[1].className.includes('subseq-answer') &&
            document.getElementsByClassName('select-input-container')[0].className.includes('answered')
        ) {
            return false
        }
        // hide all options but the first
        $(evt.currentTarget).find('.answer-option').not(':first').toggleClass('subseq-answer')

        // toggle the direction of the dropdown arrow
        $(evt.currentTarget).find('#arrow-down').toggleClass('arrow-transform')
    }

    setSelected = evt => {
        // get the text from the clicked display option
        let word = $(evt.currentTarget).find('h4').text()

        // get the index of the clicked word
        let index = this.state.options.indexOf(word)

        // if the clicked option is first, set the selected state
        // else, move it to the font of the array then set the state
        if (index == 0) {
            this.setState({selected: word})
            this.props.handleInputChange(this.props.question_id, [this.state.selected])
        } else {
            let options = this.state.options
            options.splice(index, 1)
            options.unshift(word)
            this.setState({
                selected: word,
                options: options,
            })
            this.props.handleInputChange(this.props.question_id, [this.state.selected])
        }
    }

    render() {
        // reduce all spaces in the question to a single space and split into words
        let words = this.props.question.replace(/\s\s+/g, ' ').split(' ')

        // map over the words in the question and replace the '[[answer]]' string with the
        // inline select dropdown component
        // TODO: Handle the case when the select is not surrounded by spaces.
        //       Like in the case of an ending sentance. i.e. word = '[[answer]].'
        return (
            <div className='quiz-form-select'>
                {words.map((word, index) => {
                        return (
                            <span key={index} className='word'>
                                {word.includes('[[answer]]') ?
                                    <div className='select-input-container' id={'dropdown-' + this.props.question_id}>
                                        <select
                                            name={this.props.name}
                                            className='hide'
                                            size={this.props.options.length}
                                            onChange={() => {}} // user input is handled through the display dropdown
                                            value={this.state.selected}
                                            data-question-id={this.props.question_id}
                                        >
                                            {this.props.options.map(opt => {
                                                return (
                                                    <option key={opt.id} value={opt.answer}>{opt.answer}</option>
                                                )
                                            })}
                                        </select>
                                        <div className="select-dropdown-container-bar"
                                            onClick={this.toggleDropdown}>
                                            <div className="select-dropdown-bar">
                                               {this.state.options.map((opt, index) => {
                                                    return (
                                                        <div key={index}
                                                            className={'answer-option' + (index != 0 ?  ' subseq-answer': '')}
                                                            onClick={this.setSelected}
                                                            id={opt}>
                                                            <h4>{opt}</h4>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='box-arrow'>
                                                <img id='arrow-down'
                                                    className='arrow-transform'
                                                    alt='drop down arrow'
                                                    src='/static/img/whitearrow.svg'/>
                                            </div>
                                        </div>
                                    </div>
                                    : <h4>{word}</h4>}
                            </span>
                        )
                    })}
            </div>
        )
    }
}
