export function gtmPurchaseEvent(orderNumber, data) {
  let products = [];
  const addItem = (item) => {
    products.push({
      name: item.cart_display.title,
      id: item.object_id,
      price: item.price,
      category: item.content_type,
      quantity: item.quantity,
      variant: item.product.is_online ? 'online' : null
    });

    if (item.children) {
      item.children.forEach((child) => {
        addItem(child);
      });
    }
  };

  data.line_items.forEach((item) => {
    addItem(item);
  });

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'enhancedpurchase',
      ecommerce:{
        purchase: {
          actionField: {
            id: orderNumber,
            affiliation: 'fluentcity',
            revenue: data.total_after_discount,
            coupon: data.promo_code
          },
          products: products
        }
      }
    });
  } catch (err) {
    console.error('GTM purchase error', err);
  }
}

export function friendBuyOrder(orderNumber, data) {
  try {
    window.friendbuy = window.friendbuy || [];
    window.friendbuy.push(['track', 'order', {
      id: orderNumber,
      amount: data.total_after_discount,
      email: window.loggedin_user_email,
      coupon_code: data.promo_code
    }]);
	} catch (err) {
		console.error('FriendBuy error', err);
	}
}
