import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { salesLeadsUrl } from '../constants.js';
import { FormErrorMessage } from '../profile/utils.jsx';
import FCApi from '../fc-api.js';
import { ToastNotificationsSuccess, ToastNotificationsError } from '../notifications/ToastNotifications.jsx';

const modalStyle = {
  content: {
    margin: '15% auto',
    padding: '20px',
    border: '1px solid black',
    maxWidth: '400px',
    borderRadius: '0'
  }
};

const SalesLeadModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [location, setLocation] = useState(null);

  const apiClient = new FCApi();

  useEffect(() => {
    apiClient.getLanguages((error, response) => {
      if (!error) { setLanguages(response); }
    });
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  $('[data-saleslead-open]').click((e) => {
    e.preventDefault();
    setLocation($(e.target).data('saleslead-location'));
    openModal();
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="SalesLeadModal"
        shouldCloseOnOverlayClick={true}
        appElement={$('#react-launch-SalesLeadModal').get(0)}
        style={modalStyle}
        onRequestClose={() => closeModal()}
      >
        <div className='close' onClick={() => closeModal()}></div>
        <Formik
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            'language': Yup.string().required('Please choose a language.'),
            'email': Yup.string().email('Invalid email address.').required('Please enter your email.'),
            'extra': Yup.string()
          })}
          initialValues={{
            'language': '',
            'email': '',
            'extra': ''
          }}
          onSubmit={(values, actions) => {
            values.form_location = location;
            values.page_url = window.location.pathname;

            $.post(salesLeadsUrl, values).done(() => {
              actions.setSubmitting(false);
              toast.success(
                <ToastNotificationsSuccess
                  closeToast={() => {}}
                  htmlContent={
                    <p>Request submitted. We&apos;ll be in touch.</p>
                  }
                />,
                {
                  autoClose: 3200,
                  containerId: 'saleslead-toast'
                }
              );
              closeModal();
            }).fail(() => {
              actions.setSubmitting(false);
              toast.error(
                <ToastNotificationsError
                  closeToast={() => {}}
                  htmlContent={
                    <p>Error submitting form. Please contact us.</p>
                  }
                />,
                {
                  autoClose: 3200,
                  containerId: 'saleslead-toast'
                }
              );
            });
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="fc-form form-inline">
              <div className="form-group">
                <Select
                  name="language"
                  className='react-select-container'
                  isClearable={false}
                  isSearchable={false}
                  placeholder="Choose a language to learn"
                  options={languages.map(val => {
                    return {
                      value: val.name,
                      label: val.name
                    };
                  })}
                  onChange={(e) => {
                    setFieldValue('language', e.value);
                  }}
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="language" />

              <div className="form-group">
                <Field
                  name="email"
                  placeholder="Your email"
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="email" />

              <div className="form-group">
                <Field
                  name="extra"
                  placeholder="Tell us about your language learning goals"
                  component="textarea"
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="extra" />

              <div className="form-toolbar">
                {isSubmitting ? <div className="submit-spinner spinner"></div> : ''}
                <input className='profile-submit-btn dark-button' type="submit" id="submit" value="Submit" disabled={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <ToastContainer enableMultiContainer containerId="saleslead-toast" draggable={false} closeButton={false} />
    </>
  );
}

export default SalesLeadModal;
