import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';

import { private_lessonURL } from '../../../constants.js';
import FCApi from '../../../fc-api.js';
import { ToastNotificationsError, ToastNotificationsSuccess } from '../../../notifications/ToastNotifications.jsx';

const modalStyle = {
  content: {
    margin: '15% auto',
    padding: '20px',
    border: '1px solid black',
    maxWidth: '750px',
    borderRadius: '0'
  }
};

function SubscriptionBanner(props) {
  if (props.show) {
    return (
      <div className="subscription-alert subscription-alert-warn">
        <div className="subscription-alert-message">
          subscription paused - resumes on {moment(props.resumeDate).format('MMMM Do, YYYY')}
        </div>
        <a className="subscription-alert-action">resume now</a>
      </div>
    );
  }
  return null;
}

SubscriptionBanner.propTypes = {
  show: PropTypes.bool.isRequired,
  resumeDate: PropTypes.string,
};

export default function SubscriptionView(props) {  // eslint-disable-line no-unused-vars

  const [subscription, setSubscription] = useState(null);
  const [packages, setPackages] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const studentInfo = JSON.parse($('#js-student-info').text());

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isChangingPlan, setIsChangingPlan] = useState(false);

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  /*
  ** Refresh the page and fetch fresh data from the API endpoint.
  */
  const refresh = () => {
    if (window.user.subscriptionId) {
      apiClient.getSubscription(
        window.user.subscriptionId,
        (error, response) => {
          setSubscription(response);
        }
      );
    } else {
      setSubscription(null);
    }

    apiClient.listPrivateLessonPackages((error, response) => {
      response.sort((a, b) => parseFloat(a.price) > parseFloat(b.price) ? 1 : -1);
      setPackages(response);
    });
  };

  /*
  const openCancelModal = (e) => {
    e.preventDefault();
    setIsCancelModalOpen(true);
  };
  */

  const closeCancelModal = (e) => {
    e.preventDefault();
    setIsCancelModalOpen(false);
  };

  const cancelSubscription = (e) => {
    e.preventDefault();
    setIsCancelling(true);
    apiClient.updateSubscription(
      subscription.id,
      {'status': 'canceled'},
      (error, response) => {  // eslint-disable-line no-unused-vars
        setIsCancelling(false);
        setIsCancelModalOpen(false);
        if (!error) {
          toast.success(
            <ToastNotificationsSuccess closeToast={() => {}} htmlContent={<span>Subscription canceled</span>} />,
            { autoClose: 3200 }
          );
          location.reload();
        } else {
          toast.error(
            <ToastNotificationsError closeToast={() => {}} htmlContent={<span>Error canceling subscription</span>} />,
            { autoClose: 3200 }
          );
        }
      }
    );
  };

  const openChangeModal = (e) => {
    e.preventDefault();
    setIsChangeModalOpen(true);
  };

  const closeChangeModal = (e) => {
    e.preventDefault();
    setSelectedPlan(null);
    setIsChangeModalOpen(false);
  };

  const onPlanSelectionChange = (e) => {
    const pkg = packages.find(el => el.stripe_plan == e.currentTarget.value);
    setSelectedPlan(pkg);
  }

  const changePlan = (e) => {
    e.preventDefault();
    setIsChangingPlan(true);
    apiClient.updateSubscription(
      subscription.id,
      {'new_plan': selectedPlan.stripe_plan},
      (error, response) => {  // eslint-disable-line no-unused-vars
        setIsChangingPlan(false);
        setIsChangeModalOpen(false);
        if (!error) {
          toast.success(
            <ToastNotificationsSuccess closeToast={() => {}} htmlContent={<span>Subscription updated</span>} />,
            { autoClose: 3200 }
          );
          location.reload();
        } else {
          toast.error(
            <ToastNotificationsError closeToast={() => {}} htmlContent={<span>Error updating subscription</span>} />,
            { autoClose: 3200 }
          );
        }
      }
    );
  };


  const has_subscription = (
    subscription &&
    subscription.private_lesson &&
    subscription.private_lesson.package
  );

  return (
    <div className="StudentSubscriptionView">
      <div>
        <h2 className="inverted">My Subscription Plan</h2>
        {/*
        <SubscriptionBanner
          show={subscription.paused}
          resumeDate={subscription.resumeDate}
        />*/}

        {has_subscription ?
          <div className="subscription-detail">

            <div className="subscription-detail-header">
              <div className="subscription-title"><strong>{subscription.private_lesson.package.title} Plan</strong></div>
              <div className="subscription-hours">
                <strong>
                  {subscription.private_lesson.package.number_of_hours} hours
                  {subscription.private_lesson.package.stripe_product ?
                    ` per ${subscription.plan.interval}` : ''
                  }
                </strong>
              </div>
            </div>

            <div className="subscription-detail-group">
              <dl className="subscription-detail-list">
                {subscription.private_lesson.package.stripe_product ? <>
                  <dt className="subscription-detail-list-title">
                    <strong>Next payment:</strong>
                  </dt>
                  <dd className="subscription-detail-list-description">
                    {subscription.canceled_at && !studentInfo.pending_plan_change ? 'No upcoming payments' : moment(subscription.current_period_end).format('MMMM Do, YYYY')}
                    {studentInfo.pending_plan_change && ` (Pending change to ${studentInfo.pending_plan_change.new_package.title} plan)`}
                  </dd>
                </> : ''}
                <dt className="subscription-detail-list-title">
                  <strong>Completed hours:</strong>
                </dt>
                <dd className="subscription-detail-list-description">
                  {parseFloat(subscription.private_lesson.hours_used).toString()}
                </dd>
                <dt className="subscription-detail-list-title">
                  <strong>Upcoming hours:</strong>
                </dt>
                <dd className="subscription-detail-list-description">
                  {parseFloat(subscription.private_lesson.hours_booked).toString()}
                </dd>
                <dt className="subscription-detail-list-title">
                  <strong>Unreserved hours:</strong>
                </dt>
                <dd className="subscription-detail-list-description">
                  {parseFloat(subscription.private_lesson.hours_unbooked).toString()}
                </dd>
                <dt className="subscription-detail-list-title">
                  <strong>Hours expire:</strong>
                </dt>
                <dd className="subscription-detail-list-description">
                  {moment(subscription.private_lesson.expires_on).format('MMMM Do, YYYY')}
                </dd>
              </dl>
            </div>
          </div>
        : <div className="no-plan">
            <div className="no-plan-text">You do not have an active plan.</div>
            <a href={private_lessonURL} className="btn-get-started dark-button">Get Started</a>
          </div>
        }
      </div>

      {has_subscription ?
        <div className="subscription-actions">
          <div className="subscription-actions-row">
            {(!subscription.canceled_at || studentInfo.pending_plan_change) && ''
              /*
              <a onClick={openCancelModal} className="subscription-actions-cancel" href="#">cancel</a>
              */
            }
            <a onClick={openChangeModal} className="subscription-change" href="#">edit plan</a>
            {/*
              <a className="subscription-actions-pause" href="#">pause</a>
            */}
          </div>
        </div>
      : ''}

      {subscription && <>
        <Modal
          isOpen={isCancelModalOpen}
          style={modalStyle}
        >
          <h2 className="inverted">Are you sure?</h2>
          <p>Are you sure you want to cancel your plan?</p>
          <div className="toolbar">
            {isCancelling ? <div className="spinner"></div> : ''}
            <a onClick={closeCancelModal} href="#" className="btn inverted">No</a>
            <a onClick={cancelSubscription} href="#" className={isCancelling ? 'btn disabled' : 'btn'}>Yes</a>
          </div>
        </Modal>

        <Modal
          isOpen={isChangeModalOpen}
          style={modalStyle}
          className="change-plan-modal"
        >
          <h2 className="inverted">Edit Plan</h2>
          <p>Choose a new plan below:</p>

          {packages.map(pkg => (pkg.stripe_plan != studentInfo.subscription.plan_id &&
            <div className="plan-option" key={pkg.id}>
              <label>
                <input
                  name="select-plan"
                  type="radio"
                  value={pkg.stripe_plan}
                  onChange={onPlanSelectionChange}
                />
                <strong>
                  {`${pkg.number_of_hours} HOUR${pkg.number_of_hours > 1 ? 'S' : ''} ($${parseFloat(pkg.price)} / month)`}
                </strong>
              </label>
            </div>
          ))}

          {selectedPlan && parseFloat(selectedPlan.price) >= parseFloat(studentInfo.active_package.price) &&
            <div className="change-warning">Gain instant access to more hours. This plan change will charge your default payment method immediately. Any unused hours from your current plan will be automatically transferred.</div>
          }
          {selectedPlan && parseFloat(selectedPlan.price) < parseFloat(studentInfo.active_package.price) &&
            <div className="change-warning">This plan change will take place at the end of the current billing cycle. All future monthly payments will be charged at this amount.</div>
          }

          <div className="toolbar">
            <div className="suspend-plan">
              <a className="suspend-plan-link" rel="noopener noreferrer" target="_blank" href="https://cricketmedia.typeform.com/to/fOG6yWQS">
                Need to pause or cancel your subscription?
              </a>
            </div>
            {isChangingPlan ? <div className="spinner"></div> : ''}
            <a onClick={closeChangeModal} href="#" className="btn inverted">Go Back</a>
            <a onClick={changePlan} href="#" className={isChangingPlan || !selectedPlan ? 'btn disabled' : 'btn'}>Change Plan</a>
          </div>
        </Modal>
      </>}

      <ToastContainer draggable={false} closeButton={false} />
    </div>
  );

}
