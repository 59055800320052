import queryString from 'query-string';
import React from 'react';
import { Formik, Form } from 'formik';
import moment from 'moment-timezone';

import FCApi from '../fc-api.js';
import DatePickerField from './DatePickerField.jsx';

export default class PayrollView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      payments: [],
    }

    this.apiClient = new FCApi();
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh(cb) {
    this.apiClient.listPayments((error, response) => {
      this.setState({payments: response});
      if (cb)
        cb();
    }, queryString.parse(location.search));
  }

  render() {
    let args = queryString.parse(location.search);
    let initialValues = {
      from: args.from ? moment(args.from) : '',
      to: args.to ? moment(args.to) : ''
    };

    return (
      <div className="PayrollView">
        <h2 className="inverted">My Payments</h2>
        <div className="payments-filter">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              let args = {};
              if (values.from)
                args.from = moment(values.from).format('YYYY-MM-DD');
              if (values.to)
                args.to = moment(values.to).format('YYYY-MM-DD');

              let newPath = `?${queryString.stringify(args)}`;
              window.location.search = newPath;
              this.refresh(() => {actions.setSubmitting(false)});
            }}
          >
            {({ values, isSubmitting, setFieldValue, handleSubmit }) => (

              <Form className="form-inline">
                <label htmlFor="from">
                  <span>From</span>
                  <DatePickerField
                    name="from"
                    value={values.from}
                    onChange={setFieldValue}
                  />
                </label>
                <label htmlFor="to">
                  <span>to</span>
                  <DatePickerField
                    name="to"
                    value={values.to}
                    onChange={setFieldValue}
                  />
                </label>

                <div className="submit-container">
                  {isSubmitting ? <div className="submit-spinner spinner"></div> : ''}
                  <a href="#" onClick={handleSubmit} className={isSubmitting ? 'submit disabled' : 'submit'}>Submit</a>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        {this.state.payments.length ?
          <div className="payments-table">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Item</th>
                  <th>Amount</th>
                  <th>Pay Period End Date</th>
                </tr>
              </thead>
              <tbody>
              {this.state.payments.map((p) => (
                <tr key={p.id}>
                  <td>
                    {moment.tz(p.start_at, window.user.timezone).format('MM-DD-YYYY h:mma')}
                    &nbsp;-&nbsp;
                    {moment.tz(p.end_at, window.user.timezone).format('h:mma')}
                  </td>
                  <td>{p.session_name}</td>
                  <td>${p.total_amount}</td>
                  <td>{p.pay_period ? moment(p.pay_period_end_date).format('MMM. D, YYYY') : 'Forthcoming pay period'}</td>
                </tr>
              ))}
                <tr className="total-spacer"><td colSpan="5"></td></tr>
                <tr>
                  <td className="total-column">TOTAL</td>
                  <td colSpan="2">&nbsp;</td>
                  <td><strong>{this.state.payments.reduce((x, y) => x + parseFloat(y.duration), 0)}</strong></td>
                  <td><strong>${this.state.payments.reduce((x, y) => x + parseFloat(y.total_amount), 0).toFixed(2)}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        :
          <div className="no-payments">
            No payments found.
          </div>
        }
      </div>
    );
  }

}
