import PropTypes from 'prop-types'
import React from 'react'
import $ from 'jquery'
import ProgressBar from './progress-bar.jsx'
import QuizResultsPage from './quiz-results-page.jsx'
import QuizPage from './quiz-page.jsx'
import FeedbackBar from './feedback-bar.jsx'
import {scorePage, scoreQuiz, getQuizSeries, getQuizData} from './api'


export default class QuizSeries extends React.Component {
    // This Component represents the Quiz Home Page that provides an
    // overview of all QuizSeries that are available to the user.
    static propTypes = {
        description: PropTypes.string,
        image: PropTypes.string,
        name: PropTypes.string.isRequired,
        page_count: PropTypes.number.isRequired,
        question_count: PropTypes.number.isRequired,
        quizzes: PropTypes.array.isRequired,
        quizOverviewReturn: PropTypes.func.isRequired,
        series_id: PropTypes.number.isRequired,
        show_page_feedback: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            total_correct: 0,
            total_questions: 0,
            total_answered: 0,
            quiz_data: {},
            quiz: {
                prev: -1,
                current: 0,
                next: 1,
            },
            pg: {
                prev: -2,
                current: -1,
                next: 0,
            },
            quiz_pg: 1,  // the page number of an individual quiz
            errors: {},  // holds the users incorrect answers
            passed: false,
            result_title: '',
            result_tagline: '',
            showResultsPage: false,
            showingFeedback: false,
            nextBtnEnabled: false, // sets the ability to advance to the next quiz page
            finished: [],
        }
        this.quiz_id = this.props.quizzes[0]
        this.page_data = {}
        this.timeout = null

        this.scorePage = scorePage.bind(this)
        this.scoreQuiz = scoreQuiz.bind(this)
        this.getQuizSeries = getQuizSeries.bind(this)
        this.getQuizData = getQuizData.bind(this)
    }

    UNSAFE_componentWillMount() {
        // load the first quiz in the index on mount
        // TODO this lifecycle method is not async safe.
        // consider refactoring to use componentDidMount
        this.nextQuiz()
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.quiz_id = this.props.quizzes[nextState.quiz.current - 1]
        this.page_data = nextState.quiz_data.pages[nextState.quiz_pg - 1]
    }

    enableNextBtn = value => {
        // Callback provieded to the QuizPage which passes back a bool value
        // indicating whether all required question have been answerd
        this.setState({nextBtnEnabled: value})
    }

    getRequiredQuestions = questions => {
        // Create an object containing answer values of each required question
        // on the current quiz page. Answers are initially set as empty arrays
        // then updated based on user input values. A future use case might be
        // be to show previously saved answers. Example:
        //     {'1': [''], '3': ['previous answer']}
        let requiredQuestions = {}
        questions.map(question => {
            if (question.required) {
                requiredQuestions[question.id] = []
            }
        })
        return requiredQuestions
    }

    gradePage = callback => {
        // Grade current page before proceeding on to the next one.
        let data = {
            'series_id': this.props.series_id,
            'quiz_id': this.quiz_id,
            'page_id': this.page_data.id,
            'form': $('#quiz-form').serializeArray(),
        }
        this.scorePage(data, response => callback(response))
    }

    returnToOverview = evt => {
        evt.preventDefault()
        this.props.quizOverviewReturn()
    }

    prevPage = evt => {
        evt.preventDefault()
        let quiz_pg = this.state.quiz_pg - 1
        // check if they are on the first page of the current Quiz
        if (quiz_pg <= 0) {
            // check if they are on the first Quiz of the whole Series
            if (this.quiz_id === this.props.quizzes[0]) {
                // return to QuizOverview homepage
                this.props.quizOverviewReturn()
            }
            else {
                this.prevQuiz()
            }
        }
        else {
            this.setState({
                pg: {
                    prev: Math.max(this.state.pg.prev - 1, 0),
                    current: Math.max(this.state.pg.prev, 1),
                    next: Math.max(this.state.pg.current, 2),
                },
                quiz_pg: quiz_pg,
            })
        }
    }

    nextPage = () => {
        if (this.state.nextBtnEnabled) {
            window.clearTimeout(this.timeout)
            let num_answered = this.state.total_answered + this.page_data.questions.length
            this.setState({total_answered: num_answered})
            this.gradePage(response => {
                this.currentResponse = Object.keys(response)[0] ? response[Object.keys(response)[0]] : {correct: true}
                this.setState({'errors': $.extend(this.state.errors, response)}, () => {
                    var showResults = this.state.showResultsPage
                    // check if they are on the last page of the current Quiz
                    if (this.state.quiz_pg >= this.state.quiz_data.page_count
                        && (!this.props.show_page_feedback || this.state.showingFeedback)
                    ) {
                        // now check if they are on the last Quiz in the series
                        if (this.quiz_id === this.props.quizzes[this.props.quizzes.length - 1]) {
                            showResults = true
                        }
                        // grade the quiz and bail out early if they fail to meet the threshold
                        this.scoreQuiz(this.quiz_id, this.state.errors, response => {
                            if (response.passed === false) { showResults = true }
                            // update the state with the grade information
                            // TODO: if they have gone back at any point and resubmitted a
                            // question then this will incorrectly update. You need to alter
                            // the logic so that updating the wrong/right counts sums the vals
                            // of a dict where the keys are question_id's and a values are a
                            // bool (1 for passed, 0 for failed)
                            this.setState({
                                total_questions: this.state.total_questions + response.total_questions,
                                total_correct: this.state.total_correct + response.total_correct,
                                finished: this.state.finished.concat([response.quiz_id]),
                                enableNext: false,
                                passed: response.passed,
                                result_title: response.result_title,
                                result_tagline: response.result_tagline,
                                showResultsPage: showResults,
                                showingFeedback: false,
                                nextBtnEnabled: false
                            }, () => {
                                if (!showResults) { this.nextQuiz() }  // yay they passed!
                            })
                        })
                    } else if (!this.props.show_page_feedback || this.state.showingFeedback) {
                    // they are simply moving on to the next page
                         this.setState({
                            pg: {
                                prev: this.state.pg.current,
                                current: this.state.pg.next,
                                next: this.state.pg.next + 1,
                            },
                            showingFeedback: false,
                            quiz_pg: this.state.quiz_pg + 1,
                            nextBtnEnabled: false
                        })
                    } else {
                        var dropdown
                        this.setState({showingFeedback: true})
                        var firstKey = typeof response=='object' ? Object.keys(response)[0] : false
                        response = firstKey ? response[firstKey][firstKey] : response
                        this.timeout = window.setTimeout(this.nextPage.bind(this), response.correct === false ? 3500 : 1000)
                        switch (response.question_type) {
                            // Add feedback classes.
                            case 'Select':
                                dropdown = document.getElementById('dropdown-' + response.id)
                                dropdown.classList.add(
                                    response.correct ? 'answered-correct' : 'answered-incorrect'
                                )
                                response.feedback.map(function (opt) {
                                    if (opt.correct) { this.currentCorrectAnswer = [opt.answer] }
                                }.bind(this))
                                break
                            case 'Select Multiple':
                                var el
                                this.currentCorrectAnswer = []
                                response.feedback.map(function (opt) {
                                    el = document.getElementById('multi-select-' + opt.id)
                                    if (opt.correct) { this.currentCorrectAnswer.push([el.innerText]) }
                                    el.classList.add(
                                        opt.correct ? 'answered-correct' : 'answered-incorrect'
                                    )
                                }.bind(this))
                                break
                            case 'Input - Text':
                                document.getElementById('question-input-' + response.id).classList.add(
                                    response.correct ? 'answered-correct' : 'answered-incorrect'
                                )
                                this.currentCorrectAnswer = [response.feedback.filter(function (x) {
                                    return x.correct
                                })[0].answer]
                                break
                            default:
                                el = document.getElementsByClassName('select-dropdown-container-bar')[0]
                                if (el) { el.classList.add('answered-correct') }
                        }
                    }
                })
            })
        }
        // TODO: Add alert div to ask for question input if next btn is disabled.
        // TODO: Toggle class on next btn to show disabled state
    }

    prevQuiz = () => {
        this.quiz_id = this.props.quizzes[this.state.quiz.prev - 1]
        this.getQuizData(this.quiz_id, data => {
            this.setState({
                quiz_data: data,
                quiz_pg: data.page_count,
                quiz: {
                    prev: this.state.quiz.prev - 1,
                    current: this.state.quiz.prev,
                    next: this.state.quiz.current,
                },
                pg: {
                    prev: this.state.pg.prev - 1,
                    current: this.state.pg.prev,
                    next: this.state.pg.current,
                }
            })
        })
    }

    nextQuiz = () => {
        this.quiz_id = this.props.quizzes[this.state.quiz.next- 1]
        this.getQuizData(this.quiz_id, data => {
            this.setState({
                quiz_data: data,
                quiz_pg: 1,
                quiz: {
                    prev: this.state.quiz.current,
                    current: this.state.quiz.next,
                    next: this.state.quiz.next + 1,
                },
                pg: {
                    prev: this.state.pg.current,
                    current: this.state.pg.next,
                    next: this.state.pg.next + 1,
                }
            })
        })
    }

    showResults = () => {
        // handler for the "SEE RESULTS" button
        // they need to at least be on question 2 before they submit the quiz
        if (this.state.pg.current > 0) {
            var errors_copy = $.extend({}, this.state.errors)
            let remaining_count = this.state.quiz_data.page_count - this.state.pg.current
            if (remaining_count > 0) {
                // mark all the incompleted page questions as incorrect/blank
                let remaining_pgs = this.state.quiz_data.pages.slice(
                    this.state.pg.current, this.state.quiz_data.page_count)
                remaining_pgs.forEach(pg => {
                    pg.questions.forEach(question => {
                        errors_copy[question.id] = '__BLANK__'
                    })
                })
            }
            this.scoreQuiz(this.quiz_id, errors_copy, response => {
                let data = {
                    errors: $.extend({}, errors_copy, response.errors),
                    total_questions: response.total_questions,
                    total_correct: response.total_correct,
                    passed: response.passed,
                    result_title: response.result_title,
                    result_tagline: response.result_tagline,
                    showResultsPage: true,
                }
                this.setState(data)
            })
        }
    }

    render() {

        if (this.state.showResultsPage) {
            return (
                <QuizResultsPage
                    series_id={this.props.series_id}
                    language={this.state.quiz_data.language}
                    level={this.state.quiz_data.level.toString()}
                    result_title={this.state.result_title}
                    result_tagline={this.state.result_tagline}
                    total_answered={this.state.total_answered}
                    errors={this.state.errors}
                    completed={this.state.finished} />
            )
        }
        else {
            if ($.isEmptyObject(this.state.quiz_data)) {
                return (
                    <div className='quiz-box quiz-loading'>
                        <h1>loading...</h1>
                    </div>
                )
            } else {
                // Get object of containing the required questions for the quiz page
                let requiredQuestions = this.getRequiredQuestions(this.page_data.questions)
                // Determine whether this is the first page of the first quiz
                let firstQuizPage = this.state.quiz_pg === 1 && this.quiz_id === this.props.quizzes[0]
                // if so, change the results btn to return to the QuizOverview homepage
                let resultsBtnText = firstQuizPage ? 'Return to quiz options' : 'I\'m stuck. See the results'

                return (
                    <section className="quiz-box quiz-series">
                        <div className='progress-bar-wrapper'>
                            <ProgressBar
                                currentQuizIndex={(this.state.quiz.current - 1)}
                                quizPageCount={(this.state.quiz_data.page_count)}
                                quizPage={(this.state.quiz_pg)}
                                quizPercent={(this.state.quiz_pg / this.state.quiz_data.page_count) * 100}
                            />
                        </div>
                        <div id={`quiz-page-${this.page_data.id}`} className='quiz-page-container'>
                            <FeedbackBar
                                response={this.currentResponse}
                                correctAnswer={this.currentCorrectAnswer}
                                visible={this.state.showingFeedback}
                            />
                            <QuizPage
                                quiz_id={this.state.quiz_data.id}
                                page_id={this.page_data.id}
                                title={this.page_data.title || ''}
                                description={this.page_data.description || ''}
                                image_url={this.page_data.image || ''}
                                questions={this.page_data.questions}
                                requiredQuestions={requiredQuestions}
                                showingFeedback={this.state.showingFeedback}
                                enableNextBtn={this.enableNextBtn}
                            />
                            <a id='quiz-next-btn' className="black-gray-button quiz-nav-btn" href="#" onClick={this.nextPage}>
                                <span>{this.state.showingFeedback || !this.props.show_page_feedback ? 'NEXT' : 'SUBMIT'}</span>
                                <img alt='right arrow icon' src='/static/img/logos/arrow-right.svg'/>
                            </a>
                        </div>
                        <a id='quiz-results-btn'
                           className='black-gray-button quiz-nav-btn' href='#'
                           onClick={firstQuizPage ? this.returnToOverview : this.showResults}>
                           {firstQuizPage &&
                                <img alt='right arrow icon' src='/static/img/logos/arrow-left.svg'/>
                           }
                           <span>{resultsBtnText}</span>
                        </a>
                    </section>
                )
            }
        }
    }
}
