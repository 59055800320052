import PropTypes from 'prop-types'
import React from 'react'
import {getCourseSuggestions} from './api.js'


export default class QuizSuggestionPage extends React.Component {

    static propTypes = {
        series_id: PropTypes.number.isRequired,
        total_answered: PropTypes.number.isRequired,
        errors: PropTypes.object.isRequired,
        completed: PropTypes.array.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            quiz_results: [],
            passed: null,
            final_grade: 0,
            total_questions: 0,
            total_correct: 0,
            sections: {},
            category_name: '',
            language_name: '',
            level: '',
        }
        this.getCourseSuggestions = getCourseSuggestions.bind(this)
    }

    componentDidMount() {
        let data = {
            series_id: this.props.series_id,
            errors: this.props.errors,
            completed: Array.from(this.props.completed),
        }
        this.getCourseSuggestions(data, response => {
            this.setState({
                total_questions: response.total_questions,
                total_correct: response.total_correct,
                passed: response.passed,
                final_grade: response.final_grade,
                errors: response.errors,
                quiz_results: response.quizzes,
                sections: response.recommendations,
                category_name: response.category_name,
                language_name: response.language_name,
                level: response.level,
            })
        })

        window.dataLayer.push({'event': 'receives_results'})
    }

    render() {
        return (
            <div id='quiz-suggestion-page' className='quiz-page'>
                <div className='quiz-header-sm quiz-suggestion-header'>
                    <p>We recommend</p>
                    <h3>{this.state.language_name}</h3>
                    { this.state.level
                        ? <h1>LEVEL {this.state.level}</h1>
                        : <h1>{this.state.category_name}</h1>}
                    <p id='quiz-score'>You scored <b id='quiz-score-right-count'>
                        {this.state.total_correct}</b> out of <b id='quibore-total-count'>{this.props.total_answered}</b> correct answers.
                    </p>
                </div>
                <div className='quiz-suggestion-body'>
                    <div className='quiz-suggestions'>
                        <p className='quiz-description'>Upcoming classes</p>
                        {Object.keys(this.state.sections).map((section, i) => {
                            return (
                                <section key={i} className='suggestion-group-links'>
                                    <h2>{section}</h2>
                                    <ul className='section-links'>
                                        {this.state.sections[section].map((link, j) => {
                                            return (
                                                <li key={j} onClick={() => {window.dataLayer.push(
                                                    {'event': 'clicks_recommendation', 'type': [section]})}}>
                                                    <a href={link.url}>{link.txt}</a>
                                                </li>)
                                        })}
                                    </ul>
                                </section>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
