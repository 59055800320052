import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';

import { ToastNotificationsError, ToastNotificationsSuccess } from '../notifications/ToastNotifications.jsx';
import { privateLessonCancelError, privateLessonCancelSuccess } from './constants.jsx';
import { getTimeRangeString } from './utils.jsx';
import { abstractTimeRanges, profileUrls } from '../constants.js';
import FCApi from '../fc-api.js';
import SessionCard from './SessionCard.jsx';
import TeacherCTA from './TeacherCTA.jsx';
import EmptySessionsCard from './EmptySessionsCard.jsx';

export default function MyLessonsView(props) { // eslint-disable-line no-unused-vars
  let callCount = 0;

  const USER_TIMEZONE = window.user_timezone ? window.user_timezone : 'America/Detroit';
  moment.tz.setDefault(USER_TIMEZONE);

  // Session Data
  const [ learners, setLearners ] = useState([]);
  const [ privateSessions, setPrivateSessions ] = useState([]);
  const [ classroomSessions, setClassroomSessions ] = useState([]);
  const [ completedSessions, setCompletedSessions ] = useState([]);
  const [ upcomingSessions, setUpcomingSessions ] = useState([]);
  const [ completedSessionsIndex, setCompletedSessionsIndex ] = useState(3);
  const [ upcomingSessionsIndex, setUpcomingSessionsIndex ] = useState(3);
  const [ isLoading, setIsLoading ] = useState(true);

  // Filter States
  const [ selectedLearnerId, setSelectedLearnerId ] = useState(null);
  const [ selectedSessionStatus, setSelectedSessionStatus ] = useState(
    window.user_type === 'student' ? abstractTimeRanges.UPCOMING : abstractTimeRanges.ALL
  );

  // Action Intermediate States
  const [ sessionToCancel, setSessionToCancel ] = useState(null);
  const [ userType, setUserType ] = useState(window.user_type); // remove

  // API Client
  const apiClient = new FCApi();

  useEffect(() => {
    const imageUrl = '/static/img/assets/account/student-my-lessons-header.jpg'
    $('.medium-hero.student-view').css('background-image', 'url(' + imageUrl + ')');

    if (window.user_type  === 'student') {
      fetchLearners();
    }
    refresh();
    setUserType(window.user_type); // remove
  }, []);

  useEffect(() => {
    combineSessionsWrapper();
  }, [privateSessions, classroomSessions, selectedLearnerId])

  const fetchLearners = () => {
    apiClient.v2ListLearners(
      (error, response) => {
        if (!error) {
          setLearners(response);
        }
      }
    );
  };

  const startApiCall = () => {
    callCount += 1;
    setIsLoading(true);
  };

  const stopApiCall = () => {
    callCount = Math.max(callCount-1, 0);
    if (callCount == 0) {
      setIsLoading(false);
    }
  };

  const getURLParams = () => {
    let params = {
      'end_at__gte': moment().subtract(90, 'days').toISOString(),
      'start_at__lte': moment().add(90, 'days').toISOString()
    };

    if (userType == 'teacher') {
      params['teacher'] = window.user.id;
    }

    return params;
  };

  const refresh = () => {
    let psParams = getURLParams();
    psParams['expand'] = 'teacher,lesson.student,lesson.learner'
    startApiCall();
    apiClient.v2ListPrivateSessions(
      (error, response) => {
        stopApiCall();
        if (!error) {
          setPrivateSessions(response);
        }
      },
      $.param(psParams)
    );

    let csParams = getURLParams();
    csParams['expand'] = 'teacher,course.template,venue';
    if (window.user_type === 'student') {
      csParams['expand'] += ',course.my_enrollments.learner';
    }
    startApiCall();
    apiClient.v2ListClassroomSessions(
      (error, response) => {
        stopApiCall();
        if (!error) {
          setClassroomSessions(response);
        }
      },
      $.param(csParams)
    );
  };

  const combineSessionsWrapper = () => {
    let filteredPrivateSessions = privateSessions;
    let filteredClassroomSessions = classroomSessions;

    if (selectedLearnerId) {
      filteredPrivateSessions = privateSessions.filter(ps => ps.lesson.learner.id == selectedLearnerId);
      filteredClassroomSessions = classroomSessions.filter(cs => cs.course.my_enrollments[0].learner.id == selectedLearnerId);
    }

    let combined = filteredPrivateSessions.concat(filteredClassroomSessions);

    let completed = combined.filter(s => moment(s.end_at).isBefore(moment()));
    completed.sort((a, b) => moment(b.start_at).diff(moment(a.start_at)));
    setCompletedSessions(completed);

    let upcoming = combined.filter(s => moment(s.end_at).isAfter(moment()));
    upcoming.sort((a, b) => moment(a.start_at).diff(moment(b.start_at)));
    setUpcomingSessions(upcoming);
  };

  const onSessionStatusChange = (event) => {
    const value = event.target.value;
    setCompletedSessionsIndex(3);
    setUpcomingSessionsIndex(3);
    setSelectedSessionStatus(value);
  };

  const onLearnerIdChange = (event) => {
    const value = event.target.value;
    setCompletedSessionsIndex(3);
    setUpcomingSessionsIndex(3);
    setSelectedLearnerId(value);
  }

  const onLoadMoreClick = (status) => {
    if (status == abstractTimeRanges.UPCOMING) {
      setUpcomingSessionsIndex(upcomingSessionsIndex + 4);
    } else {
      setCompletedSessionsIndex(completedSessionsIndex + 4);
    }
  };

  const handlePrivateSessionCancel = () => {
    const cb = (error, response) => { // eslint-disable-line no-unused-vars
      const start = moment(sessionToCancel.start_at);
      const end = moment(sessionToCancel.end_at);
      const name = userType == 'teacher'
        ? `${sessionToCancel.lesson.student.first_name} ${sessionToCancel.lesson.student.last_name}`
        : `${sessionToCancel.teacher.privacy_protected_name}`;
      if (!error) {
        toast.success(
          <ToastNotificationsSuccess htmlContent={privateLessonCancelSuccess(name, start, end)}/>,
          { autoClose: 3200 }
        );
        let updatedPrivateSessions = privateSessions;
        if ((start > moment()) && start.diff(moment(), 'hours') < 24) {
          updatedPrivateSessions = updatedPrivateSessions.map((ps) => {
            if (ps.id == sessionToCancel.id) ps.status = 'cancelled';
            return ps
          });
        } else {
          updatedPrivateSessions = updatedPrivateSessions.filter(el => el.id !== sessionToCancel.id);
        }
        setSessionToCancel(null);
        setPrivateSessions(updatedPrivateSessions);
      } else {
        toast.error(
          <ToastNotificationsError
            htmlContent={privateLessonCancelError(name, start, end)}
            closeToast={() => {}}
          />,
          { autoClose: 3200 }
        );
        setSessionToCancel(null);
      }
    }
    if (userType == 'teacher')
      apiClient.deletePrivateSession(
        sessionToCancel.id,
        cb
      )
    else {
      apiClient.cancelPrivateSession(
        sessionToCancel.id,
        cb
      )
    }
  }

  const renderCancelModal = () => {
    if (sessionToCancel === null) {
      return
    }
    const start = moment(sessionToCancel.start_at);
    const end = moment(sessionToCancel.end_at);
    const name = userType == 'teacher'
      ? `${sessionToCancel.lesson.student.first_name} ${sessionToCancel.lesson.student.last_name}`
      : `${sessionToCancel.teacher.privacy_protected_name}`;
    const lessThan24Hours = (start > moment()) && start.diff(moment(), 'hours') < 24;
    return (
      <Modal
          overlayClassName='profile-modal-overlay'
          className='profile-modal keep-text-centered'
          isOpen={true}
          appElement={$('.ProfileApp').get(0)}
          onRequestClose={() => setSessionToCancel(null)}
          shouldCloseOnOverlayClick={true}
        >
          <div className='close' onClick={() => setSessionToCancel(null)}></div>
          { userType == 'teacher' ?
            (
              <div className='inner-content'>
                <h2>Are You Sure?</h2>
                <p>
                  We&apos;re sorry you have to cancel your <b className='force-uppercase'>Private Lesson</b> with&nbsp;
                  <b className='force-uppercase'>{name}</b> on&nbsp;
                  <b className='force-uppercase'>{start.format('ddd, MMM Do')}</b> from&nbsp;
                  <b className='force-uppercase'>{getTimeRangeString(start, end)}</b>.{!lessThan24Hours && ' Are you sure you want to cancel this lesson?'}
                </p>
              </div>
            ) :
            (
              <div className='inner-content'>
                <h2>Oh Man</h2>
                <p>
                  Are you sure you want to cancel your <b className='force-uppercase'>Private Lesson</b> with&nbsp;
                  <b className='force-uppercase'>{name}</b> on&nbsp;
                  <b className='force-uppercase'>{start.format('ddd, MMM Do')}</b> from&nbsp;
                  <b className='force-uppercase'>{getTimeRangeString(start, end)}</b>?
                </p>
              </div>
            )
          }

          { userType == 'student' && lessThan24Hours &&
            <p>
              Because you&apos;re cancelling this lesson less than <b className='force-uppercase'>24 hours</b> before&nbsp;
              the lesson begins, <b className='force-uppercase'>{end.diff(start, 'hours', true)} hour{end.diff(start, 'hours') > 1 ? 's' : ''}</b> will&nbsp;
              be deducted from your plan.
            </p>
          }
          { userType == 'student' && lessThan24Hours && <p>Are you sure you want to cancel this lesson?</p>}
          <div className='actions-wrapper'>
            <a onClick={() => setSessionToCancel(null)} href="#" className="btn inverted">Not Yet</a>
            <button onClick={() => handlePrivateSessionCancel()}>Yes</button>
          </div>
        </Modal>
    );
  }

  const renderSessionCards = (status) => {
    const sessions = status == abstractTimeRanges.UPCOMING ? upcomingSessions : completedSessions;
    const loadMoreIndex = status == abstractTimeRanges.UPCOMING ? upcomingSessionsIndex : completedSessionsIndex;

    return (
      <div className='session-cards'>
        {sessions.slice(0, loadMoreIndex + 1).map((session, index) => {
          return (
            <SessionCard
              key={index}
              session={session}
              state={moment(session.end_at).isAfter(moment())
                ? abstractTimeRanges.UPCOMING
                : abstractTimeRanges.COMPLETED
              }
              onCancel={() => setSessionToCancel(session)}
            />
          )
        })}
        {!sessions.length > 0 &&
          <EmptySessionsCard
            userType={userType}
          />
        }
        {loadMoreIndex < sessions.length - 1 &&
          <button
            className='black-gray-button large load-more-button'
            onClick={() => {onLoadMoreClick(status)}}
          >Load More</button>
        }
      </div>
    );
  }
  const wrapperClass = userType == 'teacher' ? 'TeacherMyLessonsView' : 'StudentMyLessonsView';

  return (
    <div className={wrapperClass}>
    <ToastContainer draggable={false} closeButton={false} />
      <h2 className="inverted">My Lessons</h2>
      <div className='filter-section'>
        <div className='filter-wrapper'>
          <div className='filter'>
            <select value={selectedSessionStatus ? selectedSessionStatus : ''} onChange={onSessionStatusChange}>
              <option value={abstractTimeRanges.UPCOMING}>Upcoming</option>
              <option value={abstractTimeRanges.ALL}>All</option>
              <option value={abstractTimeRanges.COMPLETED}>Completed</option>
            </select>
          </div>
          {window.user_type == 'student' &&
            <div className='filter'>
              <select value={selectedLearnerId ? selectedLearnerId : ''} onChange={onLearnerIdChange}>
                <option value="">All Learners</option>
                {learners.map((learner, index) => (
                  <option key={index} value={learner.id}>{learner.first_name}</option>
                ))}
              </select>
            </div>
          }
        </div>
        {isLoading && (
          <div className="loading-sessions-card">
            <span className="spinner"></span>
            <span className="loading-text">Loading lessons...</span>
          </div>
        )}

        {!isLoading && (
          <>
          {selectedSessionStatus == abstractTimeRanges.ALL || selectedSessionStatus == abstractTimeRanges.COMPLETED ?
            <>
              <h2>Completed</h2>
              {renderSessionCards(abstractTimeRanges.COMPLETED)}
            </>
          : ''}

          {selectedSessionStatus == abstractTimeRanges.ALL || selectedSessionStatus == abstractTimeRanges.UPCOMING ?
            <>
              <h2>Upcoming</h2>
              {renderSessionCards(abstractTimeRanges.UPCOMING)}
            </>
          : ''}

          {userType == 'teacher' && !privateSessions.length && !classroomSessions.length ?
            <TeacherCTA
              header='Want students to find you? Schedule your availability now!'
              linkURL={profileUrls.myCalendar}
              linkText='My Calendar'
            />
          : ''}
          </>
        )}
      </div>
      {renderCancelModal()}
   </div>
  );
}
