import PropTypes from 'prop-types'
import React from 'react'
import $ from 'jquery'


export default class QuestionMultiSelect extends React.Component {
    // Displays a word cloud of potiental answers and updates
    // a hidden multi-select element for form submission purposes
    static propTypes = {
        name: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        question: PropTypes.string.isRequired,
        selected_option: PropTypes.array,
        handleInputChange: PropTypes.func.isRequired,
        question_id: PropTypes.number,
    }

    constructor(props) {
        super(props)
        this.state = {
            selected: [],
        }
    }

    toggleSelected = evt => {
        // get the text from the clicked element in the visible word cloud
        let word = $(evt.target).text()

        // copy the current selected state array
        let selected = this.state.selected

        // check for the clicked word in the selected array
        // remove/unselect if it exists and append if it does not
        let index = selected.indexOf(word)
        if (index > -1) {
            selected.splice(index, 1)
        } else {
            selected.push(word)
        }

        // update the selected state and call the input change function
        this.setState({selected: selected})
        this.props.handleInputChange(this.props.question_id, this.state.selected)

        // toggle the 'selected' class on the word
        if (!$(evt.target)[0].className.includes('answered')) {
            $(evt.target).toggleClass('selected')
        }
    }

    render() {
        // render the visible word cloud of answers AND the hidden select input
        // NOTE: user input is handled via the word cloud
        return (
            <div className="quiz-form-group">
                <h4>{ this.props.question }</h4>
                <div className='form-multi-select-display'>
                    {this.props.options.map(opt => {
                        return (
                            <span className='multi-select-option'
                                  onClick={this.toggleSelected}
                                  key={opt.id} id={'multi-select-' + opt.id}
                                  value={opt.answer}>
                                {opt.answer}
                            </span>
                        )
                    })}
                </div>
                <select
                    name={this.props.name}
                    className='form-multi-select hide'
                    size={this.props.options.length}
                    multiple='multiple'
                    onChange={() => {}}
                    value={this.state.selected}
                    data-question-id={this.props.question_id}>
                    {this.props.options.map(opt => {
                        return (
                            <option key={opt.id} value={opt.answer}>{opt.answer}</option>
                        )
                    })}
                </select>
            </div>
        )
    }
}
