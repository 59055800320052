import $ from 'jquery';
import { feedbackUrl } from './constants.js';


export default function userFeedbackFormSubmit() {

  $('.user-feedback-form').submit(function(event) {
    event.preventDefault();
    const $form = $(this);
    const $message = $form.parent().find('.user-feedback-form-submitted');

    // disable to prevent duplicate submissions
    $form.find(':submit').prop('disabled', true);

    $.post({
      url: feedbackUrl,
      data: $form.serialize()
    }).done(() => {
      $form.remove();
      $('.user-feedback-form-errors').children().remove();
      $message.html('<h3>Thank you for your feedback!</h3>');
    }).fail(function(data) {
      let output = '';
      if (data.status === 500) {
        output = 'something went wrong';
      } else {
        let response = JSON.parse(data.responseText || {})
        let info = Object.keys(response);
        if (Array.isArray(info)) {
          info.forEach(function(key) {
            let message = response[key][0].message
            output += `<p>${message}</p>`
          });
        }
      }

      $('.user-feedback-form-errors').html(output);

      }).always(function() {
          $form.find(':submit').prop('disabled', false);
      })

      return false;
    });
}