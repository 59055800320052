export default function setTimer () {
    const slidesId = 'timed-slide'
    if (!document.getElementsByClassName(slidesId).length) {
        return false
    }

    const toggleSlide = () => {
        Array.from(document.getElementsByClassName(slidesId)).map(slide => {
            slide.classList.toggle('non-display')
        })
    }

    if (window.innerWidth > 900) {
        const interval = window.setTimeout(toggleSlide, 3000)
        const inputForm = document.getElementsByClassName('pdp-pricing-info-capture')[0]
        inputForm.addEventListener('click', () => {
            window.clearInterval(interval)
        })
    }

    const toggler = document.getElementsByClassName('return-to-waitlist')[0]
    toggler.addEventListener('click', toggleSlide)
}
