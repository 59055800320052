import $ from 'jquery';
import urls from '../constants.js';
import csrf from '../csrf.js';


csrf.setupAjax();


export function updateLineItem(li_data, callback=null) {
  $.ajax({
    method: 'POST',
    url: urls.updateLineItemUrl,
    data: JSON.stringify(li_data),
    dataType: 'json',
  }).done(response => {
    return callback != null ? callback(response) : response;
  }).fail(reason => {
    console.error(reason);
  });
}

export function removeLineItem(li_data, callback=null) {
  // remove a lineitem from an existing cart
  $.ajax({
    method: 'POST',
    url: urls.removeLineItemUrl,
    data: JSON.stringify(li_data),
    dataType: 'json',
  }).done(response => {
    return callback != null ? callback(response) : response;
  }).fail(reason => {
    console.error(reason);
  });
}

export function getLineItems(callback=null) {
  // get all lineitems in the existing cart
  $.getJSON(urls.getLineItemsUrl, (json_data) => {
    return callback != null ? callback(json_data) : json_data;
  }).fail((jaXHR, textStatus, error) => {
    console.error(textStatus, error);
    return {'error': error};
  });
}

export function getLineItemsWithLang(callback=null) {
  // get all lineitems in the existing cart with
  // the list of languges for the current location
  // Used in CartOverview for empty cart display
  $.getJSON(urls.getLineItemsWithLangUrl, json_data => {
    return callback != null ? callback(json_data) : json_data;
  }).fail((jaXHR, textStatus, error) => {
    console.error(textStatus, error);
    return {'error': error};
  });
}

export function getUser(callback=null) {
  $.getJSON(urls.getUserUrl, (json_data) => {
    return callback != null ? callback(json_data) : json_data;
  }).fail((jaXHR, textStatus, error) => {
    console.error(textStatus, error);
    return {'error': error};
  });
}

export function applyPromo(code, lineItems, callback_done=null, callback_fail=null) {
  // apply a promo code to the cart
  $.ajax({
    method: 'POST',
    url: urls.applyPromoUrl,
    data: {
      'code': code,
      'lineItems': JSON.stringify(lineItems)
    },
    dataType: 'json',
  }).done(response => {
    return callback_done != null ? callback_done(response) : response;
  }).fail(reason => {
    let errors = Object.values(reason.responseJSON);
    console.error(errors);
    return callback_fail != null ? callback_fail(errors) : errors;
  });
}

export function applyGiftCard(code, callback_done=null, callback_fail=null) {
  // apply a giftcard code to the cart
  $.ajax({
    method: 'POST',
    url: urls.redeemGiftcardUrl,
    data: JSON.stringify(code),
    dataType: 'json',
  }).done(response => {
    return callback_done != null ? callback_done(response) : response;
  }).fail(reason => {
    let errors = Object.values(reason.responseJSON);
    console.error(errors);
    return callback_fail != null ? callback_fail(errors) : errors;
  });
}

export function createOrder(order_data, callback_done=null, callback_fail=null) {
  // Submit the data on the cart overview page to the API endpoint
  // to trigger creating the order.
  $.ajax({
    method: 'POST',
    url: urls.createOrderUrl,
    data: JSON.stringify(order_data),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
  }).done(response => {
    return callback_done != null ? callback_done(response) : response;
  }).fail(reason => {
    let response = reason.responseJSON;
    let errors = Object.values(response);
    console.error(errors);
    return callback_fail != null ? callback_fail(response) : errors;
  })
}
