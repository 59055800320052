import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

const FormErrorMessage = (props) => {
  return <div className="form-error">{props.children}</div>;
}

FormErrorMessage.propTypes = {
  children: PropTypes.node.isRequired
};

function mergeSortedTimeArrays(a, b) {
  // Merge Sort algorithm for two sorted lists of Classroom/Private Sessions.
  // Returns a combined list of Classroom/Private Sessions
  // in ascending order by: start_at and end_at
  var answer = new Array(a.length + b.length), i = 0, j = 0, k = 0;
  while (i < a.length && j < b.length) {
    const aStart = moment(a[i].start_at);
    const bStart = moment(b[j].start_at);
    const aEnd = moment(a[i].end_at);
    const bEnd = moment(b[j].end_at);

    if (aStart < bStart) {
        answer[k] = a[i];
        i++;
    } else if (aStart > bStart) {
        answer[k] = b[j];
        j++;
    } else {
      if (aEnd < bEnd) {
        answer[k] = a[i];
        i++;
      } else if (aEnd > bEnd) {
        answer[k] = b[j];
        j++;
      } else {
        answer[k] = a[i];
        i++;
      }
    }
    k++;
  }
  while (i < a.length) {
    answer[k] = a[i];
    i++;
    k++;
  }
  while (j < b.length) {
    answer[k] = b[j];
    j++;
    k++;
  }
  return answer;
}

function listSorter(a, b) {
  // helper function for sorting a list Classroom/Private Sessions based on start_at and end_at
  const aStart = moment(a.start_at);
  const bStart = moment(b.start_at);
  const aEnd = moment(a.end_at);
  const bEnd = moment(b.end_at);
  if (aStart < bStart || aStart === bStart && aEnd < bEnd) {
    return -1
  }
  return 1;
}

function combineSessions(privateSessions, classroomSessions, inverted=false) {
  // take 0, 1 or 2 lists of sorted or unsorted Classroom/Private Sessions,
  // sort and combine them if more than 1 is provided using merge sort invert
  // them if requested (used for completed views where the order should be
  // descending rather than ascending). Return the combined
  // (if 2 lists provided) and sorted list of Classroom/Private Sessions
  if (privateSessions.length && classroomSessions.length) {
    let sessions = mergeSortedTimeArrays(
      privateSessions.sort(listSorter),
      classroomSessions.sort(listSorter)
    );
    return inverted ? sessions.reverse() : sessions;
  }
  if (privateSessions.length && !classroomSessions.length) {
    return inverted
      ? privateSessions.sort(listSorter).reverse()
      : privateSessions.sort(listSorter);
  }
  if (!privateSessions.length && classroomSessions.length) {
    return inverted
      ? classroomSessions.sort(listSorter).reverse()
      : classroomSessions.sort(listSorter);
  }
  return [];
}

function filterListForOverlappers(start, end, lst) {
  // given a start moment() and end moment() and a list (lst) of objects
  // containing start_at and end_at Dates return the filtered list
  // containing objects that overlap the start <-> end range
  return lst.filter(obj => (moment(obj.end_at) > start && moment(obj.start_at) < end));
}

function getTimeRangeString(start, end) {
  return `
    ${start.format('h')}${start.minutes() !== 0 ? `:${start.format('mm')}` : ''}${start.format('A') != end.format('A') ? start.format('A') : ''}
    -
    ${end.minutes() !== 0 ? end.format('h:mmA') : end.format('hA')}
  `;
}

function useWindowSize() {
  // react hook that allows for responsive state changes of a react component
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function shiftTimezone(date, fromTz, toTz) {
  // shift a datetime from one tz to another
  let shifted = moment(date).tz(fromTz).format();
  if (shifted.substr(-1) === 'Z') {
    shifted = shifted.slice(0, -1);
  } else {
    shifted = shifted.slice(0, -6);
  }
  return moment(shifted + moment.tz(shifted, toTz).format('Z')).toDate();
}

export {
  combineSessions,
  filterListForOverlappers,
  FormErrorMessage,
  getTimeRangeString,
  useWindowSize,
  shiftTimezone
}
