import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import FCApi from '../../../fc-api.js';
import LanguageCard from './LanguageCard.jsx';


const LanguageList = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [ languages, setLanguages ] = useState([]);
  const apiClient = new FCApi();

  useEffect(() => {
    handleSkip()
    loadLanguages();
  }, []);

  const handleSkip = () => {
    if (!location.search.includes('skip')) {
      return;
    }

    apiClient.v2ListPrivateSessions(
      (error, response) => {
        if (!error) {
          if (response.length == 0) {
            return;
          }

          let langSlug = response[0].language.slug;
          let teacherId = response[0].teacher;

          navigate(`${langSlug}/${teacherId}`);
        }
      },
      `expand=language,lesson&lesson=${props.lesson.id}&order_by=-id`
    );

  };

  const loadLanguages = () => {
    apiClient.getLanguages((error, response) => {
      if (!error) {
        setLanguages(response);
      }
    });
  };

  const onLanguageSelect = (language) => {
    navigate(language.slug);
  };

  let validLanguages = languages;
  if (props.lesson) {
    if (props.lesson.package && props.lesson.package.valid_languages && props.lesson.package.valid_languages.length > 0) {
      const validLanguageSlugs = props.lesson.package.valid_languages.map(language => language.slug);
      validLanguages = languages.filter((lang) => validLanguageSlugs.includes(lang.slug));
    }
  }

  return (
    <div className="language-selector">
      <div className="title">Select a Subject</div>
      {languages.length == 0 ?
        <div className="no-lessons">
        No subjects.
        </div>
      :
        <section className="languages-list">
          <div className="flag-list profile-list">
          {validLanguages.map((language, index) => (
            <div
              className={'language-selector-wrapper'}
              key={index}
              onClick={() => onLanguageSelect(language)}
            >
              <LanguageCard
                language={language}
                selectedLanguage={(props.lesson && props.lesson.language) && props.lesson.language.slug === language.slug}
              />
              <input
                type="radio"
                defaultChecked={false}
              />
            </div>
          ))}
          </div>
        </section>
      }
    </div>
  );
};

LanguageList.propTypes = {
  learner: PropTypes.object.isRequired,
  lesson: PropTypes.object
};

export default LanguageList;
