import PropTypes from 'prop-types'
import React from 'react'
import LineItem from './LineItem.jsx'


export default function LineItemList(props) {
    // Display component for LineItem lists
    return (
        <div className="LineItemList">
            {props.lineitems.map((line, index) => {
                return (
                    <LineItem
                        key={index}
                        id={index}
                        line={line}
                        allowEdits={props.allowEdits}
                        removeLine={props.removeLine}
                        incrementLine={props.incrementLine}
                        decrementLine={props.decrementLine}
                    />
                )
            })}
        </div>
    )
}

LineItemList.propTypes = {
    allowEdits: PropTypes.bool.isRequired, // true for CartOverview
    lineitems: PropTypes.array.isRequired,
    removeLine: PropTypes.func,
    incrementLine: PropTypes.func,
    decrementLine: PropTypes.func,
}
