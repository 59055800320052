import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const GroupProductModalDetails = ({ product }) => {
  if (!product) {
    return null;
  }

  return (
    <>
      <h3>{product.enrolled_in.template.name}{product.enrolled_in.teacher && <> - {product.enrolled_in.teacher.first_name} {product.enrolled_in.teacher.last_name[0]}.</>}</h3><br/>
      Start Date: {moment(product.enrolled_in.start_date).format('dddd, MMM Do, YYYY')}<br/>
      Start Time: {moment(product.enrolled_in.start_at).tz(product.enrolled_in.timezone).format('h:mma z')}<br/><br/>

      {product.enrolled_in.session_count} Session{product.enrolled_in.session_count != 1 && 's'}<br/>
      {product.enrolled_in.condensed_days}, {moment(product.enrolled_in.start_date).format('MMM Do')} - {moment(product.enrolled_in.end_date).format('MMM Do, YYYY')}<br/>
      {moment(product.enrolled_in.start_time, 'hh:mm:ss').format('h:mma')} - {moment(product.enrolled_in.end_at).tz(product.enrolled_in.timezone).format('h:mma z')}<br/>
      ONLINE<br/>
    </>
  );
};

GroupProductModalDetails.propTypes = {
  product: PropTypes.object
};

export default GroupProductModalDetails;
