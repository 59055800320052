import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { logoutUrl } from '../constants.js';

const MobileMenu = ({ routes }) => {
  let navigate = useNavigate();

  const onRouteChange = (e) => {
    let url = e.target.value;
    if (url == logoutUrl) {
      window.location.href = url;
    } else {
      navigate(e.target.value);
    }
  };

  return (
    <div className="MobileMenu">
      <select onChange={onRouteChange} >
        {routes.map((route, index) =>
          <option
            key={index}
            value={route.path}
          >{route.header}</option>
        )}
        <option value={logoutUrl}>Log Out</option>
      </select>
    </div>
  );
};

MobileMenu.propTypes = {
  routes: PropTypes.array.isRequired
};

export default MobileMenu;
