import PropTypes from 'prop-types'
import React from 'react'
import $ from 'jquery'
import Cookies from 'js-cookie';
import constants from '../constants.js'
import LeadForm from './forms/lead-form.jsx'
import QuizSuggestionPage from './quiz-suggestion-page.jsx'


export default class QuizResultsPage extends React.Component {

  static propTypes = {
    series_id: PropTypes.number.isRequired,
    language: PropTypes.string,
    level: PropTypes.string,
    result_title: PropTypes.string,
    result_tagline: PropTypes.string,
    total_answered: PropTypes.number,
    errors: PropTypes.object.isRequired,
    completed: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showResultsPage: false,
    }
  }

  componentDidMount() {
    window.dataLayer.push({'event': 'give_us_your_details'})
  }

  submitForm = callback => {
    // Submit form data to hubspot
    var url = `https://api.hsforms.com/submissions/v3/integration/submit/${window.hubspot_portal_id}/${window.hubspot_form_id}`;

    $.ajax({
      type: 'POST',
      url: url,
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify({
        fields: $('#quiz-lead-form').serializeArray(),
        context: {
          hutk: Cookies.get('hubspotutk')
        }}),
      dataType: 'json'
    });

    $.post({
      url: constants.salesLeadsUrl,
      data: $('#quiz-lead-form').serialize(),
    }).done(function () {
      this.setState({showResultsPage: true})
    }.bind(this)).fail(data => {
      // Returns obj with input fields as keys
      var errorObj = JSON.parse(data.responseText)
      var api_errors = []
      Object.keys(errorObj).map((key) => {
        Object.keys(errorObj[key]).map((index) => {
          api_errors.push(errorObj[key][index].message)
        })
      })
      return callback({
        submitSuccess: false,
        show_api_error: true,
        api_errors: api_errors,
        validation_errors: {},
        show_validation_error: false,
      })
    })
  }


  render() {
    if (this.state.showResultsPage) {
      return (
        <section className="quiz-box suggestion-wrapper">
          <QuizSuggestionPage
            series_id={this.props.series_id}
            total_answered={this.props.total_answered}
            errors={this.props.errors}
            completed={this.props.completed} />
        </section>
      )
    }
    return (
      <section className="quiz-box lead-capture-page">
        <div className='lead-capture-header'>
          <h2 className='quiz-title'>{this.props.result_title}</h2>
          <p>{this.props.result_tagline}</p>
        </div>
        <div className='lead-capture-content'>
          <h4>Fill out your information to see your results!</h4>
          <LeadForm
            submit_button_label={'SEE THE RESULTS'}
            submit_func={this.submitForm}
            form_location={'quiz-results-page'}
            email={{
              'included': true,
              'hidden': false,
              'value': ''}}
            language={{
              'included': true,
              'hidden': true,
              'value': this.props.language}}
            level={{
              'included': true,
              'hidden': true,
              'value': this.props.level}}
          />
        </div>
      </section>
    )
  }
}
