import {isJSONString} from '../util.js';


export default function displayErrors(errors, selected_div) {
  // Accepts json errors object and selected display div
  console.error(errors);

  let error_list = new Array();
  let errors_obj = isJSONString(errors) ? JSON.parse(errors) : {};

  // traverse the nested form errors json object
  // and create an array of error messages
  Object.keys(errors_obj).forEach((input_field) => {
    errors_obj[input_field].forEach(msg => {
      // edit the blank field response
      let field_msg = 'This field may not be blank.';
      let req_fields = 'Please complete all required fields.';
      error_list.push(msg == field_msg ? req_fields : msg);
    })
  });

  // remove duplicate error messages
  error_list = error_list.filter((msg, pos, arr) => {
    return arr.indexOf(msg) == pos;
  });

  // create error message elements
  let error_msgs = error_list.map(value => `<p>${value}</p>`);

  // clear and reset the error messages
  if (selected_div.hasClass('hide')) {
    selected_div.removeClass('hide');
  }

  selected_div.empty();
  selected_div.html(error_msgs.join(''));
}
