import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MyCoursesListView from './MyCoursesListView.jsx';
import MyCoursesDetailView from './MyCoursesDetailView.jsx';
import MyCoursesSessionEditView from './MyCoursesSessionEditView.jsx';

const MyCoursesView = () => {
  return (
    <div className="MyCoursesView">
      <Routes>
        <Route index element={<MyCoursesListView />} />
        <Route path=":courseId" element={<MyCoursesDetailView />} />
        <Route path=":courseId/sessions/:sessionId" element={<MyCoursesSessionEditView />} />
      </Routes>
    </div>
  );
};

export default MyCoursesView;
