import PropTypes from 'prop-types'
import React from 'react'
import CustomerReview from './customer-review.jsx'
import {getCustomerReviews} from '../api'


export default class CustomerReviewList extends React.Component {

    static propTypes = {
        enrollableId: PropTypes.number,
    }

    constructor(props) {
        super(props)
        this.state = {reviews: []}
    }

    componentDidMount() {
        // get the serialized reviews using the customer review api endpoint
        getCustomerReviews(this.props.enrollableId, data => {
            this.setState({reviews: data}, () => {
                if (Object.keys(this.state.reviews).length) {
                    document.getElementsByClassName('twitter-section')[0].style.display = 'none'
                }
            })
        })
        this.setStars()
        window.addEventListener('scroll', () => this.setStars())
    }

    setStars = () => {
        // set each star section's classname to be either full or empty depending
        // on the scroll position, creating the fill animation on scroll
        let stars = Array.from(document.getElementsByClassName('review-stars-fill-bar'))
        
        let classModifier = '';

        stars.map(starsection => {

            if (starsection.getBoundingClientRect().top < window.innerHeight)
                classModifier = '';
            else
                classModifier = 'unloaded-stars';

            starsection.className = 'review-stars-fill-bar full-stars '+classModifier;
            
        });
    }

    render() {
        if (this.state.reviews) {
            return (
                <section className='customer-reviews-list'>
                    {this.state.reviews.map((review, index) => {
                        return <CustomerReview
                            key={ index }
                            has_stars={ review.has_stars }
                            quote={ review.quote }
                            reviewerImage={ review.reviewer_image }
                            reviewerName={ review.reviewer_name }
                            source={ review.source }
                            sourceLogo={ review.source_logo }
                            stars={ parseFloat(review.stars) }
                        />
                    })}
                </section>
            )
        }
        return null
    }
}
