import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';

import { getTimeRangeString } from './utils.jsx';


const TeacherCalendarCard = ({ event }) => {
  const event_start = moment(event.start_at);
  const selectDates = {start: event_start, end: moment(event.end_at)};
  if ('type' in event && event.type == 'temp') {
    if ('hasOverlap' in event && event.hasOverlap == true) {
      return (
        <div className='temp-calendar-card calendar-card has-overlap' id="temp-event">
          <div className='card-column'>
            <span className="small">{getTimeRangeString(selectDates.start, selectDates.end)}</span>
            <span>Unschedule Availability</span>
          </div>
          <div className='card-row'>
            <img className='row-icon' src='/static/img/assets/account/calendar/trash-can.jpg' />
            <span>Remove</span>
          </div>
        </div>
      )
    }
    return (
      <div className='temp-calendar-card calendar-card' id="temp-event">
        <span className="small">{getTimeRangeString(selectDates.start, selectDates.end)}</span>
        <span>Schedule Availability</span>
      </div>
    )
  }
  if ('type' in event && event.type == 'availability') {
    return (
      <div className={`availability-segment calendar-card ${event_start.minutes() == 30 ? 'double-bottom-margin' : ''}`}></div>
    )
  }
  const flag_image = event.course
    ? event.course.template.category.language.flag_image
    : (event.language ? event.language.flag_image : null);
  if (event.lesson) {
    if (event.duration === 0.25) {
      return (
        <div className={event.status == 'complete' && !event.notes ? 'calendar-card inverted' : 'calendar-card'}>
          <div className='inner-content'></div>
        </div>
      )
    } else {
      return (
        <div className={event.status == 'complete' && !event.notes ? 'calendar-card inverted' : 'calendar-card'}>
          <div className='inner-content'>
            <span className='card-title session-title'>{event.lesson.learner.first_name} {event.lesson.learner.last_name}</span>
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className='calendar-card'>
        {flag_image &&
          <img className='flag-image' src={flag_image} />
        }
        <div className='inner-content'>
          <span className='event-type'>Group</span>
          <span className='card-title'>{event.course.template.name}</span>
          <img src='/static/img/assets/account/my_lessons/group-session-icon.jpg' />
        </div>
      </div>
    )
  }
};

TeacherCalendarCard.propTypes = {
  event: PropTypes.object.isRequired,
};

export default TeacherCalendarCard;
