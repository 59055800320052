import { connect } from 'formik';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Effect = ({ formik, onChange }) => {
    const ref = useRef(null);
    useEffect(() => {
        onChange(formik, ref.current);
        ref.current = formik;
    }, [formik]);
    return null;
};

Effect.propTypes = {
  formik: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default connect(Effect);
