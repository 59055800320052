import $ from 'jquery';
require('select2');

/**
 * This function implements the functionality for displaying images
 * in the options of the dropdown in the Choose Your Package section
 * of https://www.cricketelearning.com/online-language-tutors.
 */
export default function initPackageDropdown() {

    function formatOptions(state) {
        if (!state.id) {
            return state.text;
        }
        if (!state.disabled && state.element.dataset && state.element.dataset.iconUrl) {
            return $(`<img src="${state.element.dataset.iconUrl}"><span class="select-icon-text">${state.text}</span>`);
        } else {
            return state.text;
        }
    }

    $('.package-dropdown').select2({
        templateResult: formatOptions,
        templateSelection: formatOptions
    });
}