import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import TimezoneSelectField from './TimezoneSelectField.jsx';
import FCApi from '../fc-api.js';
import { FormErrorMessage } from './utils.jsx';


export default class ProfileView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: {}
    };
    this.apiClient = new FCApi();
  }

  componentDidMount() {
    this.apiClient.fetchProfile((error, response) => {
      this.setState({profile: response});
    });
  }

  render() {
    const { profile } = this.state;

    return (
      <div className="ProfileView">
        <Formik
          enableReinitialize={true}
          initialValues={{
            photo: '',
            timezone: 'America/Detroit',
            old_password: '',
            new_password: '',
            confirm_password: '',
            ...profile
          }}
          validationSchema={Yup.object().shape({
            'new_password': Yup.string()
              .when('old_password', {
                is: (val) => val,
                then: Yup.string().required('Please choose a new password.')
              }),
            'confirm_password': Yup.string()
              .when('old_password', {
                  is: (val) => val,
                  then: Yup.string().required('Please choose a new password.')
                    .oneOf([Yup.ref('new_password'), null], 'Passwords must match.')
                })
          })}
          onSubmit={(values, actions) => {
            actions.setStatus({});

            if (values['photo'] == '') {
              delete values['photo'];
            }

            this.apiClient.updateProfile(values, (error, response) => {
              if (error) {
                actions.setErrors(this.apiClient.formatErrors(response));
              } else {
                this.setState({profile: response});
                actions.setValues({
                  old_password: '',
                  new_password: '',
                  confirm_password: ''
                });
                actions.setStatus({ msg: 'Profile saved.' });
                if (values.hasOwnProperty('timezone')) {
                  location.reload();
                }
              }
              actions.setSubmitting(false);
            });
          }}
        >
          {({ values, status, isSubmitting, setFieldValue }) => (
            <Form>
              <h2 className="inverted">Featurable Profile</h2>

              <div className="help-text">
                Update your photo and bio in order to have your information
                featured on the Cricket eLearning website.
              </div>


              <label htmlFor="photo">Photo</label>
              <input id="photo" name="photo" type="file" onChange={(event) => {
                setFieldValue('photo', event.currentTarget.files[0]);
              }} />
              <ErrorMessage component={FormErrorMessage} name="photo" />

              {profile && profile.photo_url ?
                <img className="photo-preview" src={profile.photo_url} /> :
                ''
              }
              <label htmlFor="bio">Bio</label>
              <Field name="bio" component="textarea" />
              <div className="help-text">
                Share 3-4 sentences about your qualifications, expertise and
                why students should be excited to learn with you. Bios should
                be written in 3rd person e.g. “Joe started teaching in 2013...”
              </div>
              <ErrorMessage component={FormErrorMessage} name="bio" />

              <h2 className="inverted">Settings</h2>

              <label htmlFor="timezone">Timezone</label>
              <TimezoneSelectField
                name={'timezone'}
                disabled={isSubmitting}
                value={values.timezone}
                onChange={(option) => {
                  setFieldValue('timezone', option.value);
                }}
              />

              <h2 className="inverted">Change Password</h2>

              <label htmlFor="old_password">Old Password</label>
              <Field name="old_password" type="password" autoComplete="current-password" />
              <ErrorMessage component={FormErrorMessage} name="old_password" />

              <label htmlFor="new_password">New Password</label>
              <Field name="new_password" type="password" autoComplete="new-password" />
              <ErrorMessage component={FormErrorMessage} name="new_password" />

              <label htmlFor="confirm_password">Re-enter New Password</label>
              <Field name="confirm_password" type="password" autoComplete="new-password" />
              <ErrorMessage component={FormErrorMessage} name="confirm_password" />

              <div className="form-toolbar">
                {status ? <div className="form-status">{status.msg}</div> : ''}
                {isSubmitting ? <div className="submit-spinner spinner"></div> : ''}
                <input type="submit" id="submit" value="Save" disabled={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

}
