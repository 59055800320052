import PropTypes from 'prop-types'
import React from 'react'
import CourseSection from './course-section.jsx'


export default class CourseDisplay extends React.Component {

  static propTypes = {
    city: PropTypes.string,
    currentLevel: PropTypes.number,
    currentPage: PropTypes.number,
    language: PropTypes.object,
    tabShift: PropTypes.func,
    setPage: PropTypes.func,
    templates: PropTypes.array,
  }

  constructor (props) {
    super(props)
    this.state = {
      maxCardCount: this.getMaxCardCount(),
      initialWidth: window.innerWidth
    }
    this.tabShift = this.props.tabShift.bind(this);
    this.setPage = this.props.setPage.bind(this);
  }

  deducePageCount (templates, level) {
    // Figure out the current number of pages in the active section.
    const maxCards = this.state.maxCardCount
    var pageCount = Math.ceil(templates.filter(function (temp) {
      return (
        temp.level_group === ['beginner', 'intermediate', 'advanced'][level]
      )
    }.bind(this)).length / maxCards)
    return pageCount
  }

  getMaxCardCount () {
    // Determine the maximum number of cards per page based on screenwidth.
    var maxCardCount = Math.floor(window.innerWidth / 356)
    maxCardCount = maxCardCount > 0 ? maxCardCount : 1
    return maxCardCount
  }

  initTouchControls = () => {
    // Setup swipe controls for mobile devices.
    var section
    window.touchLog = {}
    section = document.getElementsByClassName('lang-course-details')[0]
    section.addEventListener('touchstart', this.touchStart, {passive: true})
    section.addEventListener('touchend', this.touchEnd, false)
  }

  setPage = index => {
    // Turn directly to a given page/
    var pageCount = this.deducePageCount(this.props.templates, this.props.currentLevel)
    if (index >= 0 && index < pageCount) {
      this.props.setPage(index);
    }
  }

  touchEnd = () => {
    // Fires when the user picks their finger up.
    window.touchLog.endX = event.changedTouches[0].screenX
    if (window.touchLog.endX < window.touchLog.startX - 30) {
      this.turnPage(1)
    } else if (window.touchLog.endX > window.touchLog.startX + 30) {
      this.turnPage(-1)
    }
  }

  touchStart = () => {
    // Fires when the user touches the screen.
    window.touchLog.startX = event.changedTouches[0].screenX
  }

  turnPage = direction => {
    // Move to the next or previous page:
    // 'direction' equals either 1 or -1.
    var newPage = this.props.currentPage + direction
    var pageCount = this.deducePageCount(this.props.templates, this.props.currentLevel)
    if (newPage >= pageCount) {
      newPage = 0
      this.props.tabShift(1)
    }
    if (newPage < 0) {
      let newPageCount = this.deducePageCount(this.props.templates, this.props.currentLevel-1);
      newPage = newPageCount - 1;
      this.props.tabShift(-1);
    }
    this.props.setPage(newPage);
  }

  updateDimensions = () => {
    // Update the number of pages based on screenwidth.
    if (this.state.initialWidth != window.innerWidth) {
      this.setState({
        maxCardCount: this.getMaxCardCount(),
        initialWidth: window.innerWidth
      })
      setTimeout(function () {
        var pageCount = this.deducePageCount(this.props.templates, this.props.currentLevel)
        this.props.setPage(this.state.currentPage < pageCount ? this.props.currentPage : pageCount - 1);
      }.bind(this), 200)
    }
  }

  componentDidMount () {
    this.initTouchControls()
    window.addEventListener('resize', this.updateDimensions)
  }

  render () {
    var pageCount = this.deducePageCount(this.props.templates, this.props.currentLevel)
    var pageCountArray = new Array(pageCount).fill(null)

    return (
      <div>
        <div
          className='course-slider'
          style={{
            transform: 'translateY(' + this.props.currentLevel * (-605) + 'px)',
          }}
          >
          {['beginner', 'intermediate', 'advanced'].map((level, index) => {
            return (
              <CourseSection
                level='beginner'
                courses={this.props.templates.filter(template => template.level_group === level)}
                city={this.props.city}
                currentPage={this.props.currentPage}
                maxCards={this.state.maxCardCount}
                key={level + index}
              />
            )
          })}
        </div>
        <div className='cardscroll-controls'>
          <div className='course-page-arrows'>
            <div className={(this.props.currentLevel === 0 && this.props.currentPage === 0) ?
                      'hidden' : 'arrow-container left-arrow-container'}
                 onClick={this.turnPage.bind(this, -1)}>
              <div className='course-page-arrow left-arrow'></div>
            </div>
            <div className={(this.props.currentLevel === 2 && this.props.currentPage === pageCount - 1) ?
                      'hidden' : 'arrow-container right-arrow-container'}
                 onClick={this.turnPage.bind(this, 1)}>
              <div className='course-page-arrow right-arrow'></div>
            </div>
          </div>
          <div className='course-card-bubbles'>
            {pageCountArray.map((nullValue, index) => {
              return (
                <div
                  key={'bubble-' + index}
                  className={this.props.currentPage === index ? 'active bubble': 'bubble'}
                  onClick={this.setPage.bind(null, index)}
                ></div>
              )
            })}
          </div>
          <div className='course-card-bubbles'></div>
        </div>
      </div>
    )
  }
}
