
var resizeManager = {
    functions: {},
    ids: [],
    append: function (f, id) {
        if (typeof f === 'function') {
            if (!this.ids.includes(id)) {
                this.ids.push(id)
                this.functions[id] = f
            }
        }
    },
    resize: function () {
        for (let i = 0 ; i < this.ids.length ; i++) {
            this.functions[this.ids[i]]()
        }
    }
}

export default function setupResizeReader () {
    window.resizeManager = resizeManager
    window.onresize = resizeManager.resize.bind(resizeManager)
}
