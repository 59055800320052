import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { ToastNotificationsError, ToastNotificationsSuccess } from '../notifications/ToastNotifications.jsx';

import FCApi from '../fc-api.js';
import AttachmentManager from '../general/AttachmentManager.jsx';
import ResourceCard from './generic/my_resources/ResourceCard.jsx';

const MyLessonsDetailView = () => {
  const { sessionId } = useParams();
  const backUrl = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
  const [ session, setSession ] = useState(null);
  const [ resources, setResources ] = useState([]);

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    apiClient.v2FetchPrivateSession(
      sessionId,
      (error, response) => {
        if (!error) {
          // Concatenate session resources and learning plan resources
          let sessionResources = response.resources;
          if (response.activity_collection && !response.activity_collection.is_teacher_facing) {
            sessionResources = [...sessionResources, ...response.learning_plan_resources.map(r => r.external_resource)];
          }

          setResources(sessionResources);
          setSession(response);
        }
      },
      'expand=language,teacher,student_attachments,teacher_attachments,resources.file,resources.media_type,learning_plan_resources.external_resource.file,learning_plan_resources.external_resource.media_type,activity_collection'
    );
  };

  if (!session) {
    return null;
  }

  const studentNotesEditable = !session.student_notes && session.status == 'scheduled';
  const initialValues = {
    student_notes: session.student_notes || ''
  };

  return (
    <div className="MyLessonsDetailView">
      <div className="my-lessons-header">
        <Link to={backUrl} className="back-link" href="#">&larr; Back to my lessons</Link>
      </div>
      <div className="my-lessons-detail-container">
        <h2 className="inverted">
          Lesson Details
        </h2>
        <div className="my-lessons-detail-container-inner">
          <h2>{session.language && session.language.name} lesson with {session.teacher.first_name} {session.teacher.last_name}</h2>
          <p>
            {moment(session.start_at).tz(window.user.timezone).format('dddd, MMMM Do YYYY, h:mma')}
            &nbsp;-&nbsp;
            {moment(session.end_at).tz(window.user.timezone).format('h:mma')}
          </p>

          {resources.length > 0 && <>
            <h2>Resources</h2>
            <div className="my-lessons-resources">
              {resources.map((res, index) => (
                <ResourceCard
                  key={index}
                  title={res.title}
                  desc={res.description}
                  url={res.url || res.file.url}
                  isVisible={!res.is_teacher_facing}
                />
              ))}
            </div>
          </>}

          <h2>Lesson Summary</h2>
          {session.notes ?
            <p>{session.notes}</p>
          :
            <p className="no-notes">
              Your teacher has not submitted a recap for this lesson. You will
              be notified by email as soon as the recap is available.
            </p>
          }

          <AttachmentManager
            initialAttachments={session.teacher_attachments}
            sessionId={sessionId}
            maxFiles={3}
            disabled={true}
            readOnly={true}
          />

          <h2>Student Notes</h2>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              const cb = (error) => {
                if (error) {
                  const msg = 'Unable to save session notes.';
                  toast.error(
                    <ToastNotificationsError closeToast={() => {}} htmlContent={msg} />,
                    { autoClose: 6400 }
                  );
                } else {
                  const msg = 'Session notes saved.';
                  toast.success(
                    <ToastNotificationsSuccess closeToast={() => {}} htmlContent={msg} />,
                    { autoClose: 3200 }
                  );
                  setSession({...session, ...values});
                }
                actions.setSubmitting(false);
              };

              apiClient.v2UpdatePrivateSession(session.id, values, cb);
            }}
          >
            {({ values, isSubmitting, handleSubmit }) => (
              <Form className="form-inline">
                <div className="edit-form">
                  <div className="form-group">
                    <div className="help-text">
                      Add notes for your tutor to check before your coming lesson.
                    </div>
                    <Field
                      name="student_notes"
                      component="textarea"
                      disabled={!studentNotesEditable}
                    />
                  </div>
                </div>

                <AttachmentManager
                  initialAttachments={session.student_attachments}
                  sessionId={sessionId}
                  maxFiles={3}
                  disabled={session.status !== 'scheduled'}
                  readOnly={false}
                />

                <div className="form-toolbar">
                  {isSubmitting ? <div className="submit-spinner spinner"></div> : ''}
                  <Link to={backUrl} className="cancel inverted">Go Back</Link>
                  {studentNotesEditable ?
                    <a href="#" onClick={handleSubmit} className={isSubmitting || !values.student_notes ? 'save disabled' : 'save'}>Save</a>
                    : ''
                  }
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MyLessonsDetailView;
