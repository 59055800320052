// List alphabetically.....
module.exports = {
  sessionTypes: {
    PRIVATESESSION: 'private_session',
    PRIVATEONLINESESSION: 'private_online_session',
    CLASSROOMSESSION: 'classroom_session'
  },
  abstractTimeRanges: {
    UPCOMING: 'upcoming',
    ALL: 'all',
    COMPLETED: 'completed'
  },
  sessionStates: {
    CANCELLED: 'cancelled',
  },
  navigateActions: {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
  },
  // see resources/constants.py for Python equivalent
  CEFRLevelLaymanEnum: {
    Beginner: 'beginner',
    High_Beginner: 'high_beginner',
    Intermediate: 'intermediate',
    High_Intermediate: 'high_intermediate',
    Advanced: 'advanced',
    Expert: 'expert',
  },
  CEFRLaymanLabels: [
    'Beginner',
    'High Beginner',
    'Intermediate',
    'High Intermediate',
    'Advanced',
    'Expert'
  ],
  CEFRLaymanDescription: {
    Beginner: `(Beginner) I can recognize familiar words and very basic phrases about myself or my family. I can ask and answer simple questions.`,
    High_Beginner: `(High Beginner) I can catch the main point in short, simple messages or announcements. I can handle short social exchanges, but I can't usually keep the conversation going myself.`,
    Intermediate: `(Intermediate) I can understand the main points in clear standard speech on familiar topics. I can communicate easily when traveling and I can have conversations about everyday topics.`,
    High_Intermediate: `(High Intermediate) I can understand extended speech or lectures, most TV news, and movies. I can take an active part in discussions, and express my views and opinions.`,
    Advanced: `(Advanced) I have no difficulty understanding any kind of spoken language. I can express myself fluently and spontaneously without having to search for expressions.`,
    Expert: '(Expert) I am 100% fluent.',
  },
  PrimaryMotivationEnum: {
    Work: 'work',
    Travel: 'travel',
    Family: 'family',
    New_Skill: 'new_skill',
    Other: 'other',
  },
  // Auth API
  loginUrl: '/account/login/',
  logoutUrl: '/account/logout',
  passwordResetUrl: '/rest-auth/password/reset/',
  passwordResetConfirmUrl: '/rest-auth/password/reset/confirm/',
  registerUrl: '/account/register_student/',

  // Versioned API URLs
  apiUrls: {
    v1: {
      defaultCard: '/v1/default-card',
      languages: (languageId=null) => (
        languageId ?
        `/v1/languages/${languageId}` :
        `/v1/languages`
      ),
      myProfile: '/v1/profiles/me',
      payments: '/v1/payments',
      privateLessonPackages: '/v1/private_lesson_packages',
      privateLessons: (lessonId=null) => (
        lessonId ?
        `/v1/private_lessons/${lessonId}` :
        '/v1/private_lessons'
      ),
      privateSessions: (lessonId=null) => (
        lessonId ?
        `/v1/private_lessons/${lessonId}/sessions` :
        `/v1/private_sessions`
      ),
      privateSessionCancel: (lessonId, sessionId) => (
        `/v1/private_lessons/${lessonId}/sessions/${sessionId}/cancel`
      ),
      teachers: (teacherId=null) => (
        teacherId ?
        `/v1/teachers/${teacherId}` :
        '/v1/teachers'
      ),
      teacherAvailabilities: (availabilityId=null) => (
        availabilityId ?
        `/v1/teacher_availabilities/${availabilityId}` :
        `/v1/teacher_availabilities`
      ),
      standalonePrivateSessions: (sessionId=null) => (
        sessionId ?
        `/v1/private_sessions/${sessionId}` :
        `/v1/private_sessions`
      ),
      subscriptions: (subscriptionId=null) => (
        subscriptionId ?
        `/v1/subscriptions/${subscriptionId}` :
        '/v1/subscriptions'
      ),
      skipDates: '/v1/skip_dates'
    },

    v2: {
      activityCollections: (collectionId=null) => (
        collectionId ?
        `/v2/activity_collections/${collectionId}` :
        `/v2/activity_collections`
      ),
      classroomSessions: (sessionId=null) => (
        sessionId ?
        `/v2/classroom_sessions/${sessionId}` :
        `/v2/classroom_sessions`
      ),
      classroomSessionAttachments: (sessionId, fileId=null) => (
        fileId ?
        `/v2/classroom_sessions/${sessionId}/attachments/${fileId}` :
        `/v2/classroom_sessions/${sessionId}/attachments`
      ),
      courses: (courseId=null) => (
        courseId ?
        `/v2/courses/${courseId}` :
        `/v2/courses`
      ),
      learners: (learnerId=null) => (
        learnerId ?
        `/v2/learners/${learnerId}` :
        `/v2/learners`
      ),
      students: (studentId=null) => (
        studentId ?
        `/v2/students/${studentId}` :
        `/v2/students`
      ),
      privateLessons: (lessonId=null) => (
        lessonId ?
        `/v2/private_lessons/${lessonId}` :
        `/v2/private_lessons`
      ),
      privateSessions: (sessionId=null) => (
        sessionId ?
        `/v2/private_sessions/${sessionId}` :
        `/v2/private_sessions`
      ),
      privateSessionAttachments: (sessionId, fileId=null) => (
        fileId ?
        `/v2/private_sessions/${sessionId}/attachments/${fileId}` :
        `/v2/private_sessions/${sessionId}/attachments`
      )
    }
  },

  // Cart/Order API
  addToCartUrl: '/api/cart/add',
  applyPromoUrl: '/api/apply-promo',
  createOrderUrl: '/order/create-order/',
  getPromoUrl: '/api/get-promo',
  getLineItemsUrl: '/api/get-lineitems',
  getLineItemsWithLangUrl: '/api/get-lines-with-lang',
  redeemGiftcardUrl: '/api/redeem-giftcard',
  redeemUserGiftcardUrl: '/api/redeem-user-giftcard/',
  removeLineItemUrl: '/api/cart/remove',
  updateLineItemUrl: '/api/cart/update',

  // Admin URLS
  redeemUserGiftcardAdminUrl: '/giftcard-autocomplete/',
  giftcardRecipientAdminUrl: '/giftcard-recipient-autocomplete/',

  // General API
  customerReviewsURL: '/customer-reviews/',
  languageListUrl: '/language_list_set/',
  locationListUrl: '/location_list_set/',
  packagesList: '/packages-list/',
  onlineLessonsHubContentList: '/online-lesson-hub-content-list/',
  privateLessonsPageContentList: '/private-lesson-page-content-list/',
  siteBannerListUrl: '/sitebanner-list/',
  salesLeadsUrl: '/sales-leads/',
  feedbackUrl: '/feedback/',
  templateByLangListUrl: '/enrollable-template-list/by_language/',
  templateListUrl: '/enrollable-template-list/',

  // Cart Views
  cartCheckoutUrl: '/order/checkout',
  cartCheckoutLearnersUrl: '/order/checkout/learners',
  cartCheckoutPaymentUrl: '/order/checkout/payment',

  // General Views
  courseDetailUrl: (categorySlug, productSlug) => (
    `/group-language-courses/${categorySlug}/${productSlug}`
  ),
  private_lessonURL: '/online-language-tutors',
  teach_with_usURL: '/teach-with-us/',
  online_slug: 'online',
  topOffUrls: {
    starter: '/private-lessons/extra-lesson-starter/',
    basic: '/private-lessons/extra-lesson-basic/',
    plus: '/private-lessons/extra-lesson-plus/',
    premium: '/private-lessons/extra-lesson-premium/'
  },
  genericTopOffUrl: '/private-lessons/top-off/',
  cricketTopOffUrl: 'http://cricketmedia.com/tutoring',
  cricketNlTopOffUrl: 'https://cricketmedia.com/neulingo/class-selection/',
  kidsPurchaseUrl: 'https://www.cricketelearning.com/online-language-classes-for-kids/',

  // Profiles
  profileUrls: {
    accountOverview: '/account/me',
    bookLessons: '/account/me/book-lessons',
    myAccount: '/account/me/myaccount',
    myCalendar: '/account/me/calendar',
    myLessons: '/account/me/my-lessons',
    myResources: '/account/me/my-resources',
    orders: '/account/me/orders',
    payroll: '/account/me/payroll',
    paymentInfo: '/account/me/payment',
    privateLessons: '/account/me/private-lessons',
    resources: '/resources',
    studentProfile: '/account/me/student',
    teacherPrivateLessons: '/account/me/privatelessons',
    myPrivateStudents: '/account/me/my-students',
    myCourses: '/account/me/my-courses'
  },

  // Quiz
  quizUrl: '/quizzes/',
  quizSeriesUrl: '/quiz-series/',
  quizScorePageUrl: '/assessment/score-page',
  quizScoreQuizUrl: '/assessment/score-quiz',
  quizSuggestUrl: '/assessment/suggest',
}
