import $ from 'jquery'

export default function testimonials() {
    if (!document.getElementById('testimonials-data')) {
      return;
    }

    let data = document.getElementById('testimonials-data').dataset.testimonials;
    data = JSON.parse(data);

    let picture = document.getElementById('testimonials-image');
    let index = 0;

    let currentQuote = document.getElementById('current-quote');
    let currentName = document.getElementById('current-name');
    let currentTitle = document.getElementById('current-title');
    let previousName = document.getElementById('previous-name');
    let previousTitle = document.getElementById('previous-title');
    let nextName = document.getElementById('next-name');
    let nextTitle = document.getElementById('next-title');

    function updateCarousel(index) {
        if (!currentQuote) {
          return;
        }

        currentQuote.innerHTML = data[index]['description'];
        currentName.innerHTML = data[index]['first_name'] + ' ' + data[index]['last_name_initial'] + '.';
        currentTitle.innerHTML = data[index]['title'];
        picture.src = data[index]['photo'];
        let previousIndex = (index === 0) ? data.length - 1 : index - 1;
        let nextIndex = (index === data.length - 1) ? 0 : index + 1;
        previousName.innerHTML = data[previousIndex]['first_name'] + ' ' + data[previousIndex]['last_name_initial'] + '.';
        previousTitle.innerHTML = data[previousIndex]['title'];
        nextName.innerHTML = data[nextIndex]['first_name'] + ' ' + data[nextIndex]['last_name_initial'] + '.';
        nextTitle.innerHTML = data[nextIndex]['title'];

    }

    if (data.length < 6) {
        console.log('Error: there must be at least six testimonials in the data set.')
    } else {
        updateCarousel(0);
    }

    $('#testimonials-next').on('click', () => {
        index++;
        if (index === data.length) {
            index = 0;
        }
        updateCarousel(index);
    })

    $('#testimonials-previous').on('click', () => {
        index--;
        if (index === -1) {
            index = data.length - 1;
        }
        updateCarousel(index);
    })



}
