import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import OrderHistoryView from './OrderHistoryView.jsx';
import SubscriptionView from './SubscriptionView.jsx';
import UpdateCardView from './UpdateCardView.jsx';

const stripePromise = loadStripe(document.STRIPE_PUBLIC_KEY);

const PaymentInfoView = () => {
  return (
    <Elements stripe={stripePromise}>
      <UpdateCardView />
      <SubscriptionView />
      <OrderHistoryView />
    </Elements>
  );
};

export default PaymentInfoView;
