import PropTypes from 'prop-types'
import React from 'react'

// TODO: get rid of this whole file

var settings = {
    bar: {
        height: 14,
        margin: {
            sides: 140,
            top: 80,
        },
        numbers: true,
    },
    caption: {
        visible: true,
        offset: {
            x: 0,
            y: -8,
        },
    },
    copy: {
        stageCaption: 'STAGE',
        stageTitle: 'STAGE',
    },
    frameRate: 40,
    title: {
        offset: {
            x: 0,
            y: 28,
        },
    },
}


var animationUtil = {
    animateStrip: function (img, x, y, xScale, yScale, frameCount,
                            frameRate, backgroundFnc, onEnd, loop, canvas, ctx) {
        // Takes an image composed of regularly spaced frames
        // and animates it on a given HTML5 Canvas element
        var frameWidth = img.width / frameCount
        var frameIndex = 0
        this.animationInterval = window.setInterval (function (canvas, ctx, frameWidth, x, y, xScale, yScale, img, backgroundFnc) {
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            if (backgroundFnc) { backgroundFnc() }
            ctx.drawImage(img, frameIndex * frameWidth, 0, frameWidth, frameWidth, x, y, xScale, yScale)
            frameIndex += 1
            if (frameIndex > frameCount) {
                if (onEnd) { onEnd() }
                if (loop) {
                    frameIndex = 0
                } else {
                    clearInterval(this.animationInterval)
                }
            }
        }.bind(this, canvas, ctx, frameWidth, x, y, xScale, yScale, img, backgroundFnc), frameRate)
    },

    animateRectangle: function (canvas, ctx, _from, to, time, frameLength,
                                color, backgroundFnc, callback) {
        // Smoothly animates a rectangle on an HTML5 Canvas element from a
        // given starting position and size to a given ending position and
        // size, the objects '_from' and 'to' have the following form:
        // {
        //     x: 0,
        //     y: 0,
        //     width: 1,
        //     height: 1,
        // }
        var frame = 0
        var frames = time / frameLength
        var height = _from.height
        var increments
        var interval
        var rate
        var width = _from.width
        var x = _from.x
        var y = _from.y

        increments = {
            x: (to.x - _from.x) / frames,
            y: (to.y - _from.y) / frames,
            width: (to.width - _from.width) / frames,
            height: (to.height - _from.height) / frames,
        }

        var intervalFunction = function () {
            rate = (.5 - Math.abs((frame/frames) - .5)) * 4
            x += increments.x * rate
            y += increments.y * rate
            width += increments.width * rate
            height += increments.height * rate

            if (backgroundFnc) { backgroundFnc() }

            ctx.fillStyle = color
            ctx.fillRect(x, y, width, height)

            frame ++
            if (frame >= frames) {
                window.clearInterval(interval)
                ctx.fillRect(to.x, to.y, to.width, to.height)
                if (callback) { callback() }
            }
        }.bind(this)

        interval = window.setInterval(intervalFunction, frameLength)
    },
}


export default class ProgressBar extends React.Component {

    static propTypes = {
        currentQuizIndex: PropTypes.number,
        quizPage: PropTypes.number,
        quizPageCount: PropTypes.number,
        quizPercent: PropTypes.number,
        visualSettings: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.transitioning = false
        this.settings = settings
        this.bar = this.settings.bar

        this.animateStrip = animationUtil.animateStrip.bind(this)
        this.animateRectangle = animationUtil.animateRectangle.bind(this)
        window.addEventListener('resize', this.normalizeAspectRatio)
    }

    componentDidMount() {
        this.normalizeAspectRatio()
        this.drawProgressBar()
    }

    componentDidUpdate(previousProps) {
        // Check the differences between the new and old props and
        // run the appropriate animation.
        var canvas = document.getElementsByClassName('progress-bar-container')[0]
        var ctx = canvas.getContext('2d')
        if (previousProps.currentQuizIndex !== this.props.currentQuizIndex) {
            // If moved to a new quiz within the series:
            this.advanceThroughSeries(canvas, ctx, previousProps)
        } else if (previousProps.quizPercent !== this.props.quizPercent) {
            // If moved to a new page within the quiz:
            this.advanceThroughQuiz(canvas, ctx, previousProps)
        }
    }

    advanceThroughQuiz = (canvas, ctx, previousProps) => {
        // Progress within the quiz from one page to the next.
        // Animates the progress bar to its new position.
        this.animateRectangle(canvas, ctx,
            {   x: this.bar.margin.sides,
                y: this.bar.margin.top,
                width: ((canvas.width - this.bar.margin.sides * 2) * previousProps.quizPercent / 100),
                height: this.bar.height,
            }, {
                x: this.bar.margin.sides,
                y: this.bar.margin.top,
                width: ((canvas.width - this.bar.margin.sides * 2) * this.props.quizPercent / 100),
                height: this.bar.height,
            }, 300, this.settings.frameRate, '#000', null, this.drawProgressBar
        )
    }

    advanceThroughSeries = (canvas, ctx) => {
        // Progress within the series from one quiz to the next.
        // Runs the Level Transition animation.
        var icon
        var iconAppear
        var iconVanish
        var level = this.props.currentQuizIndex
        if (this.transitioning) { return false }
        this.transitioning = true

        icon = document.createElement('img')
        icon.src = '/static/img/assets/quiz/check-icon.png'
        iconAppear = document.createElement('img')
        iconAppear.src = '/static/img/assets/quiz/check-icon-appear.png'
        iconVanish = document.createElement('img')
        iconVanish.src = '/static/img/assets/quiz/check-icon-pop.png'

        // First, run the animateStrip function to have the check icon appear
        iconAppear.onload = this.animateStrip.bind(
            this, iconAppear, canvas.width - canvas.height - 26, -29, 192, 192, 5, this.settings.frameRate, this.drawProgressBar,
            function (level, canvas, ctx, icon, iconAppear, iconVanish) {
                this.drawProgressBar(canvas, ctx)
                // On animation finish, run animateReveal to show the Title
                this.animateReveal(
                    level, this.drawTitle.bind(
                        this, level, canvas, ctx
                    ), this.drawCheckIcon.bind(
                        this, icon, canvas, ctx
                    ), canvas, ctx, this.animateStrip.bind( this,
                        iconVanish, -36, -37, 192, 192, 7, this.settings.frameRate,
                        function (drawBackground) {
                            this.transitioning = false
                            this.drawProgressBar(canvas, ctx)
                            drawBackground()
                        }.bind(this, this.drawTitle.bind(this, level, canvas, ctx)),
                        this.fadeOutTitle.bind(
                            this, this.drawTitle.bind(this, level, canvas, ctx), 12, canvas, ctx
                        ), false, canvas, ctx
                    )
                )
            }.bind(this, level, canvas, ctx, icon, iconAppear, iconVanish),
            false, canvas, ctx
        )
    }

    animateReveal = (level, drawBackground, drawForeground, canvas, ctx, callback) => {
        // Runs an animation on a given HTML5 Canvas element in which a foreground
        // image slides across the canvas to reveal a background image.
        var iconIndex = 1,
            index = 1,
            interval,
            intervalFunction;

        intervalFunction = function (drawBackground, drawForeground, ctx, canvas) {
            var speed = 9
            var maxIndex = canvas.width - 60
            if (index < maxIndex) {
                ctx.save()
                ctx.beginPath()
                ctx.rect(840 - index, 0, canvas.width + 32, canvas.height)
                ctx.clip()
                drawBackground()
                ctx.restore()
                speed = 32 * (((maxIndex / 2) - Math.abs((maxIndex / 2) - index)) / (maxIndex / 2)) + 1
                iconIndex = index < maxIndex - 62 ? index : maxIndex - 62
                drawForeground(canvas.width - 164 - iconIndex)
            } else {
                if (callback) { callback() }
                clearInterval(interval)
            }
            index += speed
        }.bind(this, drawBackground, drawForeground, ctx, canvas)
        intervalFunction()
        interval = setInterval(intervalFunction, 10)
    }

    drawCheckIcon = (icon, canvas, ctx, x) => {
        // Draw the given icon vertically centered on the canvas.
        ctx.drawImage(
            icon,
            x,
            - canvas.height * .3,
            (canvas.height * 1.6),
            (canvas.height * 1.6),
        )
    }

    drawProgressBar = (canvas, ctx, quizPercent) => {
        // Draw the empty outline of the progress bar and its fill based on props.
        var bar = {}
        canvas = canvas || document.getElementsByClassName('progress-bar-container')[0]
        ctx = ctx || canvas.getContext('2d')
        quizPercent = quizPercent || this.props.quizPercent

        // Clear canvas.
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Draw empty progress container.
        ctx.fillStyle = '#fff'
        ctx.strokeStyle = '#000'
        ctx.lineWidth = 6

        bar.x = this.bar.margin.sides
        bar.y = this.bar.margin.top
        bar.width = canvas.width - this.bar.margin.sides * 2

        ctx.beginPath()
        ctx.moveTo(bar.x + 7, bar.y)
        ctx.lineTo(bar.x + bar.width - 7, bar.y)
        ctx.quadraticCurveTo(bar.x + bar.width, bar.y, bar.x + bar.width, bar.y + 7)
        ctx.quadraticCurveTo(bar.x + bar.width, bar.y + this.bar.height, bar.x + bar.width - 7, bar.y + this.bar.height)
        ctx.lineTo(bar.x + 7, bar.y + this.bar.height)
        ctx.quadraticCurveTo(bar.x, bar.y + this.bar.height, bar.x, bar.y + this.bar.height - 7)
        ctx.quadraticCurveTo(bar.x, bar.y, bar.x + 7, bar.y)
        ctx.closePath()
        ctx.fill()
        ctx.stroke()

        // Fill quiz bar.
        ctx.fillStyle = '#000'
        ctx.fillRect(0 + this.bar.margin.sides, this.bar.margin.top, (canvas.width - this.bar.margin.sides * 2) * (quizPercent / 100), this.bar.height)
        ctx.textAlign = 'center'
        if (this.bar.numbers) {
            ctx.font = 'bold 30px MaisonNeue'
            ctx.fillStyle = '#000'
            ctx.fillText(this.props.quizPage, this.bar.margin.sides - 36, canvas.height / 2 + this.settings.title.offset.y + 3)
            ctx.fillText(this.props.quizPageCount, canvas.width - this.bar.margin.sides + 36, canvas.height / 2 + this.settings.title.offset.y + 3)
        }

        // Write series caption.
        if (this.settings.caption.visible) {
            ctx.font = 'bold 30px MaisonNeue'
            ctx.fillStyle = '#000'
            ctx.fillText(
                (this.settings.copy.stageCaption + ' ' +
                    (this.props.currentQuizIndex + 1)).split('').join(String.fromCharCode(8202)),
                canvas.width / 2 + this.settings.caption.offset.x,
                canvas.height / 2 + this.settings.caption.offset.y
            )
        }
    }

    drawTitle = (level, canvas, ctx) => {
        // Draw the title text that shows between Quizzes
        var settings = this.settings.title
        var backgroundColor = '#00837C'
        var fontColor = '#000'
        ctx.font = '70px BureauGrotCondensed'
        ctx.textAlign = 'center'
        ctx.fillStyle = backgroundColor
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = fontColor
        ctx.fillText(this.settings.copy.stageTitle + ' ' + (level + 1), canvas.width / 2 + settings.offset.x, canvas.height / 2 + settings.offset.y)
    }

    fadeOutTitle = (drawTitle, fadeDuration, canvas, ctx) => {
        // Fade out the Title drawing
        setTimeout(function () {
            var index = fadeDuration
            var interval = setInterval(function () {
                if (index < 0) {
                    clearInterval(interval)
                } else {
                    ctx.clearRect(0, 0, canvas.width, canvas.height)
                    this.drawProgressBar(canvas, ctx)
                    ctx.globalAlpha = index / fadeDuration
                    this.drawTitle(this.props.currentQuizIndex, canvas, ctx)
                    ctx.globalAlpha = 1
                    index--
                }
            }.bind(this), 12)
        }.bind(this), 500)
        this.drawProgressBar(canvas, ctx)
        drawTitle()
    }

    normalizeAspectRatio = () => {
        var canvas = document.getElementsByClassName('progress-bar-container')[0]
        if (canvas) {
            var height = canvas.getBoundingClientRect().width * canvas.height/canvas.width
            canvas.style.height = height + 'px'
        }
    }

    render() {
        return (
            <canvas className='progress-bar-container' width='900' height='132'></canvas>
        )
    }
}
