import $ from 'jquery'
import urls from '../constants.js'


export function getQuizSeries(series_id=null, callback=null) {
    // When no series_id is passed in then this function will return the list
    // of all QuizSeries that we offer. When a series_id is provided we return
    // the data for a that particular QuizSeries.
    let url = series_id ? urls.quizSeriesUrl + series_id : urls.quizSeriesUrl
    $.getJSON(url, (json_data) => {
        return callback != null ? callback(json_data) : json_data
    }).fail((jaXHR, textStatus, error) => {
        console.error(textStatus, error)
        return {'error': error}
    })
}


export function getQuizData(quiz_id=null, callback=null) {
    // Given a unique Quiz.id make an api request to
    // the python endpoint and return the JSON data.
    if (quiz_id != null) {
        $.getJSON(`${urls.quizUrl}${quiz_id}`, (json_data) => {
            return callback != null ? callback(json_data) : json_data
        }).fail((jaXHR, textStatus, error) => {
            console.error(textStatus, error)
            return {'error': error}
        })
    }
    return {}
}

export function scorePage(data, callback) {
    // score all questions on a single page
    $.ajax({
        url: urls.quizScorePageUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data)
    }).done(response => {
        return callback(response)
    }).fail((reason) => {
        console.error(reason)
        return callback(reason)
    })
}

export function scoreQuiz(quiz_id, errors, callback) {
    // grade and update the quiz score
    $.ajax({
        url: urls.quizScoreQuizUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({quiz_id: quiz_id, errors: errors})
    }).done(response => {
        return callback(response)
    }).fail((reason) => {
        console.error(reason)
        return callback(reason)
    })
}

export function getCourseSuggestions(data, callback) {
    // given the score results of a particular quiz return s list
    // of courses that best represents their education level
    $.ajax({
        url: urls.quizSuggestUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data)
    }).done(response => {
        return callback(response)
    }).fail((reason) => {
        console.error(reason)
        return callback(reason)
    })

}
