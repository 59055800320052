
export default function autoScrollTo (targetElementClass, bufferElementClass, scrollSpeed = 4) {
    // Scroll vertically to the given target element.
    // targetElement should not be a React Component, use container element if scrolling to React Component.
    var targetElement = document.getElementsByClassName(targetElementClass)[0],
        targetY = 0,
        direction,
        controller,
        bufferElement;

    if (targetElement) {
        targetY = targetElement.getBoundingClientRect().top + window.pageYOffset
        bufferElement = bufferElementClass ? document.getElementsByClassName(bufferElementClass)[0] : false
        targetY -= bufferElement ? (bufferElement.getBoundingClientRect().height) : 0
    } else {
        return false
    }

    direction = targetY > window.pageYOffset ? 1 : -1
    controller = {
        accel: 0, index: 0, interval: null,
        midpoint: (targetY + pageYOffset) / 2, speed: 0, target: targetY,
    }
    window.addEventListener('wheel', () => {
        window.clearInterval(controller.interval)
    })

    controller.interval = window.setInterval(function () {
        this.accel = (
            (direction === 1 && window.pageYOffset < this.midpoint) ||
            (direction === -1 && window.pageYOffset > this.midpoint)
        ) ? scrollSpeed : -scrollSpeed
        this.index ++
        this.speed += this.accel
        this.speed = Math.max(this.speed, 4)
        if (
            ((direction === 1 && window.pageYOffset + this.speed > this.target) ||
            (direction === -1 && window.pageYOffset + this.speed < this.target)) || this.index > 120
        ) {
            window.clearInterval(this.interval)
            window.scrollTo(0, targetY)
            return false
        }

        if (targetElement) {
            targetY = targetElement.getBoundingClientRect().top + window.pageYOffset
            targetY -= bufferElement ? (bufferElement.getBoundingClientRect().height) : 0
        }
        if (
            (direction > 0 && window.pageYOffset + this.speed <= targetY) ||
            (direction < 0 && window.pageYOffset + this.speed >= targetY)
        ) {
            window.scrollTo(0, window.pageYOffset + this.speed * direction)
        }

    }.bind(controller), 12)
}
