import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MyStudentsListView from './MyStudentsListView.jsx';
import MyStudentsDetailView from './MyStudentsDetailView.jsx';
import MyStudentsSessionEditView from './MyStudentsSessionEditView.jsx';

const MyStudentsView = () => {
  return (
    <div className="MyStudentsView">
      <Routes>
        <Route index element={<MyStudentsListView />} />
        <Route path=":studentId" element={<MyStudentsDetailView />} />
        <Route path=":studentId/sessions/:sessionId" element={<MyStudentsSessionEditView />} />
        <Route path=":studentId/:lessonId/add-session" element={<MyStudentsSessionEditView />} />
      </Routes>
    </div>
  );
}

export default MyStudentsView;
