import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const USER_TIMEZONE = window.user_timezone ? window.user_timezone : 'America/Detroit';
moment.tz.setDefault(USER_TIMEZONE);

const LearnerCard = ({ learner }) => {
  return (
    <Link to={`${learner.id}`} className="learner-card">
      <div className="learner-card-left">
        <strong>{learner.first_name} {learner.last_name}</strong>
      </div>
      <div className="learner-card-right">
        More Info &#8594;
      </div>
    </Link>
  );
};

LearnerCard.propTypes = {
  learner: PropTypes.object.isRequired
};

export default LearnerCard;
