import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import FCApi from '../../../fc-api.js';
import AttachmentManager from '../../../general/AttachmentManager.jsx';
import Effect from '../../Effect.jsx';
import { shiftTimezone, FormErrorMessage } from '../../utils.jsx';
import DatePickerField from '../../DatePickerField.jsx';
import TimePickerField from '../../TimePickerField.jsx';
import { ToastNotificationsError } from '../../../notifications/ToastNotifications.jsx';
import ResourceCard from '../../generic/my_resources/ResourceCard.jsx';

const modalStyle = {
  content: {
    margin: '15% auto',
    padding: '20px',
    border: '1px solid black',
    maxWidth: '750px',
    borderRadius: '0'
  }
};

const MyStudentsSessionEditView = () => {
  const { studentId, lessonId, sessionId } = useParams();
  const navigate = useNavigate();

  const [ session, setSession ] = useState(null);
  const [ resources, setResources ] = useState([]);
  const [ learner, setLearner ] = useState(null);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);

  const apiClient = new FCApi();

  useEffect(() => {
    refreshSession();
  }, [sessionId]);

  useEffect(() => {
    refreshLearner();
  }, [studentId]);

  const refreshSession = () => {
    if (!sessionId) {
      return;
    }

    apiClient.v2FetchPrivateSession(
      sessionId,
      (error, response) => {
        if (!error) {
          // Concatenate session resources and learning plan resources
          setResources([...response.resources, ...response.learning_plan_resources.map(r => r.external_resource)]);
          setSession(response);
        }
      },
      'expand=language,teacher,student_attachments,teacher_attachments,resources.file,resources.media_type,learning_plan_resources.external_resource.file,learning_plan_resources.external_resource.media_type'
    );
  };

  const refreshLearner = () => {
    apiClient.v2FetchLearner(
      studentId,
      (error, response) => {
        if (!error) {
          setLearner(response);
        }
      }
    );
  };

  const openModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  const deleteSession = (e) => {
    e.preventDefault();
    setIsDeleting(true);

    const cb = (error) => {
      if (!error) {
        navigate('../../', {replace: true, relative: 'path'});
      }
      setIsDeleting(false);
    };

    apiClient.deletePrivateSession(sessionId, cb);
  };

  const globalFormError = (errors) => {
    // Raise form errors that are not field specific to a particular field.
    let message = errors.non_field_errors[0].props.children[0];
    toast.error(
      <ToastNotificationsError closeToast={() => {}} htmlContent={message} />,
      { autoClose: 6400 }
    );
  };

  const now = moment().tz(window.user.timezone);

  let datesEditable = !session || moment(session.start_at).add(24, 'hours').isAfter(moment());
  let summaryEditable = true;
  let canCancel = false;
  let initialValues = {
    'start_date': new Date(),
    'start_time': '00:00',
    'end_time': '00:00',
    'repeats_weekly': []
  };

  if (session) {
    const startAt = moment(session.start_at).tz(window.user.timezone);
    const endAt = moment(session.end_at).tz(window.user.timezone);

    initialValues.start_date = startAt.format('YYYY/MM/DD');
    initialValues.start_time = startAt.format('HH:mm');
    initialValues.end_time = endAt.format('HH:mm');
    initialValues.notes = session.notes;

    // disable date fields if the session is in the past
    datesEditable = datesEditable && (now < startAt) && session.teacher.id == window.user.id;
    summaryEditable = now > startAt && session.teacher.id == window.user.id;
    canCancel = session.status !== 'cancelled' && session.status !==  'complete' && session.teacher.id == window.user.id;
  }

  if ((sessionId && !session) || !learner) {
    return null;
  }

  return (
    <div className="PrivateLessonSessionEditView">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          'start_date': Yup.string().required('Please choose a start date.'),
          'start_time': Yup.string().required('Please choose a start time.'),
          'end_time': Yup.string().required('Please choose an end time.')
        })}
        onSubmit={(values, actions) => {
          const cb = (error, response) => {
            if (error) {
              let errors = apiClient.formatErrors(response);
              // translate start_at/end_at errors into start_time/end_time
              if (errors.start_at) { errors.start_time = errors.start_at; }
              if (errors.end_at) { errors.end_time = errors.end_at; }
              if ('non_field_errors' in errors) {
                globalFormError(errors);
              } else {
                actions.setErrors(errors);
              }
            } else {
              navigate('../../', {replace: true, relative: 'path'});
            }
            actions.setSubmitting(false);
          };

          // translate start_date/start_time/end_time into start_at/end_at
          let start_date = values.start_date && moment(shiftTimezone(values.start_date, moment.tz.guess(), window.user.timezone)).format('YYYY-MM-DD');
          if (start_date) {
            values.start_at = moment.tz(`${start_date} ${values.start_time}`, window.user.timezone);
            values.end_at = moment.tz(`${start_date} ${values.end_time}`, window.user.timezone);
          }

          // handle recurring session inputs
          if (values.repeat_until_date) {
            values.repeat_until_date = moment(values.repeat_until_date).format('YYYY-MM-DD');
          }

          if (session) {
            if (!datesEditable) {
              delete values.start_at;
              delete values.end_at;
            }
            apiClient.updatePrivateSession(session.id, values, cb);
          } else {
            apiClient.createStudentPrivateSession(values, cb, `private_lesson_pk=${lessonId}`);
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
          <Form className="form-inline">
            <Effect onChange={(nextState, currentState) => {
              // Handle fields related to recurring sessions
              if (!nextState || !currentState) {
                return;
              }

              if (!nextState.values.repeats_weekly.includes('yes')) {
                if (nextState.values.ends_type) {
                  delete nextState.values.ends_type;
                }
                if (nextState.values.repeat_until_date) {
                  delete nextState.values.repeat_until_date;
                }
                if (nextState.values.repeat_until_count) {
                  delete nextState.values.repeat_until_count;
                }
              }

              if (
                nextState.values.repeat_until_count &&
                currentState.values.repeat_until_count != nextState.values.repeat_until_count
              ) {
                setFieldValue('ends_type', 'after_count');
                delete nextState.values.repeat_until_date;
              }

              if (
                nextState.values.repeat_until_date &&
                currentState.values.repeat_until_date != nextState.values.repeat_until_date
              ) {
                setFieldValue('ends_type', 'on_date');
                delete nextState.values.repeat_until_count;
              }
            }} />

            <h2 className="inverted">{session ? 'Lesson Details' : 'Add Lesson'}</h2>

            <div className="edit-form">
              <div className="form-group student-name">
                <label><span>Student</span></label>
                <span>{learner ? `${learner.first_name} ${learner.last_name}` : ''}</span>
              </div>
              {session ? (
                <div className="form-group student-name">
                  <label><span>Teacher</span></label>
                  <span>{`${session.teacher.first_name} ${session.teacher.last_name}`}</span>
                </div>
              ) : ''}

              <div className="form-group">
                <label htmlFor="start_date">
                  <span>Date</span>
                </label>
                <DatePickerField
                  disabled={!datesEditable}
                  name="start_date"
                  value={values.start_date}
                  onChange={setFieldValue}
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="start_date" />

              <div className="form-group">
                <label>
                  <span>Start Time</span>
                </label>
                <TimePickerField
                  disabled={!datesEditable}
                  name="start_time"
                  value={values.start_time}
                  onChange={setFieldValue}
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="start_time" />

              <div className="form-group">
                <label>
                  <span>End Time</span>
                </label>
                <TimePickerField
                  disabled={!datesEditable}
                  name="end_time"
                  value={values.end_time}
                  onChange={setFieldValue}
                />
              </div>
              <ErrorMessage component={FormErrorMessage} name="end_time" />

              {session ?
                <React.Fragment>
                  <div className="form-group session-status">
                    <label><span>Status</span></label>
                    <span>{session.status}</span>
                  </div>

                  <div className="form-group">
                    <label>Student Notes</label>
                    <div className="help-text">
                      Check notes and/or docs added by your student for this lesson to help you
                      prepare for the session.
                    </div>
                    <textarea disabled={true}>{session.student_notes}</textarea>
                  </div>

                  <AttachmentManager
                    initialAttachments={session.student_attachments}
                    sessionId={sessionId}
                    maxFiles={3}
                    disabled={true}
                    readOnly={true}
                  />

                  {resources.length > 0 && <>
                    <div className="form-group">
                      <label>Resources</label>
                      <div className="session-resources">
                        {resources.map((res, index) => (
                          <ResourceCard
                            key={index}
                            title={res.title}
                            desc={res.description}
                            url={res.url || res.file.url}
                            isVisible={true}
                          />
                        ))}
                      </div>
                    </div>
                  </>}

                  <div className="form-group">
                    <label htmlFor="notes">Lesson Summary</label>
                    <div className="help-text">
                      After your lesson, write a quick summary. This will be
                      shared with your student.
                    </div>
                    <Field
                      name="notes"
                      component="textarea"
                      disabled={!summaryEditable}
                    />
                  </div>

                  <AttachmentManager
                    initialAttachments={session.teacher_attachments}
                    sessionId={sessionId}
                    maxFiles={3}
                    disabled={session.status !== 'complete'}
                    readOnly={false}
                  />

                </React.Fragment>
                :
                <React.Fragment>
                  <div className="form-group">
                    <label>
                      <span>Repeats Weekly</span>
                    </label>
                    <Field
                      name="repeats_weekly"
                      type="checkbox"
                      value="yes"
                    />
                  </div>
                  {values.repeats_weekly.includes('yes') ?
                    <div className="form-group repeat-ends">
                      <label><span>Ends</span></label>
                      <Field component="div" className="ends">
                        <div className="form-group">
                          <input
                            type="radio"
                            checked={values.ends_type === 'on_date'}
                            name="ends_type"
                            value="on_date"
                          />
                          &nbsp;On&nbsp;
                          <DatePickerField
                            name="repeat_until_date"
                            value={values.repeat_until_date}
                            onChange={setFieldValue}
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="radio"
                            checked={values.ends_type === 'after_count'}
                            name="ends_type"
                            value="after_count"
                          />
                          &nbsp;After&nbsp;
                          <Field as='select' name='repeat_until_count'>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                          </Field>
                          &nbsp;occurrences
                        </div>
                      </Field>
                    </div>
                  : ''}
                </React.Fragment>
              }
            </div>

            <div className="form-toolbar">
              {isSubmitting ? <div className="submit-spinner spinner"></div> : ''}
              {canCancel ?
                <a href="#" onClick={openModal} className={isSubmitting ? 'delete inverted disabled' : 'delete inverted'}>Cancel</a>
              : ''}
              <Link
                to=".."
                className="cancel inverted"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >Go Back</Link>
              {datesEditable || summaryEditable ?
                <a href="#" onClick={handleSubmit} className={isSubmitting ? 'save disabled' : 'save'}>Save</a>
                : ''
              }
            </div>
          </Form>
        )}
      </Formik>

      <Modal
        isOpen={isModalOpen}
        style={modalStyle}
      >
        <h2 className="inverted">Are you sure you want to cancel this lesson?</h2>
        <p>This lesson will be cancelled immediately. You can&apos;t undo this action.</p>
        <div className="toolbar">
          {isDeleting ? <div className="spinner"></div> : ''}
          <a onClick={closeModal} href="#" className="btn inverted">Go Back</a>
          <a onClick={deleteSession} href="#" className={isDeleting ? 'btn disabled' : 'btn'}>Cancel</a>
        </div>
      </Modal>
    </div>
  );
};

export default MyStudentsSessionEditView;
