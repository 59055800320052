import React from 'react';
import { createRoot } from 'react-dom/client';
import QuizOverview from './quiz/quiz-overview.jsx';
import CustomerReviewList from './customer_review/customer-review-list.jsx';
import CheckoutView from './cart/CheckoutView.jsx';
import FlagList from './course_selector/flag-list.jsx';
import { getQuizSeries } from './quiz/api.js';
import ProfileApp from './profile/ProfileApp.jsx';
import StudentProfileApp from './profile/StudentProfileApp.jsx';
import StudentGreetingCardView from './profile/StudentGreetingCardView.jsx';
import SalesLeadModal from './general/SalesLeadModal.jsx';
import TeacherCarousel from './general/TeacherCarousel.jsx';
import RenewalNoticeModal from './general/RenewalNoticeModal.jsx';


// This module contains all of the LAUNCH functions for triggering React apps.
// The React app launch functions require inital props and an target id.

export function launchQuizOverview(showPhoneCapture, target_id) {
  getQuizSeries(null, series => {
    // Mount the QuizOverview component
    const container = document.getElementById(target_id);
    const root = createRoot(container);
    root.render(<QuizOverview
      quiz_series={series}
      showPhoneCapture={showPhoneCapture}
    />);
  });
}

export function launchFlagList(target_id) {
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<FlagList />);
}

export function launchCustomerReviewList(target_id) {
  // Mount the CustomerReviewList component
  let enrollableId = document.getElementById('react-launch-CustomerReviewList').dataset.enrollableId
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<CustomerReviewList enrollableId={parseInt(enrollableId)} />);
}

export function launchCheckoutView(target_id) {
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<CheckoutView />);
}

export function launchProfileApp(target_id) {
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<ProfileApp {...(container.dataset)} />);
}

export function launchStudentProfileApp(target_id) {
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<StudentProfileApp {...(container.dataset)} />);
}

export function launchStudentGreetingCardView(target_id) {
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<StudentGreetingCardView />);
}

export function launchSalesLeadModal(target_id) {
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<SalesLeadModal />);
}

export function launchTeacherCarousel(target_id) {
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<TeacherCarousel {...(container.dataset)} />);
}

export function launchRenewalNoticeModal(target_id) {
  const container = document.getElementById(target_id);
  const root = createRoot(container);
  root.render(<RenewalNoticeModal />);
}
