import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types'
import Slider from 'react-slick';
import Modal from 'react-modal';

const modalStyle = {
  content: {
    margin: '0 auto',
    padding: '20px',
    border: '1px solid black',
    maxWidth: '1200px',
    borderRadius: '0',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  }
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} carousel-arrow`}
      style={{ style }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} carousel-arrow`}
      style={{ style }}
      onClick={onClick}
    />
  );
}

NextArrow.propTypes = PrevArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

const TeacherCarousel = ({ teachers }) => {
  const slider = useRef(null);
  teachers = JSON.parse(teachers);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(teachers[0]);

  const selectSlide = (index) => {
    setSelectedTeacher(teachers[index]);
    if (!isOpen) {
      openModal();
    }
  };



  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-inner">
        <Slider ref={slider} {...settings}>
          {teachers.map((teacher, index) => (
            <div key={index} data-index={index}>
              <div className="carousel-item">
                <img src={teacher.photo_url} />
                <div className="teacher-name">{teacher.first_name + ' ' + teacher.last_name[0]}</div>
                <div className="teacher-quote">{teacher.quote}</div>
                <div className="find-classes-button" onClick={() => selectSlide(index)}>
                  <p className="find-classes-button-text">Learn More</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <Modal
        isOpen={isOpen}
        className="teacher-carousel-modal"
        shouldCloseOnOverlayClick={true}
        appElement={$('.carousel-container').get(0)}
        style={modalStyle}
        onRequestClose={() => closeModal()}
      >
        <div className="modal-inner">
          <div className="teacher-name">{selectedTeacher.first_name + ' ' + selectedTeacher.last_name[0]}</div>
          <div className='close' onClick={() => closeModal()}></div>
          <div className="modal-left">
            <img src={selectedTeacher.photo_url} />
          </div>
          <div className="modal-right">
            <div className="teacher-desc">{selectedTeacher.quote}</div>
            <div className="teacher-bio">{selectedTeacher.bio}</div>
            <div className="student-quote">
              <div className="text-box"></div>
              <div className="text-quote">{selectedTeacher.student_review_quote}</div>
            </div>
          </div>
        </div>

      </Modal>
    </div>
  );
};

TeacherCarousel.propTypes = {
  teachers: PropTypes.string.isRequired
};

export default TeacherCarousel;
