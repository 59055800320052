import PropTypes from 'prop-types'
import React from 'react'


export default class QuestionInput extends React.Component {

    static propTypes = {
        handleInputChange: PropTypes.func.isRequired,
        input_type: PropTypes.oneOf(['text', 'number']).isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        on_key_press_func: PropTypes.func,
        placeholder: PropTypes.string,
        question: PropTypes.string.isRequired,
        question_id: PropTypes.number.isRequired,
        showingFeedback: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = {
            value: '',
        }
    }

    handleInput = evt => {
        this.setState({value: evt.target.value})
        this.props.handleInputChange(evt.target.dataset.questionId, [evt.target.value])
    }

    render() {
        return(
            <div className="quiz-field-group">
                <h4>{this.props.question }</h4>
                <input
                    className="quiz-field-input"
                    data-question-id={this.props.question_id}
                    disabled={this.props.showingFeedback}
                    id={'question-input-' + this.props.question_id}
                    name={this.props.name}
                    onChange={this.handleInput}
                    onKeyPress={this.props.on_key_press_func}
                    placeholder={this.props.placeholder}
                    type={this.props.input_type}
                    value={this.state.value}
                />
                {this.props.label != '' ? <label className="quiz-field-label">{this.props.label}</label> : ''}
            </div>
        )
    }
}
