import React from 'react';
import PropTypes from 'prop-types';

const HeroBanner = ({ backgroundUrl }) => {
  const bannerStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundUrl})`
  };
  return (
    <div className="medium-hero" style={bannerStyle}>
      <div className="padded-container">
        <div className="upnext no-translate">
          <h2>Hello {window.user_first_name}</h2>
        </div>
      </div>
    </div>
  );
}

HeroBanner.propTypes = {
  backgroundUrl: PropTypes.string.isRequired
};

export default HeroBanner;
