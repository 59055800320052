import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import NavItem from './NavItem.jsx';
import MobileMenu from './MobileMenu.jsx';
import MyLessonsView from './MyLessonsView.jsx';
import MyResourcesView from './generic/my_resources/MyResourcesView.jsx';
import AccountOverviewView from './students/account_overview/AccountOverviewView.jsx';
import BookLessonsView from './students/book_lessons/BookLessonsView.jsx';
import PaymentInfoView from './students/payment_info/PaymentInfoView.jsx';

import { profileUrls } from '../constants.js';

export default function StudentProfileApp() {
  const routes = [
    {
      path: profileUrls.accountOverview,
      header: 'Account Overview',
      component: AccountOverviewView,
      exact: true
    },
    {
      path: profileUrls.myLessons,
      header: 'My Lessons',
      component: MyLessonsView,
      subRoutes: true
    },
    {
      path: profileUrls.bookLessons,
      header: 'Book Lessons',
      component: BookLessonsView,
      subRoutes: true
    },
    {
      path: profileUrls.myResources,
      header: 'My Resources',
      component: MyResourcesView,
      subRoutes: true
    },
    {
      path: profileUrls.paymentInfo,
      header: 'Payment Information',
      component: PaymentInfoView
    }
  ]
  return (
    <div className="ProfileApp StudentProfileApp">
      <Router history={history}>
        <ToastContainer draggable={false} closeButton={false} />

        <div className="account-inner-container student-view padded-container">
          <div id="account-details-nav">
            <div className="links">
              {routes.map((route, index) => (route &&
                <NavItem
                  key={index}
                  url={route.path}
                  exact={route.exact}
                  label={route.header}
                />
              ))}
              <div className="nav-item">
                <a href="/account/logout">Log Out</a>
              </div>
            </div>
          </div>

          <div className="account-content-container">
            <div className="account-mobile-menu">
              <MobileMenu routes={routes} />
            </div>
            <div id="me">
              <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.subRoutes ? `${route.path}/*` : route.path}
                  exact={route.exact}
                  element={<route.component />}
                />
              ))}
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
}
