import React from 'react';
import PropTypes from 'prop-types';

const ResourceCard = ({ title, desc, url, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="ResourceCard">
      <h2 className="resource-title">
        {title}
      </h2>
      {desc ?
        <span className="resource-desc">
          <strong>Description: </strong>
          {desc}
        </span>
      : <span className="resource-desc"></span>}
      <div className="resource-link">
        <a
          target="_blank"
          rel="noreferrer"
          className="resource-link-btn btn inverted"
          href={url}
        >View</a>
      </div>
    </div>
  );
};

ResourceCard.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  url: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default ResourceCard;
