import PropTypes from 'prop-types'
import React from 'react'
import CheckoutTotal from './CheckoutTotal.jsx'
import {CardElement} from '@stripe/react-stripe-js';

// styling for Stripe input component fields
// https://stripe.com/docs/js/appendix/style
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      backgroundColor: '#fff',
      color: '#000',
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#4a4a4a',
      },
    },
    invalid: {
      color: '#fb0d57,' // $rose
    },
  },
};

const StripeForm = (props) => {
  var has_subscription = false;
  for (const line of props.lineitems) {
    if ('subscription-plan-id' in line['extra_data']) {
      has_subscription = true;
      break;
    }
  }

  return (
    <form
      className={`ParticipantStripeForm ${props.orderIsProcessing ? 'enabled' : 'disabled'}`}
    >
      {(props.total > 0 || has_subscription) &&
        <div className='cart_section StripeContainer'>
          <h2>Payment Information</h2>
          <div className='cart_form cart_container'>
            <CardElement id='stripe-card-element' options={CARD_ELEMENT_OPTIONS} />
          </div>
          <p className='cart_section__subheader'>
            <img alt='powered by stripe' src='/static/img/logos/powered_by_stripe.png' />
          </p>
        </div>
      }

      <CheckoutTotal total={props.total} />

      <button
        className='black-gray-button order-btn'
        name='submit-order'
        onClick={(evt) => props.submitOrder(evt, props.stripe, props.elements)}>
        {props.orderIsProcessing ? 'Submitting...' : 'Place Order'}
      </button>

    </form>
  );
};

StripeForm.propTypes = {
  stripe: PropTypes.object,
  elements: PropTypes.object,
  subTotal: PropTypes.number,
  lineitems: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  submitOrder: PropTypes.func.isRequired,
  orderIsProcessing: PropTypes.bool.isRequired
}

export default StripeForm;
