import $ from 'jquery'


function checkURL() {
    if (location.hash == '#Intermediate') {
        $('label.Intermediate').click()
    } else if (location.hash == '#Advanced') {
        $('label.Advanced').click()
    }
}

function changeCategory(ev) {
    if (ev.target.name !== 'productselect') { return }
    var data = document.querySelector('[data-category-id="' + ev.target.id + '"]')
    if (!data || !data.classList.contains('hide')) {
        return
    }
    document.querySelector('.template-list:not(.hide)').classList.add('hide')
    data.classList.remove('hide')

    // On click, set #Beginner, #Intermediate, #Advanced hash on url
    let level = $(ev.target).parent().find('label').attr('class')
    location.hash = level
}

export default function langdetail() {
    var holder = document.querySelector('.language-detail .options')
    if (holder) {
        if (!location.hash) {
            // On page load set #Beginner on url if none present
            let first_level = $(holder).children().first().find('label').attr('class')
            location.hash = first_level
        }

        holder.addEventListener('change', changeCategory)
        checkURL()
    }
}
