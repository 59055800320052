import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import { CEFRLaymanLabels } from '../../../constants.js';
import FCApi from '../../../fc-api.js';
import { ToastNotificationsError, ToastNotificationsSuccess } from '../../../notifications/ToastNotifications.jsx';

const LearnerProfile = ({ learner, languages, onUpdateLearner }) => {
  const apiClient = new FCApi();

  const updateProfile = (values, actions) => {
    actions.setStatus({});

    let formValues = {...values};
    if (formValues.age === '') {
      formValues.age = null;
    }

    apiClient.v2UpdateLearner(learner.id, formValues, (error, response) => {
      if (error) {
        actions.setErrors(apiClient.formatErrors(response));
        toast.error(
          <ToastNotificationsError
            closeToast={() => {}}
            htmlContent={
              <p>Unable to update your profile.</p>
            }
          />,
          { autoClose: 3200 }
        );
      } else {
        onUpdateLearner(response);
        toast.success(
          <ToastNotificationsSuccess
            closeToast={() => {}}
            htmlContent={
              <p>Profile updated!</p>
            }
          />,
          { autoClose: 3200 }
        );
      }
      actions.setSubmitting(false);
    }, 'expand=language_of_interest,private_lessons.package,private_lessons.language,enrollments.enrolled_in.template');
  };

  return (
    <div className="LearnerProfile">
      <Formik
        enableReinitialize={true}
        initialValues={{
          photo: '',
          language_of_interest: learner.language_of_interest ? learner.language_of_interest.id : '',
          level: learner.level || '',
          goals: learner.goals || '',
          preferences: learner.preferences || '',
          age: learner.age || ''
        }}
        onSubmit={(values, actions) => {
          updateProfile(values, actions);
        }}
      >
      {({ isSubmitting }) => {
        return (
          <Form>{/*
            <div className='account-photo-section'>
              <img id='photo-thumbnail' className='photo-thumbnail' src={values.photo_url} />
              <div className='photo-actions-section'>

                <input id="photo" name="photo" type="file" onChange={(event) => {
                  setFieldValue('photo', event.currentTarget.files[0]);
                  const src = URL.createObjectURL(event.currentTarget.files[0]);
                  $('#photo-thumbnail').attr('src', src);
                }} />

                <div>
                  <label htmlFor='photo' className='photo-upload dark-button inverted'>Upload An Image</label>
                  <a onClick={() => {
                    setFieldValue('photo', null);
                    $('#photo-thumbnail').removeAttr('src');
                  }}>Remove Photo</a>
                </div>
              </div>
            </div>*/}

            {learner.learner_type == 'adult' &&
            <div className="form-row">
              <div className="form-field">
                <label>Language
                  <Field
                    as="select"
                    name="language_of_interest"
                    disabled={isSubmitting}
                    className="field-language"
                  >
                    <option value="">Select a language</option>
                    {languages.map((language, i) => (
                      <option key={i} value={language.id}>{language.name}</option>
                    ))}
                  </Field>
                </label>
              </div>

              <div className="form-field">
                <label>Level
                  <Field
                    as="select"
                    name="level"
                    disabled={isSubmitting}
                  >
                    <option value="">Select a level</option>
                    {CEFRLaymanLabels.map((label, i) => (
                      <option key={i} value={label}>{label}</option>
                    ))}
                  </Field>
                </label>
              </div>
            </div>
            }

            <div className="form-row">
              <label>Goals
                <Field
                  as="textarea"
                  name="goals"
                  disabled={isSubmitting}
                  placeholder={'What are ' + (learner.learner_type == 'adult' ? 'your' : 'your child\'s') + ' learning goals?'}
                  rows={5}
                />
              </label>
            </div>

            <div className="form-row">
              <label>Additional notes
                <Field
                  as="textarea"
                  name="preferences"
                  disabled={isSubmitting}
                  component="textarea"
                  placeholder={'If ' + (learner.learner_type == 'adult' ? 'you have' : 'your child has') + ' any specific interests or learning preferences, list them here.'}
                  rows={5}
                />
              </label>
            </div>

            {learner.learner_type == 'adult' &&
            <div className="form-row">
              <label>Email
                <div className="form-readonly learner-email no-translate">{learner.contact_email}</div>
              </label>
            </div>
            }

            {learner.learner_type == 'child' &&
            <div className="form-row">
              <div className="form-field">
                <label>Age
                  <Field
                    name="age"
                    disabled={isSubmitting}
                  />
                </label>
                <ErrorMessage name="age" />
              </div>
            </div>
            }

            <div className="form-toolbar">
              {isSubmitting ? <div className="submit-spinner spinner"></div> : ''}
              <input
                id="submit"
                className='dark-button'
                type="submit"
                value="Save"
                disabled={isSubmitting}
              />
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  );
};

LearnerProfile.propTypes = {
  languages: PropTypes.array.isRequired,
  learner: PropTypes.object.isRequired,
  onUpdateLearner: PropTypes.func.isRequired
};

export default LearnerProfile;
