import React from 'react';

export default function StudentGreetingCardView() {
  const studentInfo = JSON.parse($('#js-student-info').text());

  return(
    <div>
    {studentInfo.language_of_interest ?
      <h2>{studentInfo.language_of_interest.greeting} {studentInfo.first_name}</h2>
    :
      <h2>Hello {studentInfo.first_name}</h2>
    }
    </div>
  );
}
