import React, { useState, useEffect } from 'react';

import FCApi from '../../../fc-api.js';
import LearnerDetails from './LearnerDetails.jsx';
import ProductDetailModal from './ProductDetailModal.jsx';

const StudentLearnersView = () => {
  const [ learners, setLearners ] = useState([]);
  const [ adultLearners, setAdultLearners ] = useState([]);
  const [ childLearners, setChildLearners ] = useState([]);
  const [ languages, setLanguages ] = useState([]);
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ modalProduct, setModalProduct ] = useState(null);

  const apiClient = new FCApi();

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    filterLearners();
  }, [learners]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = (product) => {
    setModalProduct(product);
    setModalIsOpen(true);
  };

  const refresh = () => {
    apiClient.v2ListLearners((error, response) => {
      if (!error) {
        setLearners(response)
      }
    }, 'expand=language_of_interest,private_lessons.teacher,private_lessons.package,private_lessons.language,enrollments.enrolled_in.template,enrollments.enrolled_in.teacher');

    apiClient.getLanguages((error, response) => {
      if (!error) {
        setLanguages(response);
      }
    });
  };

  const filterLearners = () => {
    setAdultLearners(learners.filter(l => l.learner_type === 'adult'));
    setChildLearners(learners.filter(l => l.learner_type === 'child'));
  };

  const updateLearner = (learner) => {
    let newLearners = [...learners];
    for (let i = 0 ; i < newLearners.length ; i++) {
      if (newLearners[i].id === learner.id) {
        newLearners[i] = learner;
        break;
      }
    }
    setLearners(newLearners);
  };

  return (
    <div className="StudentLearnersView">
      <h2 className="inverted">Learners</h2>
      {adultLearners.length > 0 && (
        <div className="learner-details-container">
          <h3>Adult Learners</h3>
          {adultLearners.map((learner, index) => (
            <LearnerDetails
              key={index}
              learner={learner}
              languages={languages}
              onUpdateLearner={updateLearner}
              openModal={openModal}
            />
          ))}
        </div>
      )}
      {childLearners.length > 0 && (
        <div className="learner-details-container">
          <h3>Child Learners</h3>
          {childLearners.map((learner, index) => (
            <LearnerDetails
              key={index}
              learner={learner}
              languages={languages}
              onUpdateLearner={updateLearner}
              openModal={openModal}
            />
          ))}
        </div>
      )}
      <ProductDetailModal
        isOpen={modalIsOpen}
        product={modalProduct}
        closeModal={closeModal}
      />
    </div>
  );
};

export default StudentLearnersView;
