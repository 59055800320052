import React from 'react'
import CourseDisplay from './course-display.jsx'
import FCApi from '../fc-api.js'
import FlagCard from './flag-card.jsx'
import {getLanguageTemplates} from '../api.js'
import urls from '../constants.js'
import {getattr} from '../util.js'

const levelNames = ['Beginner', 'Intermediate', 'Advanced']
const levelSlugs = ['beginner', 'intermediate', 'advanced']

const makeTemplateObject = (template, level) => {
  // Construct an object representing Enrollable Template Data
  //   :level: an int from 0-2
  const language = getattr(template, 'language', '');

  return {
    category: `${language} ${levelNames[level]}`,
    type: 'Course',
    name: 'Private',
    slug: 'private',
    weight: 5,
    short_description: 'You want a learning experience built for your specific needs.',
    url: urls.private_lessonURL,
    level: level,
    language: language,
    language_flag: getattr(template, 'language_flag', ''),
    language_image: getattr(template, 'language_image', ''),
    level_group: levelSlugs[level],
    parent_cat_slug: getattr(template, 'parent_cat_slug', ''),
    root_cat_slug: getattr(template, 'parent_cat_slug', ''),
    enabled: true,
    hidden: false,
    image: null,
    hero_image: null,
    group_icon: '/static/img/assets/private_lessons/icon.png',
    share_text: 'Share With Friends',
    price: 'From $65/hr',
    description: '',
    additional_info: '',
    takeaway_additions: '',
    takeaway_callout: '',
  };
}


export default class FlagList extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      flagIndex: 0,
      page: 0,
      flagLeftBound: 0,
      levelIndex: 0,
      templates: [],
      languages: []
    }
    this.localCache = {}
    this.apiClient = new FCApi();

    // Placeholders
    this.citySlug = 'new-york';
  }

  componentDidMount() {
    this.apiClient.getLanguages((error, response) => {
      this.setState({languages: response.filter(l => l.is_group_course_featurable)}, () => {
        this.flagClick(0);
      });
    });
  }

  addExtraTemplates(data) {
    // Add private course templates to beginner, intermediate,
    // and advanced sections
    var template = data[0];

    // Add private lesson templates
    if (template) {
      data.push(
        makeTemplateObject(template, 0),
        makeTemplateObject(template, 1),
        makeTemplateObject(template, 2)
      );
    }

    return data;
  }

  toggleFlagSelect = (flags, flagIndex) => {
    // toggle the CSS for selecting a flag in the flag dropdown
    for (var i = 0; i < flags.length; i++) {
      flags[i].classList.remove('selected-language')
    }

    // highlight only the user selected flag
    flags[flagIndex].classList.add('selected-language');
  }

  flagClick = flagIndex => {
    if (this.state.flagIndex !== flagIndex) {
      this.setState({
        page: 0
      })
    }
    // Handle click event for individual language flags on the list.
    var flagNames = [],
      flag_container = document.getElementsByClassName('flag-list-item-container'),
      flag = flag_container[0],
      selector = document.getElementsByClassName('flag-selector')[0],
      flags = Array.from(flag_container);

    if (!flag) { return false; }
    let flagBounds = flag.getBoundingClientRect();
    let listElement = document.getElementsByClassName('flag-list')[0];
    if (!listElement) { return false; }
    var list = listElement.getBoundingClientRect();

    // moves the little dropdown arrow up and down.
    if (selector) {
      if (this.state.languages[flagIndex].slug.length > 8 && window.innerWidth < 500) {
        selector.style.opacity = 0
      } else {
        selector.style.opacity = 1
      }
    }

    // this is iterating over the <h4> elements and extracting the language names
    flags.map(el => {
      flagNames.push(el.getElementsByTagName('h4')[0].innerText)
    });

    let currentLanguage = flagNames[flagIndex]
    let cachedLanguage = this.localCache[currentLanguage];
    if (!cachedLanguage) {
      getLanguageTemplates(this.state.languages[flagIndex].slug, data => {
        data = this.addExtraTemplates(data)
        this.localCache[currentLanguage] = data
        this.setState({
          flagIndex: flagIndex,
          flagLeftBound: flagBounds.left - list.left + flagBounds.width / 2 - 16,
          templates: data,
        }, () => {
          this.toggleFlagSelect(flags, flagIndex);
        });
      })
    } else {
      this.setState({
        flagIndex: flagIndex,
        flagLeftBound: flagBounds.left - list.left + flagBounds.width / 2 - 16,
        templates: cachedLanguage,
      }, () => {
        this.toggleFlagSelect(flags, flagIndex);
      });
    }
  }

  repositionFlagSelector = () => {
    // Update the black triangular flag selector's position.
    let flagElement = document.getElementsByClassName('flag-list-item-container')[0];
    let listElement = document.getElementsByClassName('flag-list')[0];
    if (!(flagElement && listElement)) { return false; }
    let flag = flagElement.getBoundingClientRect();
    let list = listElement.getBoundingClientRect();
    this.setState({
      flagLeftBound: flag.left - list.left + flag.width / 2 - 16,
    });
  }

  tabClick = index => {
    // Select a level group tab
    var slidingTabs
    if (index >= 0 && index < 3) {
      slidingTabs = Array.from(document.getElementsByClassName('courses-tab'))
      slidingTabs.map(tab => tab.style.transition = 'none')

      this.setState({
        levelIndex: index,
        page: 0
      });

      setTimeout(function(slidingTabs) {
        slidingTabs.map(tab => tab.style.transition = 'none')
        setTimeout(function(slidingTabs) {
          slidingTabs.map(tab => tab.style.transition = 'transform .5s')
        }.bind(this, slidingTabs), 60)
      }.bind(this, slidingTabs), 60)
    }
  }

  tabShift = direction => {
    // Go forward or backward one tab:
    // 'direction' equals either 1 or -1
    let new_tab = this.state.levelIndex + direction
    if (new_tab > 2) { return false }
    new_tab = Math.max(0, new_tab)
    this.tabClick(new_tab)
  }

  setPage = page => {
    this.setState({page: page});
  }

  toggleDropdown() {
    // At mobile widths, toggle whether the language dropdown is expanded.
    var flagList = document.getElementsByClassName('flag-list')[0]
    if (window.innerWidth >= 900) { return false }
    if (flagList.className.includes('languages-expanded')) {
      flagList.classList.remove('languages-expanded')
    } else {
      flagList.classList.add('languages-expanded')
    }
  }

  render() {
    let flagListItemElement = document.getElementsByClassName('flag-list-item')[0]
    var flagWidth = flagListItemElement
      ? flagListItemElement.getBoundingClientRect().width : 0
    var selectedLanguage = this.state.languages && this.state.languages[this.state.flagIndex]

    return (
      <section className='languages-inner-container js-lang-section'>
        <div className='language-module-header'>
          <h1>Our Group Course Languages</h1>
          <p>What do you want to learn to speak next?</p>
        </div>

        <div className='languages-list'>
          <ul className='flag-list with-slider' onClick={this.toggleDropdown}>
            {this.state.languages && this.state.languages.map(function(language, index) {
              return (
                <FlagCard
                  language={language}
                  selectedLanguage={index === this.state.flagIndex}
                  key={language.slug}
                  onClick={this.flagClick.bind(this, index)}
                />
              )
            }.bind(this))}

            <div className='flag-selector' style={{
              transform: 'translateX(' + (window.innerWidth > 900 ? (flagWidth * this.state.flagIndex):0) + 'px)',
              left: this.state.flagLeftBound,
            }}></div>
          </ul>

          <div className='language-detail'>
            <div className='lang-level-tabs'>
              <div
                onClick={this.tabClick.bind(this, 0)}
                className={`select-tab beginner-tab ${this.state.levelIndex === 0 ? 'active-tab' : ''}`}
              > Beginner </div>
              <div
                onClick={this.tabClick.bind(this, 1)}
                className={`select-tab intermediate-tab ${this.state.levelIndex === 1 ? 'active-tab' : ''}`}
              > Intermediate </div>
              <div
                onClick={this.tabClick.bind(this, 2)}
                className={`select-tab advanced-tab ${this.state.levelIndex === 2 ? 'active-tab' : ''}`}
              > Advanced </div>
            </div>
            <div className='lang-course-details'>
              <CourseDisplay
                city={this.citySlug}
                currentLevel={this.state.levelIndex}
                currentPage={this.state.page}
                language={selectedLanguage}
                tabShift={this.tabShift}
                setPage={this.setPage}
                templates={this.state.templates}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
