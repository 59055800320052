import React, { useState } from 'react';
import Modal from 'react-modal';

const modalStyle = {
  content: {
    margin: '15% auto',
    padding: '20px',
    border: '1px solid black',
    maxWidth: '500px',
    borderRadius: '0'
  }
};

const RenewalNoticeModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  $('[data-renewal-notice-open]').click((e) => {
    e.preventDefault();
    openModal();
  });

  return (
    <Modal
      isOpen={isOpen}
      className="SalesLeadModal"
      shouldCloseOnOverlayClick={true}
      appElement={$('#react-launch-RenewalNoticeModal').get(0)}
      style={modalStyle}
      onRequestClose={() => closeModal()}
    >
      <div className='close' onClick={() => closeModal()}></div>
      <h3 style={{marginBottom:'1em'}}>Automatically renewing plans</h3>

      <p>All the private plans work as monthly
      subscription and give you access to 1, 4, 8, or 12 hours of private
      online lessons per billing cycle, as well as Fluent City&apos;s full list of
      resources! Your subscription will auto-renew every billing cycle.</p>

      <p>Have more questions? Visit our <a href="https://faq.cricketelearning.com/">FAQ</a>
      &nbsp;page or email us at <a href="mailto:help@cricketmedia.com">help@cricketmedia.com</a>.</p>
    </Modal>
  );
}

export default RenewalNoticeModal;
