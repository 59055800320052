import React from 'react';

export default function CalendarKey() {
  return (
    <div className='calendar-key'>
      <div className='calendar-key-item'>
        <img src='/static/img/assets/account/calendar/unavailable-icon.svg' />
        <b className='force-uppercase'>Unavailable</b>
      </div>
      <div className='calendar-key-item'>
        <img src='/static/img/assets/account/calendar/available-icon.svg' />
        <b className='force-uppercase'>Available</b>
      </div>
      <div className='calendar-key-item'>
        <img src='/static/img/assets/account/calendar/holiday-icon.svg' />
        <b className='force-uppercase'>Holiday</b>
      </div>
    </div>
  )
}