import $ from 'jquery'
import * as launch from './react-launch.js'


export default function reactListen() {
    // This module contains all of the LISTENERS for triggering React apps.
    // The React apps are launched using their respecitve launch functions,
    // anchor tag id's, and inital props.

    $('#react-launch-QuizOverview').on('click', function(evt) {
        // set initial state of showPhoneCaputre on the QuizOverview component
        // to true if the phone caputre btn or its children are clicked
        let showPhoneCapture = $(evt.target).hasClass('showPhoneCapture') || $(evt.target).parents().hasClass('showPhoneCapture')
        launch.launchQuizOverview(showPhoneCapture, 'quiz-container')
    })

    if ($('#react-launch-FlagList').length) {
      launch.launchFlagList('react-launch-FlagList')
    }

    if ($('#react-launch-CustomerReviewList').length) {
      launch.launchCustomerReviewList('react-launch-CustomerReviewList')
    }

    if ($('#react-launch-CheckoutView').length) {
      launch.launchCheckoutView('react-launch-CheckoutView')
    }

    if ($('#react-launch-ProfileApp').length) {
      launch.launchProfileApp('react-launch-ProfileApp');
    }

    if ($('#react-launch-StudentProfileApp').length) {
      launch.launchStudentProfileApp('react-launch-StudentProfileApp');
    }

    if ($('#react-launch-StudentGreetingCardView').length) {
      launch.launchStudentGreetingCardView('react-launch-StudentGreetingCardView');
    }

    if ($('#react-launch-SalesLeadModal').length) {
      launch.launchSalesLeadModal('react-launch-SalesLeadModal');
    }

    if ($('#react-launch-TeacherCarousel').length) {
      launch.launchTeacherCarousel('react-launch-TeacherCarousel');
    }

    if ($('#react-launch-RenewalNoticeModal').length) {
      launch.launchRenewalNoticeModal('react-launch-RenewalNoticeModal');
    }
}
