import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const SelectLearnerView = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    // If only one learner, preselect it.
    if (props.learners.length == 1) {
      onLearnerSelect(props.learners[0].id, true);
    }
  }, []);

  const onLearnerSelect = (learnerId, skip=true) => {
    let path = `${learnerId}`;
    if (skip) {
      path += '?skip';
    }
    navigate(path);
  };

  return (
    <div className="learner-selector">
      <div className="title">Select a Learner</div>
      {props.learners.length == 0 ?
        <div className="no-lessons">
        No Learners
        </div> :
        <div className="learners no-translate">
          {props.learners.map((learner, index) => (
            <div
              className="booking-option"
              key={index}
              onClick={() => onLearnerSelect(learner.id)}
            >
              <h2 className="option-title">
                {learner.first_name} {learner.last_name ? learner.last_name[0] : ''}
              </h2>
            </div>
          ))}
        </div>
      }
    </div>
  );

};

SelectLearnerView.propTypes = {
  learners: PropTypes.array.isRequired,
};

export default SelectLearnerView;
