import PropTypes from 'prop-types'
import React from 'react'


export default function DiscountTotal(props) {
    // Display component for applied promo and credit values
    return (
        <div className='DiscountTotal'>
            { props.promo_amt > 0.0 &&
                <div className='DiscountContainer__line'>
                    <h4>Promo Discount:  -${props.promo_amt}</h4>
                </div>
            }
            { props.credit_amt > 0.0 &&
                <div className='DiscountContainer__line'>
                    <h4>Order Credit:  -${props.credit_amt}</h4>
                </div>
            }
        </div>
    )
}

DiscountTotal.propTypes = {
    promo_amt: PropTypes.number,
    credit_amt: PropTypes.number,
}
