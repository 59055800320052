import $ from 'jquery'


function openTab(evt) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = 'none'
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' active', '')
    }

    // Show current tab and add "active" class to button that opened the tab
    document.getElementById(evt.target.id + 'tab').style.display = 'block'
    evt.currentTarget.className += ' active'
}

export default function gyl() {
    var tabs = document.getElementsByClassName('tablinks')
    for(var i=0; i<tabs.length; i++) {
        tabs[i].addEventListener('click', openTab)
    }
    if (location.search.indexOf('private') != -1) {
        $('#private').click()
    } else {
        $('#group').click()
    }
}
