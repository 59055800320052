import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import $ from 'jquery';
import { toast } from 'react-toastify';

import { Calendar, momentLocalizer } from 'react-big-calendar';

import FCApi from '../fc-api.js';
import { ToastNotificationsError, ToastNotificationsSuccess } from '../notifications/ToastNotifications.jsx';

import CalendarToolbar from './CalendarToolbar.jsx';
import TeacherCreateTooltip from './CreateEventTooltip.jsx';
import TeacherCalendarCard from './TeacherCalendarCard.jsx';
import TeacherCalendarTooltip from './TeacherCalendarTooltip.jsx';
import CalendarKey from './CalendarKey.jsx';
import CalendarHeader from './CalendarHeader.jsx';

import { privateLessonCancelError, privateLessonCancelSuccess } from './constants.jsx';
import {
  filterListForOverlappers,
  getTimeRangeString,
  shiftTimezone,
  useWindowSize
} from './utils.jsx';

const USER_TIMEZONE = window.user_timezone !== '' ? window.user_timezone : 'America/Detroit';

export default function TeacherCalendarView() {
  // event data
  const [ privateSessions, setPrivateSessions ] = useState([]);
  const [ classroomSessions, setClassroomSessions ] = useState([]);
  const [ teacherAvailabilities, setTeacherAvailabilities ] = useState([]);
  const [ skipDates, setSkipDates ] = useState([]);

  const [ availabilityIDToRemove, setAvailabilityIDToRemove ] = useState(null);

  // timeslot selection
  const [ selectedSlot, setSelectedSlot ] = useState(null);
  const [ selectedSlotOverlaps, setSelectedSlotOverlaps ] = useState([]);
  const [ shouldSaveAvailability, setShouldSaveAvailability ] = useState(false);
  const [ shouldDeleteAvailability, setShouldDeleteAvailability ] = useState(false);
  const [ shouldAvailabilityRepeat, setShouldAvailabilityRepeat ] = useState(false);

  // event selection
  const [ selectedEvent, setSelectedEvent ] = useState(null);
  const [ isCancellingPrivateSession, setIsCancellingPrivateSession ] = useState(false);

  // navigation trackers
  const [ canGoBack, setCanGoBack ] = useState(true); // eslint-disable-line no-unused-vars
  const [ canGoForward, setCanGoForward ] = useState(true); // eslint-disable-line no-unused-vars
  const [ viewDate, setViewDate ] = useState(null);
  const [ viewDateRange, setViewDateRange ] = useState([
    moment().startOf('week'),
    moment().endOf('week')
  ]);

  // initialize API
  const apiClient = new FCApi();

  // window size tracker
  const [ windowWidth ] = useWindowSize();

  useEffect(() => {
    fetch_events();
  }, [viewDateRange]);

  useEffect(() => {
    $('.rbc-event').css('z-index', 1);
  }, [privateSessions, classroomSessions, teacherAvailabilities, selectedSlot, viewDate]);

  useEffect(() => {
    if(selectedSlot !== null) {
      $('#temp-event').parent().parent().css('z-index', 2);
    }
  }, [selectedSlot]);

  useEffect(() => {
    setTeacherAvailabilities(teacherAvailabilities.filter(el => el.id !== availabilityIDToRemove));
  }, [availabilityIDToRemove]);

  const getUrlParams = () => {
    return {
      'end_at__gte': moment(viewDateRange[0]).toISOString(),
      'start_at__lte': moment(viewDateRange[1]).toISOString(),
      'teacher': window.user_id
    }
  }

  const fetch_events = () => {
    if (!viewDateRange) {
      return;
    }

    let psParams = getUrlParams();
    psParams['expand'] = 'teacher,lesson.student,lesson.learner'
    apiClient.v2ListPrivateSessions(
      (error, response) => {
        if (!error) {
          setPrivateSessions(response);
        }
      },
      $.param(psParams)
    );

    let csParams = getUrlParams();
    csParams['expand'] = 'teacher,course.template.category.language,venue'
    apiClient.v2ListClassroomSessions(
      (error, response) => {
        if (!error) {
          // Filter out any cancelled sessions.
          setClassroomSessions(response.filter(s => s.status != 'cancelled'));
        }
      },
      $.param(csParams)
    );

    apiClient.listTeacherAvailabilities(
      (error, response) => {
        if (!error) {
          setTeacherAvailabilities(response.map((el) => {
            let obj = el;
            obj.type = 'availability';
            return obj;
          }));
        }
      },
      $.param(getUrlParams())
    );

    apiClient.listSkipDates(
      (error, response) => {
        if (!error) {
          setSkipDates(response);
        }
      },
      $.param(getUrlParams())
    );
  }

  // calendar library accessor methods
  const getStart = (session) => {
    if (session.type == 'temp') {
      // temp events are already in local calendar time
      return moment(session.start_at).toDate();
    }

    return shiftTimezone(session.start_at, USER_TIMEZONE, moment.tz.guess());
  }
  const getEnd = (session) => {
    if (session.type == 'temp') {
      // temp events are already in local calendar time
      return moment(session.end_at).toDate();
    }

    let localEnd = moment(shiftTimezone(session.end_at, USER_TIMEZONE, moment.tz.guess()));
    if (localEnd.hour() == 0 && localEnd.minute() == 0) {
      localEnd.subtract(1, 'minutes');
    }
    return localEnd.toDate();
  };

  // calendar selection callback methods
  const segmentSelected = (slotInfo) => {
    const filteredAvailabilities = filterListForOverlappers(
      moment(shiftTimezone(slotInfo.start, moment.tz.guess(), USER_TIMEZONE)),
      moment(shiftTimezone(slotInfo.end, moment.tz.guess(), USER_TIMEZONE)),
      teacherAvailabilities
    )
    setSelectedSlot(slotInfo);
    setSelectedEvent(null);
    setShouldSaveAvailability(false);
    setShouldAvailabilityRepeat(false);
    if (filteredAvailabilities.length > 0) {
      setSelectedSlotOverlaps(filteredAvailabilities)
    } else {
      setSelectedSlotOverlaps([]);
    }
  }

  const eventSelected = (event, e) => {
    if ('type' in event) {
      if (event.type == 'availability') {
        setSelectedSlot({
          'start': shiftTimezone(event.start_at, USER_TIMEZONE, moment.tz.guess()),
          'end': shiftTimezone(event.end_at, USER_TIMEZONE, moment.tz.guess())
        });
        setSelectedSlotOverlaps([event]);
        setShouldDeleteAvailability(true);
      }
      if (event.type == 'temp' && 'hasOverlap' in event && event.hasOverlap == true) {
        setShouldDeleteAvailability(true);
      }
      return;
    }
    let tempEvent = event;
    event.x = e.pageX;
    event.y = e.pageY;
    setSelectedEvent(tempEvent);
    setSelectedSlot(null);
  }

  // click handlers
  const closeTooltip = () => {
    setSelectedEvent(null);
  }
  const cancelAddAvailability = () => {
    setSelectedSlot(null);
    setShouldAvailabilityRepeat(false);
    setSelectedSlotOverlaps([]);
  }
  const saveAvailability = () => {
    setShouldSaveAvailability(true);
  }
  const closeModal = (e) => {
    e.preventDefault();
    setSelectedSlot(null);
    setShouldSaveAvailability(false);
    setShouldDeleteAvailability(false);
    setShouldAvailabilityRepeat(false);
    setSelectedSlotOverlaps([]);
  }

  const classroomSessionsHaveOverlap = (startAt, endAt) => {
    return classroomSessions.filter(cs => moment(cs.start_at) < endAt && moment(cs.end_at) > startAt).length > 0;
  }

  const privateSessionsHaveOverlap = (startAt, endAt) => {
    return privateSessions.filter(cs => moment(cs.start_at) < endAt && moment(cs.end_at) > startAt).length > 0
  }

  const validateNoConflicts = (data) => {
    // method assumes start/end times have already been shifted back to user
    // profile timezone
    for (const slot of data) {
      if (slot === undefined)
        continue;
      const startAt = moment(slot.start_at);
      const endAt = moment(slot.end_at);
      if (classroomSessionsHaveOverlap(startAt, endAt)) {
        return false;
      }
      if (privateSessionsHaveOverlap(startAt, endAt)) {
        return false;
      }
      if ('repeat_until_count' in slot) {
        const weeks = [...Array(slot['repeat_until_count'] + 1).keys()].slice(1);
        for (const week of weeks) {
          const startAt = moment(slot.start_at).add(week, 'weeks');
          const endAt = moment(slot.end_at).add(week, 'weeks');
          if (classroomSessionsHaveOverlap(startAt, endAt)) {
            return false;
          }
          if (privateSessionsHaveOverlap(startAt, endAt)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  const onSave = () => {
    let postData = [];
    let start = moment(shiftTimezone(selectedSlot.start, moment.tz.guess(), USER_TIMEZONE));
    let end = start.clone().add(30, 'minutes');

    while (start < moment(shiftTimezone(selectedSlot.end, moment.tz.guess(), USER_TIMEZONE))) {
      let slot = {'start_at': start.toDate(), 'end_at': end.toDate()};
      if (shouldAvailabilityRepeat) {
        slot['repeat_until_count'] = 3;
      }
      postData.push(slot)
      start.add(30, 'minutes');
      end.add(30, 'minutes');
    }

    // validate that there are no classroom/private sessions that already exist during the availabilities to be created
    if (!validateNoConflicts(postData)) {
      toast.error(
        <ToastNotificationsError
          htmlContent={
            <p>Oops, you already have a classroom or private lesson scheduled during {selectedSlot.slots.length > 2 || shouldAvailabilityRepeat ? 'these times' : 'this time'}.</p>
          }
        />,
        { autoClose: false }
      );
      setSelectedSlot(null);
      setShouldSaveAvailability(false);
      setShouldAvailabilityRepeat(false);
      return;
    }

    let cb = (error, response) => {
      if (!error) {
        const start = moment(selectedSlot.start);
        const end = moment(selectedSlot.end);
        const timeString = getTimeRangeString(start, end);
        if (shouldAvailabilityRepeat) {
          toast.success(
            <ToastNotificationsSuccess
              htmlContent={
                <p>You&apos;re all set! Your&nbsp;
                  <b className='force-uppercase'>AVAILABILITY</b> is scheduled for every&nbsp;
                  <b className='force-uppercase'>{start.format('ddd')}</b> from&nbsp;
                  <b className='force-uppercase'>{timeString}</b> for&nbsp;
                  <b className='force-uppercase'>4 weeks</b> starting on <b className='force-uppercase'>{start.format('MMM Do')}</b>.
                </p>
              }
            />,
            { autoClose: 3200 }
          );
        } else {
          toast.success(
            <ToastNotificationsSuccess
              htmlContent={
                <p>You&apos;re all set! Your&nbsp;
                  <b className='force-uppercase'>AVAILABILITY</b> is scheduled for&nbsp;
                  <b className='force-uppercase'>{start.format('MMM Do')}</b> from&nbsp;
                  <b className='force-uppercase'>{timeString}</b>.
                </p>
              }
            />,
            { autoClose: 4800 }
          );
        }
        let updated_response = response;
        if (Array.isArray(updated_response)) {
          updated_response = updated_response.map((el) => {
            let obj = el;
            obj.type = 'availability';
            return obj;
          });
        }
        setTeacherAvailabilities([].concat(...teacherAvailabilities, ...[updated_response]));
      } else {
        toast.error(
          <ToastNotificationsError
            htmlContent={
              <p>Oops, you can&apos;t schedule <b className='force-uppercase'>availability</b> during this time.</p>
            }
          />,
          { autoClose: false }
        );
      }
    }
    cb.bind({selectedSlot: selectedSlot, shouldRepeat: shouldAvailabilityRepeat});
    apiClient.createTeacherAvailability(postData, cb);
    setSelectedSlot(null);
    setShouldSaveAvailability(false);
    setShouldAvailabilityRepeat(false);
  }

  const onAvailabilityDelete = () => {
    const start = moment(selectedSlot.start);
    const end = moment(selectedSlot.end);

    selectedSlotOverlaps.map((availability) => {
      apiClient.deleteTeacherAvailability(
        availability.id,
        (error, response) => { // eslint-disable-line no-unused-vars
          if (!error) {
            if (!toast.isActive('availability-deleted')) {
              const timeString = getTimeRangeString(start, end);
              toast.success(
                <ToastNotificationsSuccess
                  htmlContent={
                    <p>You&apos;re all set! Your&nbsp;
                      <b className='force-uppercase'>AVAILABILITY</b> for&nbsp;
                      <b className='force-uppercase'>{start.format('MMM Do')}</b> from&nbsp;
                      <b className='force-uppercase'>{timeString}</b> has been removed.
                    </p>
                  }
                />,
                { autoClose: 3200, toastId: 'availability-deleted' }
              );
            }
            setAvailabilityIDToRemove(availability.id);
          } else {
            if (!toast.isActive('unable-to-delete-availability')) {
              toast.dismiss();
              toast.error(
                <ToastNotificationsError
                  htmlContent={
                    <p>Oops, we weren&apos;t able to remove your <b className='force-uppercase'>availability</b> during this time.</p>
                  }
                />,
                { autoClose: false, toastId: 'unable-to-delete-availability' }
              );
            }
          }
        }
      );
    });
    setSelectedSlot(null);
    setShouldDeleteAvailability(false);
    setSelectedSlotOverlaps([]);
  }

  const onPrivateLessonDelete = () => {
    apiClient.deletePrivateSession(
      selectedEvent.id,
      (error, response) => { // eslint-disable-line no-unused-vars
        const session = selectedEvent;
        const start = moment(session.start_at);
        const end = moment(session.end_at);
        const studentName = `${session.lesson.learner.first_name} ${session.lesson.learner.last_name}`;
        if (!error) {
          toast.success(
            <ToastNotificationsSuccess htmlContent={privateLessonCancelSuccess(studentName, start, end)}/>,
            { autoClose: 3200 }
          );
          setIsCancellingPrivateSession(false);
          setSelectedEvent(null);
          setPrivateSessions(privateSessions.filter(el => el.id !== session.id));
        } else {
          toast.error(
            <ToastNotificationsError htmlContent={privateLessonCancelError(studentName, start, end)}/>,
            { autoClose: 3200 }
          );
          setIsCancellingPrivateSession(false);
          setSelectedEvent(null);
        }
      }
    );
  }

  const onNavigate = (date, view, action) => { // eslint-disable-line no-unused-vars
    let start, end;
    if (view == 'week') {
      start = moment(date).startOf('week');
      end = moment(date).endOf('week');
    } else {
      start = moment(date).startOf('day');
      end = moment(date).endOf('day');
    }
    setViewDate(date);
    setViewDateRange([start, end]);
  }

  const renderActionComponents = (tempEvent) => {
    if (selectedEvent !== null) {
      if (!isCancellingPrivateSession) {
        return (
          <TeacherCalendarTooltip
            session={selectedEvent}
            onCloseTooltip={() => closeTooltip()}
            onCancel={() => setIsCancellingPrivateSession(true)}
          />
        );
      } else {
        const session = selectedEvent;
        const start = moment(session.start_at);
        const end = moment(session.end_at);
        const studentName = `${session.lesson.learner.first_name} ${session.lesson.learner.last_name}`;
        return (
          <Modal
              overlayClassName='profile-modal-overlay'
              className='profile-modal keep-text-centered'
              isOpen={true}
              appElement={$('.ProfileApp').get(0)}
              onRequestClose={() => {
                setSelectedEvent(null);
                setIsCancellingPrivateSession(false);
              }}
              shouldCloseOnOverlayClick={true}
            >
              <div
                className='close'
                onClick={() => {
                  setSelectedEvent(null);
                  setIsCancellingPrivateSession(false);
                }}
              ></div>
              <div className='inner-content'>
                <h2>Oh Man</h2>
                <p>
                  Are you sure you want to cancel your <b className='force-uppercase'>Private Lesson</b> with&nbsp;
                  <b className='force-uppercase'>{studentName}</b> on&nbsp;
                  <b className='force-uppercase'>{start.format('ddd, MMM Do')}</b> from&nbsp;
                  <b className='force-uppercase'>{getTimeRangeString(start, end)}</b>?
                </p>
              </div>
              <div className='actions-wrapper'>
                <a
                  onClick={() => {
                    setSelectedEvent(null);
                    setIsCancellingPrivateSession(false);
                  }}
                  href="#"
                  className="btn inverted"
                >Not Yet</a>
                <button onClick={() => onPrivateLessonDelete()}>Yes</button>
              </div>
            </Modal>
        );
      }
    }
    if (selectedSlot === null) {
      return;
    }
    if (shouldSaveAvailability) {
      const start = moment(selectedSlot.start);
      const end = moment(selectedSlot.end);
      return (
        <Modal
          overlayClassName='profile-modal-overlay'
          className='profile-modal'
          isOpen={true}
          appElement={$('.ProfileApp').get(0)}
          onRequestClose={(e) => closeModal(e)}
          shouldCloseOnOverlayClick={true}
        >
          <div className='close' onClick={(e) => closeModal(e)}></div>
          <div className='inner-content'>
            <h2>Well Done</h2>
            {shouldAvailabilityRepeat ?
              (
                <p>
                  Are you sure you want to indicate your <b className='force-uppercase'>AVAILABILITY</b> every&nbsp;
                  <b className='force-uppercase'>{start.format('ddd')}</b> from&nbsp;
                  <b className='force-uppercase'>{getTimeRangeString(start, end)}</b> for&nbsp;
                  <b className='force-uppercase'>4 weeks</b> starting on <b className='force-uppercase'>{start.format('MMM Do')}</b>?
                </p>
              )
              :
              (
                <p>
                  Are you sure you want to indicate your <b className='force-uppercase'>AVAILABILITY</b> on&nbsp;
                  <b className='force-uppercase'>{start.format('ddd, MMM Do')}</b> from&nbsp;
                  <b className='force-uppercase'>{getTimeRangeString(start, end)}</b>?
                </p>
              )
            }
          </div>
          <div className='actions-wrapper'>
            <a onClick={(e) => closeModal(e)} href="#" className="btn inverted">No, cancel</a>
            <button onClick={() => onSave()}>Yes</button>
          </div>
        </Modal>
      )
    } else if (shouldDeleteAvailability) {
      const start = moment(selectedSlot.start);
      const end = moment(selectedSlot.end);
      return (
        <Modal
          overlayClassName='profile-modal-overlay'
          className='profile-modal'
          isOpen={true}
          appElement={$('.ProfileApp').get(0)}
          onRequestClose={(e) => closeModal(e)}
          shouldCloseOnOverlayClick={true}
        >
          <div className='close' onClick={(e) => closeModal(e)}></div>
          <div className='inner-content'>
            <h2>Hold Up</h2>
            <p>
              Are you sure you want to remove all <b className='force-uppercase'>availabilities</b> on&nbsp;
              <b className='force-uppercase'>{start.format('ddd, MMM Do')}</b> from&nbsp;
              <b className='force-uppercase'>{getTimeRangeString(start, end)}</b>?
            </p>
          </div>
          <div className='actions-wrapper'>
            <a onClick={(e) => closeModal(e)} href="#" className="btn inverted">No, cancel</a>
            <button onClick={() => onAvailabilityDelete()}>Yes</button>
          </div>
        </Modal>
      )
    } else if (selectedSlotOverlaps.length === 0) {
      return (
        <TeacherCreateTooltip
          event={tempEvent}
          isRepeating={shouldAvailabilityRepeat}
          onCancel={() => cancelAddAvailability()}
          onSave={() => saveAvailability()}
          onRepeatChange={() => setShouldAvailabilityRepeat(!shouldAvailabilityRepeat)}
        />
      )
    }
  }

  // setup code for the render
  CalendarToolbar.defaultProps = {
    canGoBack: canGoBack,
    canGoForward: canGoForward,
    skipDates: skipDates,
  }
  const components = {
    event: TeacherCalendarCard,
    toolbar: CalendarToolbar,
    header: CalendarHeader,
  }
  const formats = {
    timeGutterFormat: 'hA',
    dayFormat: (date) => {
      const backShiftDate = moment(date).subtract(1, 'days');
      const hasMatchingHoliday = skipDates.filter(sd => moment(sd.start_at).isSame(backShiftDate, 'date')).length > 0;
      if (hasMatchingHoliday) {
        return `holiday ${moment(date).format('ddd D')}`;
      }
      return moment(date).format('ddd D')
    },
    dayRangeHeaderFormat: ({ start, end }) => {
      start = moment(start);
      end = moment(end);
      return `${start.format('MMM Do')} - ${start.isSame(end, 'month') ? end.format('Do') : end.format('MMM Do')}`
    },
    dayHeaderFormat: (date) => {
      const start = moment(date).startOf('week');
      const end = moment(date).endOf('week');
      return `${start.format('MMM Do')} - ${start.isSame(end, 'month') ? end.format('Do') : end.format('MMM Do')}`
    }
  }
  let sessions = [].concat(...privateSessions, ...classroomSessions, ...teacherAvailabilities);
  let tempEvent = null;
  if (selectedSlot !== null) {
    tempEvent = {
      start_at: selectedSlot.start,
      end_at: selectedSlot.end,
      type: 'temp',
      tempData: selectedSlot,
      hasOverlap: selectedSlotOverlaps.length > 0,
    }
    sessions = sessions.concat([tempEvent]);
  }
  moment.tz.setDefault();
  const localizer = momentLocalizer(moment);
  const view = windowWidth >= 900 ? 'week' : 'day';
  return (
    <div className="TeacherCalendarView">
      <h2 className="inverted">My Calendar</h2>
      <div className='calendar-wrapper'>
        <Calendar
          components={components}
          formats={formats}
          localizer={localizer}
          events={sessions}
          defaultView='week'
          views={['week', 'day']}
          view={view}
          onView={() => {}}
          selectable={true}
          getNow={() => shiftTimezone(new Date(), USER_TIMEZONE, moment.tz.guess())}
          startAccessor={getStart}
          endAccessor={getEnd}
          allDayAccessor={() => {return false;}}
          onSelectSlot={segmentSelected}
          onSelectEvent={eventSelected}
          onNavigate={onNavigate}
          min={moment().set({'hour': 0, 'minute': 0, 'second': 0}).toDate()}
        />
        {renderActionComponents(tempEvent)}
        <CalendarKey />
      </div>
    </div>
  );
}
