import PropTypes from 'prop-types'
import React from 'react'
import CoursePage from './course-page.jsx'


export default class CourseSection extends React.Component {

    static propTypes = {
        city: PropTypes.string,
        courses: PropTypes.array,
        currentPage: PropTypes.number,
        level: PropTypes.string,
        maxCards: PropTypes.number,
        pageResetObject: PropTypes.object,
    }

    makeCoursePage = (courses, index) => {
        // Return a slice of the total courses for the given page
        return courses.slice(index * this.props.maxCards, (index + 1) * this.props.maxCards)
    }

    render () {
        var languageDetail,
            pageWidth,
            courses,
            pages;

        pages = new Array(Math.ceil(this.props.courses.length/this.props.maxCards)).fill(null)
        languageDetail = document.getElementsByClassName('language-detail')[0]
        pageWidth = languageDetail ? languageDetail.getBoundingClientRect().width : window.innerWidth
        courses = this.props.courses.sort(function (x, y) {
            return x.weight < y.weight ? -1 : 1
        })

        return (
            <section
                className={'courses-tab ' + this.props.level + '-courses active-section'}
                style={{transform: 'translateX(' + this.props.currentPage * (-1) * (pageWidth) + 'px)'}}
            >
                {pages.map((nullValue, index) => {
                    return (
                        <CoursePage
                            city={this.props.city}
                            courses={this.makeCoursePage(courses, index)}
                            key={'course-page-' + index.toString()}
                        />
                    )
                })}
            </section>
        )
    }
}
