import PropTypes from 'prop-types'
import React from 'react'
import CourseCard from './course-card.jsx'


export default class CoursePage extends React.Component {

    static propTypes = {
        city: PropTypes.string,
        courses: PropTypes.array,
    }

    render () {
        return (
            <section className='courses-page'>
                {this.props.courses.map((course, index) => {
                    return (
                        <CourseCard
                            city={this.props.city}
                            course={course}
                            key={course.slug + index.toString()} />
                    )
                })}
            </section>
        )
    }
}
