import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const USER_TIMEZONE = window.user_timezone ? window.user_timezone : 'America/Detroit';
moment.tz.setDefault(USER_TIMEZONE);

const CourseCard = ({ course }) => {
  return (
    <Link to={`${course.id}`} className="course-card">
      <div className="course-card-left">
        <strong>{course.template.name}</strong>
        <span>
          {course.condensed_days} at&nbsp;
          {moment(course.start_at).format('h:mma')} from&nbsp;
          {moment(course.start_date).format('MMM D')} to&nbsp;
          {moment(course.end_date).format('MMM D')}
        </span>
      </div>
      <div className="course-card-right">
        More Info &#8594;
      </div>
    </Link>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object.isRequired
};

export default CourseCard;
