import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, generatePath } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import FCApi from '../fc-api.js';
import { getLineItems } from './api.js';
import CartLearners from './CartLearners.jsx';
import CartOverview from './CartOverview.jsx';
import CartPayment from './CartPayment.jsx';
import ProgressBar from '../general/ProgressBar.jsx';

export default function CheckoutView() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ lineitems, setLineitems ] = useState([]);
  const [ learners, setLearners ] = useState([]);
  const [ user, setUser ] = useState(null);

  const apiClient = new FCApi();

  let callCount = 0;
  const basePath = generatePath('/order/checkout', {});

  const progressSteps = [
    'Confirm Cart',
    'Enter Learner Info',
    'Complete Payment'
  ];

  const startLoad = () => {
    callCount += 1;
    setIsLoading(true);
  };

  const endLoad = () => {
    callCount -= 1;
    if (callCount <= 0) {
      callCount = 0;
      setIsLoading(false);
    }
  };

  useEffect(() => {
    preFetch();
  }, []);

  const preFetch = () => {
    startLoad();
    getLineItems((response) => {
      setLineitems(response.lineitems);
      setUser(response.user);
      endLoad();
    });

    startLoad();
    apiClient.v2ListLearners(
      (error, response) => {
        endLoad();
        if (!error) {
          setLearners(response);
        }
      }
    );
  };

  if (isLoading) {
    return (
      <div className="loading">Loading...</div>
    );
  }

  const stepUrls = [
    basePath,
    `${basePath}/learners`
  ]

  const Overview = () => (
    <div>
      <ProgressBar
        currentStep={0}
        showLinks={false}
        steps={progressSteps}
        stepUrls={stepUrls}
        basePath={basePath}
      />
      <CartOverview
        lineitems={lineitems}
      />
    </div>
  );

  const Learners = () => (
    <div>
      <ProgressBar
        currentStep={1}
        showLinks={false}
        steps={progressSteps}
        stepUrls={stepUrls}
        basePath={basePath}
      />
      <CartLearners
        lineitems={lineitems}
        learners={learners}
      />
    </div>
  );

  const Payment = () => (
    <div>
      <ProgressBar
        currentStep={2}
        showLinks={false}
        steps={progressSteps}
        stepUrls={stepUrls}
        basePath={basePath}
      />
      <CartPayment
        lineitems={lineitems}
        learners={learners}
        user={user}
      />
    </div>
  );

  return (
    <div className="CheckoutView cart_page">
      <ToastContainer draggable={false} closeButton={false} />
      <Router>
        <h2 className='checkout-title'>Complete Your Purchase</h2>
        <Routes>
          <Route path={basePath} element={<Overview />} />
          <Route path={`${basePath}/learners`} element={<Learners />} />
          <Route path={`${basePath}/payment`} element={<Payment />} />
        </Routes>
      </Router>
    </div>
  );
}
